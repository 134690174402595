import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Accordion,
  AccordionDetails,
  Typography,
  AccordionSummary
} from "@material-ui/core";
import tiktok from "../../assets/images/tiktok.png";
import capcut from "../../assets/images/capcut.png";
import { MdDownload } from "react-icons/md";
import yt from "../../assets/images/yt.png";
import ig from "../../assets/images/ig.png";
import PostBlock from "./post-block";
import ExpandMoreIcon from "../../assets/images/expand_more.svg";
//Import Images
import "./styles.scss";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#162638",
    opacity: 0.8,
    color: "#fff",
    boxShadow:
      "0 4px 6px rgba(132,146,166,0.1), 0 1px 3px rgba(132,146,166,0.1)",
    fontSize: 12,
  },
  arrow: {
    color: "#162638",
    opacity: 0.8,
  },
}))(Tooltip);

function Standings(props) {
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const returnPlatformImg = (row) =>{
      if (row.platform === "tiktok") {
        return tiktok;
      } else if (row.platform === "capcut") {
        return capcut;
      } else if (row.platform === "youtube") {
        return yt;
      } else {
        return ig;
      }
    }
    function compare(a, b) {
      if (a.creativityScore > b.creativityScore) {
        return -1;
      }
      if (a.creativityScore < b.creativityScore) {
        return 1;
      }
      return 0;
    }
    const lastUpdated = props.lastUpdated;
    const creatorObj = {};
    const postsArr = props.posts ? props.posts.filter(function (e) {
      if (e.creativityScore > 0) {
        const creatorId = e.creator.replace(" ", "") + e.platform;
        if (!creatorObj[creatorId]) {
          creatorObj[creatorId] = {
            creator: e.creator,
            platform: e.platform,
            likes: e.likes,
            creativityScore: e.creativityScore,
            posts: 1,
            links: [e.link],
          };
        } else {
          const tempLikes = creatorObj[creatorId]["likes"];
          const tempPosts = creatorObj[creatorId]["posts"];
          const tempLink =
            tempLikes < e.likes ? e.link : creatorObj[creatorId]["link"];
            creatorObj[creatorId]["links"][
              creatorObj[creatorId]["links"].length
            ] = e.link;
          creatorObj[creatorId] = {
            creator: e.creator,
            platform: e.platform,
            likes: tempLikes + e.likes,
            posts: tempPosts + 1,
            links: creatorObj[creatorId]["links"],
          };
        }
      }
      
      return e._deleted !== true && e.creativityScore > 0;
    }).sort(compare) : [];
    const creatorArr = Object.values(creatorObj).sort(compare);
    
    const columns = [
      {
        id: 1,
        label: "Creator",
      },
      {
        id: 3,
        label: "View",
      },
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);

    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    function numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function nFormatter(num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
      }
      return num;
    }
    const [show, setShow] =useState(false);
    const [activeCreator, setActiveCreator] = useState(null);

    function returnAccordionDetails(r) {
      if (r.creator === activeCreator){
        return (
          <AccordionDetails
            style={{
              display: "flex",
              flexWrap:'wrap',
              flexDirection: "row",
            }}
          >
            {r.links.map((e) => {
              // console.log(r);
              if (r.platform === "capcut") {
                return (
                  <>
                  <a className="mr-2 mb-2" href={e} target="_blank">
                    <div className="btn btn-light"><MdDownload/> View/Download</div>
                  </a>
                  
                  </>
                );
              } else {
                return <PostBlock theLink={e} platform={r.platform} />;
              }
            })}
          </AccordionDetails>
        );
      } else {
        return (<div></div>)
      }
      
      
    }
    function intToString(value) {
      var suffixes = ["", "k", "m", "b", "t"];
      if (value > 1000) {
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(2)
        );
        if (shortValue % 1 != 0) {
          shortValue = shortValue.toFixed(1);
        }
        return shortValue + suffixes[suffixNum];
      }
      return value;
    }
    return (
      <React.Fragment>
        <Container>
          <Row className="justify-content-center mt-4">
            <div className="table-responsive bg-white shadow rounded">
              {creatorArr.length > 0 ? (
                <>
                  <TableContainer>
                    <h4 className="mt-2 ml-3 madeGentle" style={{ marginBottom: 0 }}>
                      Approved Entries
                    </h4>
                    <p className="text-muted mb-1 ml-3 text-sm">
                      Last Updated: {moment(lastUpdated).fromNow()}
                    </p>
                    <Table stickyHeader aria-label="sticky table">
                      <div
                        className="d-flex border-bottom container mt-3 pb-2"
                        style={{ justifyContent: "space-between" }}
                      >
                        <span className="font-weight-bold">Creator</span>
                        <span className="font-weight-bold">View</span>
                      </div>
                      <TableBody>
                        {creatorArr
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, i) => {
                            return (
                              <Accordion key={i} className="pt-1 pb-1" onClick={()=>{setActiveCreator(row.creator);}}>
                                <AccordionSummary
                                  expandIcon={
                                    <img
                                      src={ExpandMoreIcon}
                                      alt="expand-icon"
                                    />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography style={{display:'flex',flexDirection:'row'}}>
                                    <img
                                      src={
                                         returnPlatformImg(row)
                                      }
                                      className="float-left mr-2 "
                                      height="30"
                                      alt=""
                                    />
                                    <div
                                      style={{ marginTop: 3 }}
                                      className="mobile-creator-col text-decoration"
                                    >
                                      @{row.creator}{" "}
                                    </div>
                                  </Typography>
                                </AccordionSummary>
                                {row.creator === activeCreator && returnAccordionDetails(row)}
                                
                              </Accordion>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={creatorArr.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <>
                  <TableContainer>
                    <h4 className="mt-2 ml-3" style={{ marginBottom: 0 }}>
                      Leaderboard
                    </h4>
                    <p className="text-muted mb-1 ml-3 text-sm">
                      Last Updated:
                    </p>
                    <Table stickyHeader aria-label="sticky table"></Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={creatorArr.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </>
              )}
            </div>
          </Row>
        </Container>
      </React.Fragment>
    );
}

export default Standings;
