import React, {useState} from "react";
import {
    Row,
    Col,
  } from "reactstrap";
import Platforms from "./Platforms";
import {BrandRateTooltip, SongRateTooltip} from "../../Utility/modals";
import { MdClose } from "react-icons/md";

const Profiles = ({profiles, setProfiles}) => {
    // console.log("profiles",profiles);
    return (
        <div>
            <div className="campaign-title">
              <h6 className="text-black ">Social Media Profiles</h6>
            </div>
            
              {profiles &&
                profiles.map((e, x) => {
                  return (
                    <div className="bg-light p-3 mb-3" style={{borderRadius:8}}>
                      <Row>
                        <Col xs={5} >
                          <p className="mb-0">Platform</p>
                        </Col>
                        <Col xs={7}>
                          <p className="mb-0">Handle</p>
                        </Col>
                      </Row>
                    <>
                      <Row className="mb-2">
                        <Col xs={5}>
                          <select
                            className="inputField custom-select bg-white"
                            id="Sortbylist-job"
                            value={e.Platform}
                            onChange={(event) => {
                              const shallowCopy = [...profiles];
                              shallowCopy[x].Platform = event.target.value;
                              setProfiles(shallowCopy);
                            }}
                          >
                            {Platforms.map((a, i) => {
                                return (
                                  <option key={i} value={a.PlatformName}>
                                    {a.PlatformName}
                                  </option>
                                );
                              })}
                          </select>
                        </Col>
                        
                        <Col xs={6}>
                          <input
                            value={`${e.Handle[0] !== "@" ? "@" : ""}${e.Handle}`}
                            onChange={(a) => {
                              const shallowCopy = [...profiles];
                              shallowCopy[x].Handle = a.target.value;
                              setProfiles(shallowCopy);
                            }}
                            className="inputField form-control"
                          />
                        </Col>
                        <Col xs={1} style={{marginTop:'-70px', justifyContent:'end', display:'flex'}}>
                          <div
                            className="d-flex"
                            style={{ alignItems: "center", height: "100%" }}
                            onClick={() => {
                              const profilesWithRemoval = profiles.filter(
                                (profile, count) => count !== x
                              );
                              setProfiles(profilesWithRemoval);
                            }}
                          >
                            <div style={{background:'#162638', borderRadius:'100%', height:20, width:20, display:'flex', alignItems:'center', justifyContent:'center', marginTop:-8, cursor:'pointer'}}>
                              <MdClose style={{color:'#fff',fontSize:14}} />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={5} >
                        {/* <CustomTooltip tooltipText="The price you'd charge for promoting a song in a post" titleText="Song Promo Rate"/> */}
                        Song Rate <SongRateTooltip />
                        </Col>
                        <Col xs={7}>
                          {/* <CustomTooltip tooltipText="The price you'd charge for promoting a brand in a post" titleText="Brand Promo Rate"/> */}
                          Brand Rate <BrandRateTooltip />
                        </Col>
                      </Row>
                      <Row>
                      <Col xs={5}>
                        <i className="mdi mdi-currency-usd input-icon"></i>
                        <input
                          value={`${e.SongRate[0] === "$" ? parseFloat(e.SongRate.substring(1)) : e.SongRate}`}
                          onChange={(a) => {
                            const shallowCopy = [...profiles];
                            shallowCopy[x].SongRate = a.target.value;
                            setProfiles(shallowCopy);
                          }}
                          style={{paddingLeft:18}}
                          type="number"
                          className="inputField form-control"
                        />
                        </Col>
                        <Col xs={6}>
                          <i className="mdi mdi-currency-usd input-icon" ></i>
                          <input
                            value={`${e.BrandRate[0] === "$" ? parseFloat(e.BrandRate.substring(1)) : e.BrandRate}`}
                            onChange={(a) => {
                              const shallowCopy = [...profiles];
                              shallowCopy[x].BrandRate = a.target.value;
                              setProfiles(shallowCopy);
                            }}
                            style={{paddingLeft:18}}
                            type="number"
                            className="inputField form-control"
                          />
                        </Col>

                      </Row>
                    </>
                    </div>
                  );

                })}
            
            <div
              className="btn btn-pink float-right"
              onClick={() => {
                const shallowCopy = [...profiles];
                shallowCopy.push({Platform: 'TikTok', Handle:'example', SongRate: "$10.00", BrandRate:"$20.00" });
                setProfiles(shallowCopy);
              }}
            >
              <i className="mdi mdi-plus-circle mr-2"></i>Add Handle
            </div>
          </div>
    );
}

export default Profiles;