import React, { useState, useEffect, useRef } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Row,
    Col,
  } from "reactstrap";
import Countries from "./Countries";
import GenderIdentity from "./GenderIdentity";
import SexualOrientation from "./SexualOrientation";
import States from "./States";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/build/index.css';

function range(start, thisYear) {
    const arr = [];
    for (let index = start; index < thisYear; index++) {
        arr.push(index);
    }
    return arr;
}

const Personal = ({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    gender,
    setGender,
    sexualOrientation,
    setSexualOrientation,
    birthday,
    setBirthday,
    postalCode,
    setPostalCode,
    address,
    setAddress,
    city,
    setCity,
    primaryPhone,
    setPrimaryPhone,
    allowText,
    setAllowText,
    country,
    setCountry,
    state,
    setState,
    stateOther,
    setStateOther,
    paypal,
    setPayPal
}) => {
    
    const changeState = (e) => {
        setState(e.target.value);
    };
    const years = range(1910, 2009);
    const days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
        22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ];
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const changeCountry = (e) => {
        setCountry(e.target.value);
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };
    
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };
    const handleGenderChange = (e) => {
        setGender(e.target.value);
    };
    const handleSOChange = (e) => {
        setSexualOrientation(e.target.value);
    };

    const handlePayPalChange = (e) => {
        setPayPal(e.target.value);
    };

    const changeMonth = (e) => {
        const newDate = new Date(birthday).setMonth(e.target.value);
        setBirthday(new Date(newDate));
    };
    
    const changeDay = (e) => {
        const newDate = new Date(birthday).setDate(e.target.value);
        setBirthday(new Date(newDate));
    };

    const handleStateOtherChange = (e) => {
        setStateOther(e.target.value);
    };

    const changeYear = (e) => {
        const newDate = new Date(birthday).setFullYear(e.target.value);
        setBirthday(new Date(newDate));
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    const handlePostalCodeChange = (e) => {
        setPostalCode(e.target.value);
    };

    const handlePrimaryPhoneChange = (e) => {
        setPrimaryPhone(e.target.value);
    };

    // console.log("primary phone",primaryPhone);

    return (
        <AvForm className="">
            <div className="campaigns">
                <Row>
                    <Col xs="6">
                        <div className="campaign">
                            <div className="campaign-title">
                                <h6 className="">First Name</h6>
                            </div>
                            <AvField
                                name="firstName"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                type="text"
                                className="inputField mb-3"
                                errorMessage="Invalid First Name"
                                validate={{
                                required: { value: true },
                                }}
                            />
                        </div>
                    </Col>
                    <Col xs="6">
                        <div className="campaign">
                        <div className="campaign-title">
                            <h6 className="">Last Name</h6>
                        </div>
                        <AvField
                            name="lastName"
                            value={lastName}
                            onChange={handleLastNameChange}
                            type="text"
                            className="inputField mb-3"
                            errorMessage="Invalid Last Name"
                            validate={{
                            required: { value: true },
                            }}
                        />
                        </div>
                    </Col>
                </Row>
                <div className="campaign">
                    <div className="campaign-title">
                        <h6 className="">PayPal Email</h6>
                    </div>
                    <AvField
                        name="paypal"
                        value={paypal}
                        onChange={handlePayPalChange}
                        type="email"
                        className="inputField mb-3"
                        errorMessage="Invalid PayPal"
                        validate={{
                            required: { value: true },
                        }}
                    />
                </div>
              <Row>
                <Col xs="6">
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className=" ">
                    {/* <CustomTooltip tooltipText="By providing your gender, we'll be able to send you
                      additional campaigns." titleText="Gender"/> */}
                      Gender
                    </h6>
                  </div>
                  <select
                    className="custom-select mb-3 inputField"
                    id="Sortbylist-job"
                    value={gender}
                    onChange={handleGenderChange}
                  >
                    {GenderIdentity.map((e, i) => {
                        return (
                          <option value={e.GenderIdentityID} key={i}>
                            {e.GenderIdentityDescription}
                          </option>
                        );
                    })}
                  </select>
                </div>
                </Col>
                <Col xs="6">
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className=" ">
                      {/* <CustomTooltip tooltipText="By providing your sexual orientation, we'll be able to send you
                      additional campaigns." titleText="Sexual Orientation"/> */}
                      {" "}
                      Sexual Orientation
                    </h6>
                  </div>
                  <select
                    className="custom-select mb-3 inputField"
                    id="Sortbylist-job"
                    value={sexualOrientation}
                    onChange={handleSOChange}
                  >
                    {SexualOrientation.map((e, i) => {
                        return (
                          <option value={e.SexualOrientationID} key={i}>
                            {e.SexualOrientationDescription}
                          </option>
                        );
                    })}
                  </select>
                </div>
                </Col>
              </Row>
              <div className="campaign mb-3">
                <div className="campaign-title">
                  <h6 className="">Birthday</h6>
                </div>
                <div className="birthdayInput">
                  <select
                    onChange={changeMonth}
                    className="inputField"
                    value={new Date(birthday).getMonth()}
                  >
                    {months.map((option, i) => (
                      <option key={option} value={i}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    onChange={changeDay}
                    className="inputField"
                    value={days[parseFloat(new Date(birthday).getDate() - 1)]}
                  >
                    {days.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    onChange={changeYear}
                    className=" inputField "
                    value={new Date(birthday).getFullYear()}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">Address</h6>
                </div>
                <AvField
                  name="address"
                  value={address}
                  onChange={handleAddressChange}
                  type="text"
                  className="inputField mb-3"
                  errorMessage="Invalid Address"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>

              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">City</h6>
                </div>
                <AvField
                  name="city"
                  value={city}
                  onChange={handleCityChange}
                  type="text"
                  className="inputField mb-3"
                  errorMessage="Invalid City"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">State/Province</h6>
                </div>
                {States && (
                  <select
                    onChange={changeState}
                    value={state}
                    className="mb-3 custom-select inputField"
                  >
                    
                    {States.map((option, i) => (
                      <option key={i} value={option.StateID}>
                        {option.StateName}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              {(state === "111" || state === 111) && (
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className="text-black">
                      State/Province Other
                    </h6>
                  </div>
                  <AvField
                    name="stateOther"
                    value={stateOther}
                    onChange={handleStateOtherChange}
                    type="text"
                    className="inputField mb-3"
                    errorMessage="Invalid State Other"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
              )}
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">Zip/Postal Code</h6>
                </div>
                <AvField
                  name="postalCode"
                  value={postalCode}
                  onChange={handlePostalCodeChange}
                  type="text"
                  className="inputField mb-3"
                  errorMessage="Invalid Postal Code"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">Country</h6>
                </div>
                  <select
                    onChange={changeCountry}
                    value={country}
                    className="mb-3 inputField custom-select"
                  >
                    {Countries.map((option, i) => (
                      <option key={i} value={option.CountryID}>
                        {option.CountryName}
                      </option>
                    ))}
                  </select>
              </div>
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">Mobile Phone</h6>
                </div>
                {/* <AvField
                  name="primaryPhone"
                  value={primaryPhone}
                  onChange={handlePrimaryPhoneChange}
                  type="text"
                  className="inputField mb-3"
                  errorMessage="Invalid Primary"
                  validate={{
                    required: { value: true },
                  }}
                /> */}
                <PhoneInput
                  defaultCountry="us"
                  className="mb-3"
                  value={primaryPhone}
                  onChange={(phone) => setPrimaryPhone(phone)}
                />
              </div>

              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={allowText}
                  onClick={() => {
                    setAllowText(!allowText);
                  }}
                  id="customControlInline"
                />
                <label
                  className="form-check-label"
                  htmlFor="customControlInline"
                >
                  Check to receive text message notifications
                </label>
              </div>
            </div>
          </AvForm>
    );
}

export default Personal;