import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import Cookies from "js-cookie";
import { useHistory, useLocation, } from "react-router-dom";
import queryString from "query-string";
import { Capacitor } from '@capacitor/core';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
// Redux
import { MdCheck, MdCheckBox, MdCheckCircle } from "react-icons/md";
import { Stepper, Step, StepLabel, } from "@material-ui/core";
import { withRouter, Link, } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import "./styles.scss";
import { TokenAppenditure, ValidateEmail } from '../Utility/common';
import Profiles from './SignUpSteps/Profiles';
import Platforms from './SignUpSteps/Platforms';
import Manager from './ManagerOnboardingSteps/Manager';
import Creators from './ManagerOnboardingSteps/Creators';
import { Alert } from 'reactstrap';
//Import config
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const SignUp = (props) => {
  const [userID, setUserID] = useState("");
  const [data, setData] = useState("");
  const { setWelcomeState, setCardHeight, setBorderRadius } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [paypal, setPayPal] = useState("");
  const [company, setCompany] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState();
  const [allowText, setAllowText] = useState(true);
  const [profiles, setProfiles] = useState([{ Platform: 'TikTok', Handle: 'example', SongRate: "$10.00", BrandRate: "$20.00" }]);
  const [creators, setCreators] = useState([]);
  const history = useHistory();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [currentStep, setCurrentStep] = useState(0);
  const [existingHandles, setExistingHandles] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [emailError, setEmailError] = useState();
  const handleUserIDChange = (e) => {
    if (!validateEmail(e.target.value)) {
      setEmailError("Invalid Email");
    } else {
      setEmailError("");
    }
    setUserID(e.target.value);
  };
  function getPlatformID(platformName) {
    const platform = Platforms.find(p => p.PlatformName === platformName);
    return platform ? platform.PlatformID : null;
  }

  const submit = () => {
    const newCreators = JSON.parse(JSON.stringify(creators));

    // Iterate over newCreators and update Platform fields in contactPlatforms
    newCreators.forEach(creator => {
      creator.contactPlatforms.forEach(contactPlatform => {
        const platformID = getPlatformID(contactPlatform.Platform);
        if (platformID !== null) {
          contactPlatform.PlatformID = platformID;
          delete contactPlatform.Platform;
        }
        const BrandRate = contactPlatform.BrandRate && contactPlatform.BrandRate[0] === "$" ? contactPlatform.BrandRate.substring(1) : contactPlatform.BrandRate;
        const SongRate = contactPlatform.SongRate && contactPlatform.SongRate[0] === "$" ? contactPlatform.SongRate.substring(1) : contactPlatform.SongRate;
        const Handle = contactPlatform.Handle && contactPlatform.Handle[0] === "@" ? contactPlatform.Handle.substring(1) : contactPlatform.Handle;

        contactPlatform.BrandRate = BrandRate;
        contactPlatform.SongRate = SongRate;
        contactPlatform.Handle = Handle;
      });
    });

    // console.log("new creators",newCreators);
    const formattedCreators = newCreators.map(obj => {
      const newObj = { ...obj, countryId: parseInt(obj.countryId), genderId: parseInt(obj.genderId), sexualOrientationId: parseInt(obj.genderId), stateId: parseInt(obj.stateId) }
      return newObj
    });

    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/contact/savemanager`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", },
        body:
          JSON.stringify(
            {
              firstName: firstName,
              lastName: lastName,
              primaryEmail: userID,
              password: password,
              passwordConfirm: confirmNewPassword,
              paymentEmail: paypal,
              company: company,
              creators: formattedCreators,
              primaryPhone: primaryPhone,
              AllowText: Boolean(allowText)
            }
          )

      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          setError("There has been an error. Please try again or contact tech@genni.com to resolve.")
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        // console.log("data", data);
        if (data.data.success) {
          setCurrentStep(currentStep + 1);
          setSuccessMessage(data.data.message)
          setData(data.data);
          setExistingHandles(data.data.existingHandlesData.handles);
        } else {
          setError(`${data.data.message} Please try again or contact tech@genni.com to resolve.`);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };



  function hasLowerCase(str) {
    return str.toUpperCase() !== str;
  }

  function hasUpperCase(str) {
    return str.toLowerCase() !== str;
  }

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  function hasNumber(myString) {
    return /\d/.test(myString);
  }

  const [passwordErrorMessage, setPasswordErrorMessage] = useState();

  const errorMessageReturn = (nPassword) => {
    if (!hasLowerCase(nPassword)) {
      setPasswordErrorMessage("Must contain a lower case letter");
    } else if (!hasUpperCase(nPassword)) {
      setPasswordErrorMessage("Must contain an upper case letter");
    } else if (!containsSpecialChars(nPassword)) {
      setPasswordErrorMessage("Must contain a special character");
    } else if (!hasNumber(nPassword)) {
      setPasswordErrorMessage("Must contain at least 1 digit");
    } else if (nPassword.length < 8) {
      setPasswordErrorMessage("Must be at least 8 characters");
    } else {
      setPasswordErrorMessage("");
    }
  };

  const validPassword = (nPassword) => {
    if (!hasLowerCase(nPassword)) {
      setIsValidPassword(false);
    } else if (!hasUpperCase(nPassword)) {
      setIsValidPassword(false);
    } else if (!containsSpecialChars(nPassword)) {
      setIsValidPassword(false);
    } else if (!hasNumber(nPassword)) {
      setIsValidPassword(false);
    } else if (nPassword.length < 8) {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(true);
    }
  };

  const handlePasswordChange = (e) => {
    if (confirmNewPassword && confirmNewPassword.length > 0) {
      if (confirmNewPassword !== e.target.value) {
        setError("Passwords do not match");
      } else {
        setError("");
      }

    }
    errorMessageReturn(e.target.value);
    validPassword(e.target.value);
    setPassword(e.target.value);
  };
  // console.log(userID);
  const [password, setPassword] = useState("");
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    // console.log(values)

  }
  const [error, setError] = useState("");
  const [accountType, setAccountType] = useState("Influencer");
  const handleConfirmNewPasswordChange = (e) => {
    if (e.target.value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
    setConfirmNewPassword(e.target.value);
  };

  const isGoodToProceed = () => {
    if (currentStep === 1 && (firstName && lastName) && primaryPhone !== undefined && primaryPhone && primaryPhone.length > 7 && paypal && ValidateEmail(paypal)) {
      return true;
    } else if (currentStep === 2 && profiles.length > 0 && creators.length > 0) {
      return true;
    }
    return false;
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleAccountTypeChange = (e) => {
    setAccountType(e.target.value);
  };

  const [successMessage, setSuccessMessage] = useState();


  const returnFormStep = () => {
    if (currentStep === 0) {
      return (
        <AvForm
          className="form-horizontal full-width container "
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >

          <div className="mb-3">
            <AvField
              name="userID"
              label="Email"
              type="email"
              value={userID}
              onChange={handleUserIDChange}
              validate={{
                required: { value: true },
                email: true,
              }}
              className="form-control"
              placeholder="Enter Email"
              required
            />

          </div>
          {accountType !== "Manager" && (
            <>
              <div className="mb-3">
                <div className="float-end"></div>
                <AvField
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  className="inputField"
                  label="Password"
                  errorMessage="Invalid Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                {passwordErrorMessage && (
                  <p
                    className="text-danger"
                    style={{ fontSize: "13px", marginTop: "-16px" }}
                  >
                    {passwordErrorMessage}
                  </p>
                )}
              </div>
              <div className="mb-3">
                <div className="float-end"></div>
                <AvField
                  name="confirmPassword"
                  type="password"
                  placeholder="Enter Password"
                  className="inputField"
                  label="Confirm Password"
                  errorMessage="Invalid Password"
                  value={confirmNewPassword}
                  onChange={handleConfirmNewPasswordChange}
                />
              </div>
            </>
          )}

          <div className="mt-3">
            <p className="text-danger">{error}</p>
            <div>
              {accountType === "Manager" ? (
                <>
                  <button
                    className={`btn btn-pink gradient-pink text-white w-100 waves-effect waves-light float-right ${validateEmail(userID)
                        ? ""
                        : "disabled"
                      }`}
                    style={{ float: "right" }}
                    onClick={() => {
                      if (
                        validateEmail(userID)
                      ) {

                        fetch(
                          `https://portalapi.genni.com/api/v1/creatorapi/check-username?username=${userID}`,
                          { method: "GET",
                            
                           }
                        )
                          .then((res) => {
                            if (res.ok) {
                              return res.json();
                            }
                            throw res;
                            //const jsonRes = JSON.parse(res);
                          })
                          .then(async (data) => {
                            if (data.results.success) {
                              setError("");
                              setSuccessMessage("Thanks for requesting access to become a Genni manager! A member of our team will reach out to you shortly to begin the onboarding process.")
                              setCurrentStep(3);
                            } else {
                              setError("Email already exists");

                            }
                          })
                          .catch((error) => {
                            setError("Email already exists");
                          });
                        
                      }
                    }}
                  >
                    Request Access
                  </button>
                </>
              ) : (
                <button
                  className={`btn btn-pink gradient-pink text-white w-100 waves-effect waves-light float-right ${!passwordErrorMessage &&
                      password === confirmNewPassword && password &&
                      validateEmail(userID)
                      ? ""
                      : "disabled"
                    }`}
                  style={{ maxWidth: 100, float: "right" }}
                  onClick={() => {
                    if (
                      !passwordErrorMessage &&
                      password === confirmNewPassword &&
                      validateEmail(userID)
                    ) {
                      fetch(
                        `https://portalapi.genni.com/api/v1/creatorapi/check-username?username=${userID}`,
                        { method: "GET" }
                      )
                        .then((res) => {
                          if (res.ok) {
                            return res.json();
                          }
                          throw res;
                          //const jsonRes = JSON.parse(res);
                        })
                        .then(async (data) => {
                          if (data.results.success) {
                            setError("");
                            setCurrentStep(currentStep + 1);
                          } else {
                            setError("Email already exists");

                          }
                        })
                        .catch((error) => {
                          setError("Email already exists");
                        });
                    }
                  }}
                >
                  Next
                </button>
              )}

            </div>
          </div>
        </AvForm>
      );
    } else if (currentStep === 1) {
      return (
        <>
          <Manager
            firstName={firstName}
            setFirstName={setFirstName}
            company={company}
            setCompany={setCompany}
            lastName={lastName}
            setLastName={setLastName}
            paypal={paypal}
            setPayPal={setPayPal}
            primaryPhone={primaryPhone}
            setPrimaryPhone={setPrimaryPhone}
            allowText={allowText}
            setAllowText={setAllowText}
          />
          <div className="mt-3 full-width">
            <p className="text-danger">{error}</p>
            <div>
              {currentStep > 0 && (
                <button
                  className={`btn btn-secondary fw-semi text-white w-100 waves-effect waves-light float-right`}
                  style={{ maxWidth: 100, float: "left", height: 43 }}
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  Back
                </button>
              )}
              <button
                className={`btn btn-pink gradient-pink text-white w-100 waves-effect waves-light float-right ${isGoodToProceed()
                    ? ""
                    : "disabled"
                  }`}
                style={{ maxWidth: 100, float: "right" }}
                onClick={() => {
                  if (
                    isGoodToProceed()
                  ) {
                    setCurrentStep(currentStep + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
          <div className='mt-4'></div>
        </>
      );
    } else if (currentStep === 2) {
      return (
        <>
          {error && (
            <Alert color="danger">{error}</Alert>
          )}

          <Creators
            creators={creators}
            setCreators={setCreators}
          />
          <div className="mt-3 full-width">
            <div>
              {currentStep > 0 && (
                <button
                  className={`btn btn-secondary fw-semi text-white w-100 waves-effect waves-light float-right`}
                  style={{ maxWidth: 100, float: "left", height: 43 }}
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  Back
                </button>
              )}
              <button
                className={`btn btn-pink gradient-pink text-white w-100 waves-effect waves-light float-right ${isGoodToProceed()
                    ? ""
                    : "disabled"
                  }`}
                style={{ maxWidth: 100, float: "right" }}
                onClick={() => {
                  if (
                    isGoodToProceed()
                  ) {
                    submit();
                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
          <div className='mt-4'></div>
        </>
      );
    } else if (currentStep === 3) {
      return (
        <div className="flex flex-col justify-center text-center py-2">
          <div className="rounded-full" style={{ background: 'rgb(249, 250, 251)', height: 68, width: 68, borderRadius: '100%', display: 'flex', justifyContent: 'center', margin: "AUTO", alignItems: 'center' }}>
            <MdCheckCircle size={36} color="#32a852" />
          </div>
          <h4 className="mt-3">Almost there!</h4>
          <p className="mt-0 font-semibold" style={{ fontSize: '1rem' }}>
            Please click the confirmation link in your email to activate your account.
          </p>
        </div>
      );
    }
  }

  useEffect(() => {
    document.body.className = "authentication-bg";
    if (params.token) {
      fetch(
        `https://portalapi.genni.com/api/v1/creatorapi/check`,
        { method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key":params.token },
        
         }
      )
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          }else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setError(JSON.parse(errText.message).messages[0]);
          });
        }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then(async (data) => {
          // console.log("data",data);
          if (data.results.success) {
            await Cookies.set("LoginToken", params.token);
            // console.log(params.token);
            history.push(`/dashboard${TokenAppenditure()}`);
          }
          
        });
      
    }
    return function cleanup() {
      document.body.className = "";
    };
  });

  const steps = [
    "Account",
    "Personal",
    "Finish",
  ];

  return (
    <React.Fragment>
      <div className="bg-pink" style={{ height: 8, width: '100%' }}>

      </div>
      <div className='container card mt-4' style={{ overflow: 'auto' }}>
        <div className="text-center mt-3">
          <h3 className="madeGentle">Manager Onboarding Form</h3>
          <p className="text-muted">Already have an account?
            {Capacitor.isNativePlatform() ? (
              <Link
                onClick={() => { setWelcomeState("login"); setCardHeight("60vh"); setBorderRadius(60); }}
                className="fw-medium text-pink"
              >
                {" "}
                Log In{" "}
              </Link>
            ) : (
              <Link
                to="/login"
                className="fw-medium text-pink"
              >
                {" "}
                Log In{" "}
              </Link>
            )}
          </p>
        </div>

        <Stepper activeStep={currentStep} alternativeLabel className="full-width">
          <Step key="Account">
            <StepLabel>Account</StepLabel>
          </Step>
          <Step key="Personal">
            <StepLabel>Personal</StepLabel>
          </Step>
          <Step key="Creators">
            <StepLabel>Creators</StepLabel>
          </Step>
          <Step key="Finish">
            <StepLabel>Finish</StepLabel>
          </Step>
        </Stepper>
        <div className="container mt-0 p-4">
          {returnFormStep()}
        </div>
        {existingHandles && existingHandles.length > 0 && (
          <div className='p-2'>
            <div className='container card mt-0 p-1' style={{ overflow: 'auto' }}>
              <Alert color="danger" className='mt-2'>The following handles are already linked to an account in our system. Please reach out to info@genni.com to resolve the conflict.</Alert>
              <TableContainer>


                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          Platform
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          Handle
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {existingHandles.map(e=> {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          style={{ cursor: "pointer" }}
                          tabIndex={-1}
                        >
                          <TableCell>
                            {e.PlatformName}
                          </TableCell>
                          <TableCell>
                            {e.Handle}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    
                  </TableBody>

                </Table>

              </TableContainer>
            </div>
          </div>
        )}
        </div>
        

    </React.Fragment>
  );
}


export default withRouter((SignUp))
