import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import Select from 'react-select';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import {
  Row,
  Col,
  Card,
  CardHeader,
  Alert,
} from "reactstrap";
import { isMobile } from 'react-device-detect';
import { LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";
import CampaignPayments from "./CampaignPayments";
import { MdAlarm, MdDangerous, MdEdit } from "react-icons/md";

import ContestPayments from "./ContestPayments";

const Payments = () => {  
  const [currentPage, setCurrentPage] = useState(1);
  const [payPalEmail, setPayPalEmail] = useState();
  const [totalPages, setTotalPages]=useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const loginToken = LoginToken();
  const [gridType, setGridType] = useState("grid");
  const [paymentStatus, setPaymentStatus] = useState("Potential");
  const [paymentType, setPaymentType] = useState({value:"Potential",label:'Potential'});
  useEffect(() => {
    setGridType(isMobile ? "grid" : "list");
  }, [])
  const [tabValue, setTabValue] = useState(0);
  
  const typeOptions = tabValue === 0 ? [
    {value:"Potential",label:'Potential'},
    { value: 'Pending', label: 'To Be Paid' },
    { value: 'Paid', label: 'Paid' },
    
  ] : [{ value: 'Pending', label: 'To Be Paid' },{ value: 'Paid', label: 'Paid' },];
  const handleTabChange = (e,val) =>{
    if (val === 1 ) {
      setPaymentType({value:"Paid",label:'Paid'});
    }
    setTabValue(val);
  }

  const changePaymentType = (e) => {
    setPaymentType(e);
    setPaymentStatus(e.value);
    fetchPayments(e.value);
  }

  const [data, setData] = useState();

  const fetchPayments = async (pS) => {
    await setData();
    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/payments?payment_status=${pS}&page=${currentPage}`,
      { method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
       }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        // console.log("data",data);
        setData(data);
        setTotalPages(data.results.data.PageInformation.TotalPages);
      });
  }


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    fetchPayments(paymentType.value);
    fetch(`https://portalapi.genni.com/api/v1/creatorapi/vendor?x-api-key=${loginToken}`, {
      method: "GET",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
          // console.log("payment data",data);
          setIsLoading(false);
          setPayPalEmail(data.results.data.ContactInformation.EmailAddress_Payment ? data.results.data.ContactInformation.EmailAddress_Payment : "");
        
      });
  }, [currentPage])
  
  
  const ToggleView = () => {
    return (
      <div className="d-flex mr-2 ">
        <div onClick={()=>{setGridType("grid")}} className={`${gridType!=="grid" ? "bg-white" : "disabled"} bg-white border d-flex al-center jc-center cursor-pointer`} style={{ borderTopLeftRadius:4, borderBottomLeftRadius: 4, height:39, width: 39}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={gridType === "grid" ? "#162638" : "#bec0c4"} stroke={gridType === "grid" ? "#162638" : "#bec0c4"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="fill-current w-4 h-4 "><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>

        </div>
        <div onClick={()=>{setGridType("list")}} className={`${gridType!=="list" ? "bg-white" : "disabled"} border d-flex al-center jc-center cursor-pointer`} style={{ borderWidth:4,borderTopRightRadius:4, borderBottomRightRadius: 4, height:39, width: 39}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#bec0c4" stroke={gridType !== "grid" ? "#162638" : "#bec0c4"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="fill-current w-4 h-4 "><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      
      {Capacitor.isNativePlatform() && (
        <div className={"container pt-2 pb-2 border-bottom"} style={{marginTop:`${Capacitor.isNativePlatform() ? '-160px' : '20px'}`}}>
        <div className="card-statistics " style={{ marginTop:'-15px'}}>
          
          <div className={`bg-white d-flex jc-space-between al-center align-items-center border-none`} style={{borderRadius:8, border:'none',}}>
            
            <ToggleView />
            <Select
              value={paymentType}
              defaultValue={paymentType}
              onChange={changePaymentType}
              options={typeOptions}
              className="fancy-select full-width"
              classNamePrefix="cursor-pointer full-width"
              color="#f853bd"
              
              isSearchable={false}
            />
            
          </div>
          
        </div>
      </div>
       )}
      <div className="page-content">
        {Capacitor.isNativePlatform() && (<div style={{marginTop:'-12px'}}></div>)}
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {!Capacitor.isNativePlatform() && Breadcrumbs("Payments", "Home", `/dashboard${TokenAppenditure()}`)}
              {!isLoading && !payPalEmail && (
                <><Link to="/account/vendor"><Alert color="danger" style={{ width: '100%' }}>You are missing a confirmed PayPal email. Please click here to update.</Alert></Link></>
              )}
              <div className="d-flex jc-space-between al-center campaignHeader">
                <div>
                  {!Capacitor.isNativePlatform() && (
                  <>
                    
                    <h4 className="madeGentle mt-0 al-center mb-0 campaignTitle">Payments</h4>
                    {isLoading ? (
                        <Skeleton
                          widthRandomness={20}
                          width="110px"
                          height="16px"
                          baseColor="#e6ebf2"
                          className="mt-0"
                          highlightColor="#f0f4f7"
                        />
                    ) : (
                      <>
                        {payPalEmail && (
                          <a href='/account/vendor/' style={{color:'#162638'}} className=" mb-0">PayPal Email: {payPalEmail}<MdEdit style={{marginTop:-2}} className="ml-2 cursor-pointer" /></a>

                        ) }
                      </>
                    )}
                    
                  </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
            <div
              className="mt-2 mb-3 ai-center jc-center payments-sort"
              style={{ display: "flex", flexDirection: "row", borderBottom: '1px solid #e5e7eb', justifyContent:'space-between' }}
            >
                <Tabs className="" value={tabValue} onChange={handleTabChange} TabIndicatorProps={{ style: { background:  `${Capacitor.isNativePlatform ? "#f853bd" : `#fff`}` } }}  aria-label="basic tabs example">
                  <Tab label="Campaigns" disableRipple value={0} {...a11yProps(0)} />
                  <Tab label="Contests" disableRipple value={1} {...a11yProps(1)} />
                </Tabs>
                {!Capacitor.isNativePlatform() && (
                  <div className="d-flex campaignSubtitle">
                    <ToggleView />
                  
                    <Select
                      value={paymentType}
                      defaultValue={paymentType}
                      onChange={changePaymentType}
                      options={typeOptions}
                      className="fancy-select"
                      classNamePrefix="cursor-pointer"
                      color="#f853bd"
                      isSearchable={false}
                    />
                  </div>
                )}
                
            </div>
          {tabValue===0 ? (
            <CampaignPayments 
              setCurrentPage={setCurrentPage} 
              currentPage={currentPage}
              totalPages={totalPages}
              data={data}
              gridType={gridType}
            />
          ) : (
            <ContestPayments 
              setCurrentPage={setCurrentPage} 
              currentPage={currentPage}
              totalPages={totalPages}
              data={data}
              paymentType={paymentType}
              gridType={gridType}
            />

          )}
          

        </div>
      </div>
    </React.Fragment>
  );
};;

export default Payments;
