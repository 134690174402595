import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { Button } from "reactstrap";
import { listWinnerss } from "../../graphql/queries";
import MuiButton from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {
  updatePost,
  updateCompetition,
  deletePost,
} from "../../graphql/mutations";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PostBlock({ theLink, platform }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isBanned, setIsBanned] = useState(false);
  const [winners, setWinners] = useState([]);
  const [wasDeleted, setWasDeleted] = useState("Delete");
  const [newValue, setNewValue] = useState(null);
  const [error, setError] = useState(null);
  const [bonus, setBonus] = useState(0);
  const [link, setLink] = useState(theLink);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [videoId, setVideoId] = useState();
  
  const handleBonusChange = (e) => {
    setBonus(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  
  function getVideoId() {
    if (link.includes("vt.tiktok") || link.includes("vm.tiktok")) {
      fetch(
        `https://social-media-data-tt.p.rapidapi.com/live/post/meta?video=${link}`,
        {
          method: "GET",
          headers: {
            "x-rapidapi-key":
              "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
            "x-rapidapi-host": "social-media-data-tt.p.rapidapi.com",
          },
        }
      )
        .then((res) => res.text())
        .then(async (response) => {
          const resultObj = JSON.parse(response);
          
          setLink(
            `https://tiktok.com/@${resultObj.authorMeta.name}/video/${resultObj.id}`
          );

          setVideoId(resultObj.id);
          
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      if (link.includes("tiktok")) {
        setLink(link);
        setVideoId(link.split("/video/")[1].split("?")[0]);
      }
    }

    return false;
  }
  // console.log(error);

  

  useEffect(() => {
    
    getVideoId();
  }, []);
  

  function getIGEmbedLink() {
    if (link.includes("?")) {
      return link.split("?")[0];
    } else {
      return link + "/";
    }
  }
  return (
    <div className="mb-4" style={{ display: "flex", flexDirection: "column" }}>
      {platform === "tiktok" && videoId ? (
        <>
          <iframe
            src={`https://www.tiktok.com/embed/${videoId}`}
            scrolling="no"
            width="240"
            heigth="500"
            style={{
              maxWidth: "100%",
              width: 240,
              height: 500,
              border: "none",
            }}
          ></iframe>
         
        </>
      ) : (
        <>
          <iframe
            src={`${getIGEmbedLink()}embed`}
            scrolling="no"
            width="240"
            heigth="500"
            style={{
              maxWidth: "100%",
              width: 240,
              height: 500,
              border: "none",
            }}
          ></iframe>
          
        
          
        </>
      )}
      
    </div>
  );
}

export default PostBlock;
