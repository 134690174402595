import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"


const rootReducer = combineReducers({
  // public
  Layout,

})

export default rootReducer
