import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import Cookies from "js-cookie";
import { Row, Col, Alert, Container ,CardBody,Card} from "reactstrap"

import { withRouter, Link, } from "react-router-dom";
import SignUp from './SignUp';

//Import config

const Hello = (props) => {
  return (
    <React.Fragment>
      <div className="bg-pink" style={{height:5, width:'100%'}}>
      
      </div>
      <div className="account-pages pt-5">
        <Container>
          <Row className="align-items-center justify-content-center">
                <Col md={8} lg={8} xl={8}>
                    <Card style={{marginTop:0}}>
                        <SignUp />
                    </Card>

                </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}



export default withRouter((Hello))

Hello.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}