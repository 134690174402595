import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Input, Label } from "reactstrap";
// import images
import logo from "../../assets/images/Logo.png";
import tiktok from "../../assets/images/white-tiktok.svg";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grid1: [
        { title: "For Brands", link: "https://genniagency.com" },
        { title: "For Music", link: "https://songfluencer.com" },
      ],
      grid2: [
        {
          title: "Terms of Services",
          link: "/terms",
        },
        {
          title: "Privacy Policy",
          link: "/privacy",
        },
        {
          title: "FAQ",
          link: "/pagefaq",
        },
        {
          title: "Careers",
          link: "https://songfluencer.com/careers",
        },

        { title: "Contact Us", link: "https://songfluencer.com/#contact" },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="footer">
          <div className="container">
            <div className="footer-container">
              <div className="content">
                
              </div>
            
            </div>
            <div className="footer-line"></div>
            <div className="footer-container">
              <div className="footer-holder">
                <a href="index.html" aria-current="page" className="footer-logo-container w-inline-block"><img src={logo} loading="lazy" alt="" className="_100pct footer-logo-container"/></a>
                <a href="/terms" className="footer-link first w-inline-block">
                  <div>Terms</div>
                </a>
                <a href="/privacy" className="footer-link w-inline-block">
                  <div>Privacy</div>
                </a>
                <a href="/pagefaq" className="footer-link w-inline-block">
                  <div>FAQ</div>
                </a>
                <a href="https://songfluencer.com/#contact" className="footer-link w-inline-block">
                  <div>Contact</div>
                </a>
              </div>
              <div className="content">
                <div className="_18px">Genni Inc.</div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
