import { useState } from "react";
import { Capacitor } from "@capacitor/core";
import { LoginToken, NoResults } from "./common";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import campaignImg from "../../assets/images/campaigns3.svg";
import contestImg from "../../assets/images/contests2.svg";
import { OffcanvasProvider, Trigger, Offcanvas } from 'react-simple-offcanvas'
import CampaignDetail from "../CampaignDetail";

const CampaignCard = (props) => {
    const {e, isDashboard} = props;
    const returnLink = () => {
        if (e) {
            if (e.type === "Campaign") {
                return `/detail?PostID=${e.postID}&UID=${e.UID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`;
            } else {
                return `/contestdetail?PostID=${e.postID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`;
            }
        }
        
    }
    const [isOpen, setIsOpen] = useState(false);
    function handleOpen() {
      setTimeout(() => {
        setIsOpen(true);
      }, 500);
    }
    function handleClose() {
      setTimeout(() => {
        setIsOpen(false);
      }, 500);
    }
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
      setImageError(true);
    };
    const loginToken = LoginToken();
    const returnImg = () => {
      if (returnLink().includes("contest")) {
        return <img src={contestImg} style={{width:'18px', height:'18px'}} alt="campaignimg" />;
      } else {
        if (e.image) {
          return <img src={e.image} onError={handleImageError} style={{height:40,width:40, borderRadius:3, display: imageError ? 'none' : 'block',}} alt="campaignimg" />
        } else {
          return (
          <img src={campaignImg} onError={handleImageError}
          style={{ display: imageError ? 'none' : 'block', width:'18px', height:'18px', }} alt="campaignimg" />
          );
        }
      }
    }
    const returnCard = (a) => {
      return (
        <div className={`bg-white post-card ${!isDashboard && "post-card-mobile"} m-0`} >
          <div className="card-media mb-15 d-flex jc-space-between">
            <div className="d-flex al-center jc-space-between full-width" style={{width:'100%'}}>
                <div className="media mr-10">
                    <div className="d-flex al-center jc-center" style={{height:40, width: 40, background: 'rgb(233, 236, 239)', borderRadius:3}}>
                      {returnImg()}
                    </div>
                </div>
                <div className="d-flex">
                  <h6 className="info fw-bold " >{a.price.substring(0, a.price.length - 3)}</h6>
                </div>
            </div>
            
          </div>
          <div className="info mb-10">
            <h5 className="title fw-bold" style={{lineHeight: 1.4, textAlign:'initial'}}>
              <span className="" id="title">
                {a.title}
              </span>
            </h5>
            <p className="text-muted" style={{lineHeight: 1.5,  fontWeight:400,textAlign:'initial'}}>{a.subtitle.length > 1 ? a.subtitle : "No Action Required"}</p>
          </div>
          <div className="price">
            <span className="my-0" style={{color:"#162638",lineHeight:1.5}}>{a.platform} - {a.handle}</span>
            <FaChevronRight style={{color:'#8492a6',opacity:1, marginTop:4}} />
          </div>
        </div>
      );
    }
    if (e) {
        return (
            <>
              {Capacitor.isNativePlatform() ? (
                <>
                <OffcanvasProvider onOpen={handleOpen} onClose={handleClose} >
                  <Trigger className="button-none mb-2">
                    {returnCard(e)}
                  </Trigger>
                  <Offcanvas title={e.title} backdrop={false} position="bottom" className="offcanvas-sub"> <CampaignDetail isOpen={isOpen} style={{paddingTop:'0px !important'}} postID={e.postID} uid={e.UID} /></Offcanvas>
                </OffcanvasProvider>
                
                
                </>
              ) : (
                <Link to={returnLink()} className="mb-15">
                  {returnCard(e)}
                </Link>
              )}
              
              
            </>
          );
    }
    return "";
    
}

export default CampaignCard;