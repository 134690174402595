import PropTypes, {useEffect} from 'prop-types'
import React, { Component, useState } from "react"
import { connect } from "react-redux"
import Cookies from "js-cookie";
import { Capacitor } from '@capacitor/core';
import { withRouter } from "react-router-dom"
import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions"
// Other Layout related Component
import NativeHeader from './NativeHeader';
import FullHeader from './FullHeader';
import BottomNavigation from './BottomNavigation';
import ContestDetailHeader from "../../pages/ContestDetail/header";
import Footer from "./Footer"

function Layout(props) {
  const isBasePage = props.location.pathname === ("/") || props.location.pathname.includes("/index") || props.location.pathname.includes("/hello") || props.location.pathname.includes("/manageronboarding") || props.location.pathname.includes("/welcome") || props.location.pathname.includes("/login") || props.location.pathname.includes("/updatePayment") || props.location.pathname.includes("/forgot-password")|| props.location.pathname.includes("/pagefaq") || props.location.pathname.includes("/dashboard") ||  props.location.pathname.includes("/terms") ||  props.location.pathname.includes("/privacy") || props.location.pathname.includes("/forgot-password")|| props.location.pathname.includes("/pagefaq") || props.location.pathname.includes("/dashboard") ||  props.location.pathname.includes("/terms") ||  props.location.pathname.includes("/contactus") ||  props.location.pathname.includes("/trackerscontact") ||  props.location.pathname.includes("/marketingcontact") ;
  const isBottomNavPage = props.location.pathname === ("/dashboard") || props.location.pathname === ("/payments") || props.location.pathname === ("/notifications") || props.location.pathname === ("/settings");
  const hasSingleHeader = props.location.pathname === ("/detail");
  let hasFooter = true;
  let whiteFooter = true;
  
  const returnHeader = () => {
    if (Capacitor.isNativePlatform()) {
      if (!isBasePage || props.location.pathname.includes("/dashboard")) {
        if (props.location.pathname.includes("contestdetail")){
          return <ContestDetailHeader />
        } else {
          return <NativeHeader pathName={props.location.pathname} isDashboard={props.location.pathname.includes("dashboard")} isBottomMenu={props.location.pathname.includes("dashboard") || props.location.pathname.includes("payments") || props.location.pathname.includes("settings") || props.location.pathname.includes("notifications")}  />;
        }
      }
    } else if (
      props.location.pathname.includes("/confirm") || props.location.pathname.includes("/route")
    ) {
       hasFooter = false;
      return "";
    }else if (
      props.location.pathname.includes("/contestdetail")
    ) {
      hasFooter = false;
      return <ContestDetailHeader />
    }  else if (
      isBasePage && !props.location.pathname.includes("/dashboard")
    )  {
      whiteFooter = false;
      return "";
    } else {
      hasFooter = false;
      return <FullHeader singleHeader={hasSingleHeader} />
    }
  };
  return (
    <React.Fragment>
      <div id="layout-wrapper container">
        
        {returnHeader()}
        
        {Capacitor.isNativePlatform() ? (
          <div className={!props.location.pathname.includes("contestdetail") && "main-content"} style={{marginTop:`${!isBasePage && "120px"}`}}>{props.children}</div>
        ) : (
          <div className={`${!props.location.pathname.includes("contestdetail") && "main-content"} ${((!isBasePage || props.location.pathname.includes("/dashboard")) && !hasSingleHeader) ? "pt-5" :""}`} style={{marginTop:`${(!isBasePage || props.location.pathname.includes("/dashboard")) && "80px"}`}}>{props.children}</div>

        )}
        {hasFooter && !Capacitor.isNativePlatform() && <Footer isDark={!whiteFooter} />}

        {Capacitor.isNativePlatform() && (isBottomNavPage) && (
          <>
          <BottomNavigation locationName={props.location}  />
          <div style={{height:400, width:'100%'}}></div>
          </>
        )}
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
}

Layout.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
}

const mapStatetoProps = state => {
  return {
    ...state.Layout,
  }
}
export default connect(mapStatetoProps, {
  changeTopbarTheme,
  changeLayout,
  changeLayoutWidth,
})(withRouter(Layout))
