const Countries = 
[{CountryID: 1, CountryName: 'United States'},
{CountryID: 2, CountryName: 'Canada'},
{CountryID: 3, CountryName: 'Mexico'},
{CountryID: 4, CountryName: 'Afghanistan'},
{CountryID: 5, CountryName: 'Albania'},
{CountryID: 6, CountryName: 'Algeria'},
{CountryID: 7, CountryName: 'American Samoa'},
{CountryID: 8, CountryName: 'Andorra'},
{CountryID: 9, CountryName: 'Angola'},
{CountryID: 10, CountryName: 'Anguilla'},
{CountryID: 11, CountryName: 'Antarctica'},
{CountryID: 12, CountryName: 'Antigua and Barbuda'},
{CountryID: 13, CountryName: 'Argentina'},
{CountryID: 14, CountryName: 'Armenia'},
{CountryID: 15, CountryName: 'Aruba'},
{CountryID: 16, CountryName: 'Ascension Island'},
{CountryID: 17, CountryName: 'Australia'},
{CountryID: 18, CountryName: 'Austria'},
{CountryID: 19, CountryName: 'Azerbaijan'},
{CountryID: 20, CountryName: 'Bahamas'},
{CountryID: 21, CountryName: 'Bahrain'},
{CountryID: 22, CountryName: 'Bangladesh'},
{CountryID: 23, CountryName: 'Barbados'},
{CountryID: 24, CountryName: 'Belarus'},
{CountryID: 25, CountryName: 'Belgium'},
{CountryID: 26, CountryName: 'Belize'},
{CountryID: 27, CountryName: 'Benin'},
{CountryID: 28, CountryName: 'Bermuda'},
{CountryID: 29, CountryName: 'Bhutan'},
{CountryID: 30, CountryName: 'Bolivia'},
{CountryID: 31, CountryName: 'Bosnia and Herzegovina'},
{CountryID: 32, CountryName: 'Botswana'},
{CountryID: 33, CountryName: 'Bouvet Island'},
{CountryID: 34, CountryName: 'Brazil'},
{CountryID: 35, CountryName: 'British Indian Ocean Territory'},
{CountryID: 36, CountryName: 'Brunei Darussalam'},
{CountryID: 37, CountryName: 'Bulgaria'},
{CountryID: 38, CountryName: 'Burkina Faso'},
{CountryID: 39, CountryName: 'Burundi'},
{CountryID: 40, CountryName: 'Cambodia'},
{CountryID: 41, CountryName: 'Cameroon'},
{CountryID: 42, CountryName: 'Cape Verde'},
{CountryID: 43, CountryName: 'Cayman Islands'},
{CountryID: 44, CountryName: 'Central African Republic'},
{CountryID: 45, CountryName: 'Chad'},
{CountryID: 46, CountryName: 'Chile'},
{CountryID: 47, CountryName: 'China'},
{CountryID: 48, CountryName: 'Christmas Island'},
{CountryID: 49, CountryName: 'Cocos (Keeling) Islands'},
{CountryID: 50, CountryName: 'Colombia'},
{CountryID: 51, CountryName: 'Comoros'},
{CountryID: 52, CountryName: 'Democratic Republic of the Congo (Kinshasa)'},
{CountryID: 53, CountryName: 'Congo, Republic of (Brazzaville)'},
{CountryID: 54, CountryName: 'Cook Islands'},
{CountryID: 55, CountryName: 'Costa Rica'},
{CountryID: 56, CountryName: 'Ivory Coast'},
{CountryID: 57, CountryName: 'Croatia'},
{CountryID: 58, CountryName: 'Cuba'},
{CountryID: 59, CountryName: 'Cyprus'},
{CountryID: 60, CountryName: 'Czechia'},
{CountryID: 61, CountryName: 'Denmark'},
{CountryID: 62, CountryName: 'Djibouti'},
{CountryID: 63, CountryName: 'Dominica'},
{CountryID: 64, CountryName: 'Dominican Republic'},
{CountryID: 65, CountryName: 'East Timor Timor-Leste'},
{CountryID: 66, CountryName: 'Ecuador'},
{CountryID: 67, CountryName: 'Egypt'},
{CountryID: 68, CountryName: 'El Salvador'},
{CountryID: 69, CountryName: 'Equatorial Guinea'},
{CountryID: 70, CountryName: 'Eritrea'},
{CountryID: 71, CountryName: 'Estonia'},
{CountryID: 72, CountryName: 'Ethiopia'},
{CountryID: 73, CountryName: 'Falkland Islands'},
{CountryID: 74, CountryName: 'Faroe Islands'},
{CountryID: 75, CountryName: 'Fiji'},
{CountryID: 76, CountryName: 'Finland'},
{CountryID: 77, CountryName: 'France'},
{CountryID: 78, CountryName: 'French Guiana'},
{CountryID: 79, CountryName: 'French Metropolitan'},
{CountryID: 80, CountryName: 'French Polynesia'},
{CountryID: 81, CountryName: 'French Southern Territories'},
{CountryID: 82, CountryName: 'Gabon'},
{CountryID: 83, CountryName: 'Gambia'},
{CountryID: 84, CountryName: 'Georgia'},
{CountryID: 85, CountryName: 'Germany'},
{CountryID: 86, CountryName: 'Ghana'},
{CountryID: 87, CountryName: 'Gibraltar'},
{CountryID: 88, CountryName: 'Great Britain'},
{CountryID: 89, CountryName: 'Greece'},
{CountryID: 90, CountryName: 'Greenland'},
{CountryID: 91, CountryName: 'Grenada'},
{CountryID: 92, CountryName: 'Guadeloupe'},
{CountryID: 93, CountryName: 'Guam'},
{CountryID: 94, CountryName: 'Guatemala'},
{CountryID: 95, CountryName: 'Guernsey'},
{CountryID: 96, CountryName: 'Guinea'},
{CountryID: 97, CountryName: 'Guinea-Bissau'},
{CountryID: 98, CountryName: 'Guyana'},
{CountryID: 99, CountryName: 'Haiti'},
{CountryID: 100, CountryName: 'Heard and Mc Donald Islands'},
{CountryID: 101, CountryName: 'Holy See'},
{CountryID: 102, CountryName: 'Honduras'},
{CountryID: 103, CountryName: 'Hong Kong'},
{CountryID: 104, CountryName: 'Hungary'},
{CountryID: 105, CountryName: 'Iceland'},
{CountryID: 106, CountryName: 'India'},
{CountryID: 107, CountryName: 'Indonesia'},
{CountryID: 108, CountryName: 'Iran (Islamic Republic of)'},
{CountryID: 109, CountryName: 'Iraq'},
{CountryID: 110, CountryName: 'Ireland'},
{CountryID: 111, CountryName: 'Isle of Man'},
{CountryID: 112, CountryName: 'Israel'},
{CountryID: 113, CountryName: 'Italy'},
{CountryID: 114, CountryName: 'Jamaica'},
{CountryID: 115, CountryName: 'Japan'},
{CountryID: 116, CountryName: 'Jersey'},
{CountryID: 117, CountryName: 'Jordan'},
{CountryID: 118, CountryName: 'Kazakhstan'},
{CountryID: 119, CountryName: 'Kenya'},
{CountryID: 120, CountryName: 'Kiribati'},
{CountryID: 121, CountryName: "Korea, Democratic People's Rep. (North Korea)"},
{CountryID: 122, CountryName: 'Korea, Republic of (South Korea)'},
{CountryID: 123, CountryName: 'Kosovo'},
{CountryID: 124, CountryName: 'Kuwait'},
{CountryID: 125, CountryName: 'Kyrgyzstan'},
{CountryID: 126, CountryName: "Lao, People's Democratic Republic"},
{CountryID: 127, CountryName: 'Latvia'},
{CountryID: 128, CountryName: 'Lebanon'},
{CountryID: 129, CountryName: 'Lesotho'},
{CountryID: 130, CountryName: 'Liberia'},
{CountryID: 131, CountryName: 'Libya'},
{CountryID: 132, CountryName: 'Liechtenstein'},
{CountryID: 133, CountryName: 'Lithuania'},
{CountryID: 134, CountryName: 'Luxembourg'},
{CountryID: 135, CountryName: 'Macau'},
{CountryID: 136, CountryName: 'Macedonia, Rep. of'},
{CountryID: 137, CountryName: 'Madagascar'},
{CountryID: 138, CountryName: 'Malawi'},
{CountryID: 139, CountryName: 'Malaysia'},
{CountryID: 140, CountryName: 'Maldives'},
{CountryID: 141, CountryName: 'Mali'},
{CountryID: 142, CountryName: 'Malta'},
{CountryID: 143, CountryName: 'Marshall Islands'},
{CountryID: 144, CountryName: 'Martinique'},
{CountryID: 145, CountryName: 'Mauritania'},
{CountryID: 146, CountryName: 'Mauritius'},
{CountryID: 147, CountryName: 'Mayotte'},
{CountryID: 148, CountryName: 'Micronesia, Federal States of'},
{CountryID: 149, CountryName: 'Moldova, Republic of'},
{CountryID: 150, CountryName: 'Monaco'},
{CountryID: 151, CountryName: 'Mongolia'},
{CountryID: 152, CountryName: 'Montenegro'},
{CountryID: 153, CountryName: 'Montserrat'},
{CountryID: 154, CountryName: 'Morocco'},
{CountryID: 155, CountryName: 'Mozambique'},
{CountryID: 156, CountryName: 'Myanmar, Burma'},
{CountryID: 157, CountryName: 'Namibia'},
{CountryID: 158, CountryName: 'Nauru'},
{CountryID: 159, CountryName: 'Nepal'},
{CountryID: 160, CountryName: 'Netherlands'},
{CountryID: 161, CountryName: 'Netherlands Antilles'},
{CountryID: 162, CountryName: 'New Caledonia'},
{CountryID: 163, CountryName: 'New Zealand'},
{CountryID: 164, CountryName: 'Nicaragua'},
{CountryID: 165, CountryName: 'Niger'},
{CountryID: 166, CountryName: 'Nigeria'},
{CountryID: 167, CountryName: 'Niue'},
{CountryID: 168, CountryName: 'Norfolk Island'},
{CountryID: 169, CountryName: 'Northern Mariana Islands'},
{CountryID: 170, CountryName: 'Norway'},
{CountryID: 171, CountryName: 'Oman'},
{CountryID: 172, CountryName: 'Pakistan'},
{CountryID: 173, CountryName: 'Palau'},
{CountryID: 174, CountryName: 'Palestinian National Authority'},
{CountryID: 175, CountryName: 'Panama'},
{CountryID: 176, CountryName: 'Papua New Guinea'},
{CountryID: 177, CountryName: 'Paraguay'},
{CountryID: 178, CountryName: 'Peru'},
{CountryID: 179, CountryName: 'Philippines'},
{CountryID: 180, CountryName: 'Pitcairn Island'},
{CountryID: 181, CountryName: 'Poland'},
{CountryID: 182, CountryName: 'Portugal'},
{CountryID: 183, CountryName: 'Puerto Rico'},
{CountryID: 184, CountryName: 'Qatar'},
{CountryID: 185, CountryName: 'Reunion Island'},
{CountryID: 186, CountryName: 'Romania'},
{CountryID: 187, CountryName: 'Russian Federation'},
{CountryID: 188, CountryName: 'Rwanda'},
{CountryID: 189, CountryName: 'Saint Kitts and Nevis'},
{CountryID: 190, CountryName: 'Saint Lucia'},
{CountryID: 191, CountryName: 'Saint Vincent and the Grenadines'},
{CountryID: 192, CountryName: 'Samoa'},
{CountryID: 193, CountryName: 'San Marino'},
{CountryID: 194, CountryName: 'Sao Tome and Pr¡ncipe'},
{CountryID: 195, CountryName: 'Saudi Arabia'},
{CountryID: 196, CountryName: 'Senegal'},
{CountryID: 197, CountryName: 'Serbia'},
{CountryID: 198, CountryName: 'Seychelles'},
{CountryID: 199, CountryName: 'Sierra Leone'},
{CountryID: 200, CountryName: 'Singapore'},
{CountryID: 201, CountryName: 'Slovakia (Slovak Republic)'},
{CountryID: 202, CountryName: 'Slovenia'},
{CountryID: 203, CountryName: 'Solomon Islands'},
{CountryID: 204, CountryName: 'Somalia'},
{CountryID: 205, CountryName: 'South Africa'},
{CountryID: 206, CountryName: 'South Georgia and South Sandwich Islands'},
{CountryID: 207, CountryName: 'South Sudan'},
{CountryID: 208, CountryName: 'Spain'},
{CountryID: 209, CountryName: 'Sri Lanka'},
{CountryID: 210, CountryName: 'Saint Helena'},
{CountryID: 211, CountryName: 'St. Pierre and Miquelon'},
{CountryID: 212, CountryName: 'Sudan'},
{CountryID: 213, CountryName: 'Suriname'},
{CountryID: 214, CountryName: 'Svalbard and Jan Mayen Islands'},
{CountryID: 215, CountryName: 'Swaziland'},
{CountryID: 216, CountryName: 'Sweden'},
{CountryID: 217, CountryName: 'Switzerland'},
{CountryID: 218, CountryName: 'Syria, Syrian Arab Republic'},
{CountryID: 219, CountryName: 'Taiwan (Republic of China)'},
{CountryID: 220, CountryName: 'Tajikistan'},
{CountryID: 221, CountryName: 'Tanzania'},
{CountryID: 222, CountryName: 'Thailand'},
{CountryID: 223, CountryName: 'Tibet'},
{CountryID: 224, CountryName: 'Timor-Leste (East Timor)'},
{CountryID: 225, CountryName: 'Togo'},
{CountryID: 226, CountryName: 'Tokelau'},
{CountryID: 227, CountryName: 'Tonga'},
{CountryID: 228, CountryName: 'Trinidad and Tobago'},
{CountryID: 229, CountryName: 'Tunisia'},
{CountryID: 230, CountryName: 'Turkey'},
{CountryID: 231, CountryName: 'Turkmenistan'},
{CountryID: 232, CountryName: 'Turks and Caicos Islands'},
{CountryID: 233, CountryName: 'Tuvalu'},
{CountryID: 234, CountryName: 'Uganda'},
{CountryID: 235, CountryName: 'Ukraine'},
{CountryID: 236, CountryName: 'United Arab Emirates'},
{CountryID: 237, CountryName: 'United Kingdom'},
{CountryID: 238, CountryName: 'U.S. Minor Outlying Islands'},
{CountryID: 239, CountryName: 'Uruguay'},
{CountryID: 240, CountryName: 'Uzbekistan'},
{CountryID: 241, CountryName: 'Vanuatu'},
{CountryID: 242, CountryName: 'Vatican City State (Holy See)'},
{CountryID: 243, CountryName: 'Venezuela'},
{CountryID: 244, CountryName: 'Vietnam'},
{CountryID: 245, CountryName: 'Virgin Islands (British)'},
{CountryID: 246, CountryName: 'Virgin Islands (U.S.)'},
{CountryID: 247, CountryName: 'Wallis and Futuna Islands'},
{CountryID: 248, CountryName: 'Western Sahara'},
{CountryID: 249, CountryName: 'Yemen'},
{CountryID: 250, CountryName: "Zaire (see Congo, Democratic People's Republic)"},
{CountryID: 251, CountryName: 'Zambia'},
{CountryID: 252, CountryName: 'Zimbabwe'},
{CountryID: 253, CountryName: 'Other/None'}];

export default Countries;