import React, {useEffect,useState} from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import errorImg from "../../assets/images/404-error.png"

const Terms = () => {
  const app = "Genni Web App";
  const [data,setData] = useState();
  useEffect(() => {
    fetch(`https://portalapi.genni.com/api/v1/creatorapi/legal`, {
      method: "POST",
      headers: { "Content-Type": "application/json"},
      body: JSON.stringify({ type: "Terms of Service", application: app })
      
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          setData(data);
        } else {
          // window.location.href = "/login";
        }
      });
  }, []);
  // // console.log("data",data);
  return (
    <React.Fragment>
      <div className="container">
        {data && (
          <div
            className="Archivo"
            dangerouslySetInnerHTML={{
              __html: data.results.data.Information,
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default Terms
