import { useEffect, useState } from "react";
import { Row, Col, Alert, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Cookies from "js-cookie";
import { useLocation, useHistory } from "react-router-dom";
import { LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";
import { TbAlertCircleFilled } from "react-icons/tb";

const Deactivate = () => {
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [deactivateText, setDeactivateText] = useState();
  const loginToken = LoginToken();
  const history = useHistory();
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        fetch(`https://portalapi.genni.com/api/v1/creatorapi/auth/logout`,
          {
            method: "post",
            headers: { "Content-Type": "application/json", "x-api-key": loginToken },
          })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
            //const jsonRes = JSON.parse(res);
          })
          .then((data) => {
            Cookies.set("LoginToken", "");
            history.push("/login");
          }).catch((error) => {
            Cookies.set("LoginToken", "");
            history.push("/login");
          });
      }, 5000)
    }
  }, [loginToken, successMessage])

  const handleDeactivateTextChange = (e) => {
    setDeactivateText(e.target.value);
  };

  const deactivateAccount = (e) => {
    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/deactivate-account`,
      { 
        method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },

      }
    ).then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((d) => {
        // document.body.scrollTop = document.documentElement.scrollTop = 0;
        // console.log("d",d);
        setErrorMessage();
        setSuccessMessage(d.results.message);
      }).catch(error=>{
        // console.log(error)
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setSuccessMessage("");
        setErrorMessage("Please make sure all fields are properly filled out.");
      });
  };

  return (
    <>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {Breadcrumbs(
                "Deactivate Account",
                "Account Settings",
                `/settings${(TokenAppenditure(), true)}`
              )}
              <AvForm className="card p-3">
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                {successMessage ? (
                  <>
                    <Alert color="success"><i className="mdi mdi-check-circle"></i>{successMessage}</Alert>
                    <p className="text-muted">You will be redirected shortly.</p>
                  </>
                ) : (
                  <>
                    <div className="page-title-box d-flex flex-col">
                      <h4 className="mb-0 madeGentle">Deactivate Account</h4>
                      <p className="text-muted">
                        This will disable your Genni account. You will not be
                        able to login or operate campaigns through the Genni
                        application.
                      </p>
                    </div>
                    <div className="campaign">
                      <div className="campaign-title">
                        <div className="mb-0">
                          <span>Please type{" "}
                          <span className="mt-0 text-muted">
                            Deactivate my account {" "}
                          </span>
                          to confirm.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="campaigns">
                      <div className="campaign">
                        <AvField
                          name="DeactivateText"
                          value={deactivateText}
                          onChange={handleDeactivateTextChange}
                          type="text"
                          className="inputField mb-3 required"
                          errorMessage="Invalid Email"
                        />
                      </div>

                      <div className="campaign">
                        <button
                          className="btn btn-pink gradient-pink full-width float-right"
                          style={{ width: "100%" }}
                          onClick={deactivateAccount}
                          disabled={deactivateText !== "Deactivate my account" ? true : false}
                        >
                          I understand the consequences, deactivate my account
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </AvForm>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Deactivate;
