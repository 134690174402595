import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Container, CardBody ,Card} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { MdCheckCircle,} from "react-icons/md";
// action
import logo from "../../assets/images/Logo.png";
// import images
import logolight from "../../assets/images/logo-light.png"

const ForgetPasswordPage = props => {
  const [email,setEmail] = useState();
  const [error, setError] = useState();
  const [messages, setMessages] = useState();
  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };
  const reset = async () => {
    fetch(`https://portalapi.genni.com/api/v1/creatorapi/password-reset`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({username: email})
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (data.results.success) {
          // console.log("you're good");
          setMessages(data.results.message);
        } else {
          setError(data.results.message);
        }
      }).catch((error) => {
        setError("An error has occured");
      });
  }

  return (
    <React.Fragment>
      <div className="bg-pink" style={{height:10, width:'100%'}}>
      
      </div>

      <div className="account-pages my-5  pt-sm-5">
        <Container>
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div>
                <Card>
                  <CardBody className="p-3">
                    {!messages && (
                      <div className="text-center mt-2">
                      <h4 className=" madeGentle">Forgot Password?</h4>
                    </div>
                    )}
                    
                    <div className="p-2 mt-0">
                      {messages && (
                        <>
                         <div className="flex flex-col justify-center text-center py-2">
                         <div className="rounded-full" style={{ background: 'rgb(249, 250, 251)', height: 68, width: 68, borderRadius: '100%', display: 'flex', justifyContent: 'center', margin: "AUTO", alignItems: 'center' }}>
                           <MdCheckCircle size={36} color="#32a852" />
                         </div>
                         <h4 className="mt-3">Successful Password Reset!</h4>
                         <p className="mt-0 font-semibold" style={{ fontSize: '1rem' }}>
                           {messages}
                         </p>
                         <div className="mt-4 text-center">
                            <p className="mb-0">
                              Received the email?{" "}
                              <Link to="/login" className="fw-medium text-pink">
                                {" "}
                                Login{" "}
                              </Link>
                            </p>
                          </div>
                       </div>
                        
                        </>
                      )}

                      {!messages && (
                        <AvForm className="form-horizontal">
                          <div className="mb-3">
                            <AvField
                              name="email"
                              value={email}
                              onChange={handleEmailChange}
                              label="Email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              required
                            />
                          </div>
                          <p className="text-danger">{error}</p>
                          <Row className="row mb-0">
                            <Col className="col-12 text-end">
                              <button
                                className="btn btn-pink gradient-pink gradient-pink w-md waves-effect waves-light"
                                onClick={() => {
                                  reset();
                                }}
                              >
                                Reset
                              </button>
                            </Col>
                          </Row>
                          <div className="mt-4 text-center">
                            <p className="mb-0">
                              Remember It?{" "}
                              <Link to="/login" className="fw-medium text-pink">
                                {" "}
                                login{" "}
                              </Link>
                            </p>
                          </div>
                        </AvForm>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center"></div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}



export default withRouter((ForgetPasswordPage))
