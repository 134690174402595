/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-2",
  aws_appsync_graphqlEndpoint:
    "https://skzvkieqlbgcrcwczrglggncui.appsync-api.us-east-2.amazonaws.com/graphql",
  aws_appsync_region: "us-east-2",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-cjv76rbepfgylhr5c62mzpepsq",
  aws_cognito_identity_pool_id:
    "us-east-2:adf65167-b04c-4792-bcc0-65947e5b09aa",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_43FqidDNz",
  aws_user_pools_web_client_id: "4v78iaib2iguidhh81o89svp7s",
  oauth: {},
  aws_user_files_s3_bucket:
    "preffy-webbcb877c98a0749c083b216aad631f2df162954-staging",
  aws_user_files_s3_bucket_region: "us-east-2",
};

export default awsmobile;
