import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import Cookies from "js-cookie";
import { Row, Col, Alert, Container ,CardBody,Card, alert} from "reactstrap"
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { TokenAppenditure } from "../Utility/common";
import logo from "../../assets/images/Logo.png";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { withRouter, Link } from "react-router-dom"

const UpdatePayment = (props) => {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [error, setError] = useState("");
  const history = useHistory();
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    if (params.token || Cookies.get("LoginToken")){
      fetch(
        `https://api.genni.com/confirmPaymentEmail?token=${params.token || Cookies.get("LoginToken")}`,
        { method: "POST",
          body: JSON.stringify({
            uid:params.uid,
            isEmailConfirmed:params.isEmailConfirmed,
            contactPaymentEmailId:params.contactPaymentEmailId
          })
        }
      )
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          }else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setError(JSON.parse(errText.message).messages[0]);
          });
        }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then(async (data) => {
          if (params.token) {
            await Cookies.set("LoginToken", params.token);
            history.push(`/account/vendor/${TokenAppenditure()}`);
          } else {
            history.push(`/account/vendor/${TokenAppenditure()}`);
          }
        });
    }
    return function cleanup() {
      document.body.className = "";
    };
  });

  const returnForm = () => {
    return (
        <>
          <div className="text-center mt-2">
            <img src={logo} style={{width:140, height:'auto'}} className="mb-3" alt="genni" />
          </div>
          <div className="p-2 mt-4 text-center">
            <Loader type="Bars" color="#f853bd" height={20} width={40} />
          </div>
        </>
      );
  }

  return (
    <React.Fragment>
      <div className="bg-pink" style={{height:220, width:'100%'}}>
      </div>
      <div className="account-pages pt-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card style={{marginTop:-200}}>
                <CardBody className="p-4">
                  {returnForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}


export default withRouter((UpdatePayment))
