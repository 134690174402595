import PropTypes from "prop-types";
import React from "react";
import Cookies from "js-cookie";
import { useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import { Badge, Col, Row,  } from "reactstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { LoginToken, NoResults } from "./common";
import CampaignCard from "./CampaignCard";
import CampaignDetail from "../CampaignDetail";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";

const Tablelist = (props) => {
  const { data, approvalStatus, pagination, totalPages, currentPage, setCurrentPage, isDashboard  } = props;
  const location = useLocation();
  let params = queryString.parse(location.search);
  const returnEmptyVal = () => {
    if (data === null) {
      return true;
    }
    return false;
  };
  const returnSkeletonComps = (i) => {
    const oneSkeleton = (
      <div style={{width:'100%'}}>
        <div
          className={`post-card ${!isDashboard && "post-card-mobile"} post-card-skeleton bg-white mb-3`}
          style={{ minHeight: 170,height:170, width:'100%', background: '#fff !important' ,  }}
        >
          <div className="card-media mb-15 d-flex jc-space-between" >
            <div className="d-flex flex-col">
                <div className="media mr-10">
                    <Skeleton
                      widthRandomness={0}
                      width="40px"
                      height="40px"
                      baseColor="#e6ebf2"
                      className="mt-0"
                      highlightColor="#f0f4f7"
                    />
                </div>
                <div className="d-flex mt-15 flex-col">
                  <Skeleton
                    widthRandomness={10}
                    width="140px"
                    height="20px"
                    baseColor="#e6ebf2"
                    highlightColor="#f0f4f7"
                    className="mb-1"
                  />
                  <Skeleton
                    widthRandomness={50}
                    width="170px"
                    height="15px"
                    baseColor="#e6ebf2"
                    highlightColor="#f0f4f7"
                    className="mb-2"
                  />
                </div>
            </div>
            
          </div>
          
          

        </div>
      </div>
    );
    return (
      <>
        {oneSkeleton}
        {oneSkeleton}
        {oneSkeleton}
      </>
    );
  };
  return (
    <React.Fragment>
        {data && data.length > 0 ? (
          <>
            <div className={`${isDashboard ? "slider-grid slider-grid-mobile" : "slider-grid"} pb-2`} style={{overflowX:'hidden'}}>
              {data.map((e, i) => {
                return (
                  <>
                    <CampaignCard isDashboard={isDashboard} e={e}/>
                  </>
                );
              })}
            </div>
          </>
        ) : (
          <>
            {returnEmptyVal() ? (
              <>
                <div className="slider-grid pb-2">

                  {returnSkeletonComps()}
                </div>
              </>
            ) : (

              <div className="container" style={{margin:'auto'}}>
                <NoResults approvalStatus={approvalStatus} />
              </div>
            )}
          </>
        )}
      
      {data && data.length > 0 && totalPages > 1 && (
        <div className="card mt-2" style={{width:'100%'}}>
          {pagination && totalPages > 1 && (
            <div
              className="px-4"
              style={{
                textAlign: "right",
                marginTop: 16,
                paddingBottom: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <p className="px-4" style={{ fontSize: 14 }}>
                Page {currentPage} of {totalPages}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: 27,
                  marginTop: -10,
                  width: 72,
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={`pagination-btn ${
                    currentPage > 1
                      ? ""
                      : "disabled-pagination-btn text-muted"
                  }`}
                  onClick={() => {
                    if (currentPage > 1) {
                      setCurrentPage(parseInt(currentPage) - 1);
                    }
                  }}
                >
                  {" "}
                  <MdOutlineChevronLeft />
                </div>
                <div
                  className={`pagination-btn ${
                    currentPage < totalPages
                      ? ""
                      : "disabled-pagination-btn text-muted"
                  }`}
                  onClick={() => {
                    if (currentPage < totalPages) {
                      setCurrentPage(parseInt(currentPage) + 1);
                      
                    }
                  }}
                >
                  {" "}
                  <MdOutlineChevronRight />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      
    
      
    </React.Fragment>
  );
};;


export default Tablelist;
