import React from "react";
import { Row, Col } from "reactstrap";
import { AvForm, AvField, } from "availity-reactstrap-validation";
import GenderIdentity from "../SignUpSteps/GenderIdentity";
import SexualOrientation from "../SignUpSteps/SexualOrientation";
import Countries from "../SignUpSteps/Countries";
import States from "../SignUpSteps/States";
import { add } from "lodash";

function range(start, thisYear) {
    const arr = [];
    for (let index = start; index < thisYear; index++) {
      arr.push(index);
    }
    return arr;
}

const CreatorInfo = ({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    address,
    setAddress,
    gender,
    setGender,
    sexualOrientation,
    setSexualOrientation,
    country,
    setCountry,
    postalCode,
    setPostalCode,
    birthday,
    setBirthday,
    state,
    setState,
    setCity,
    stateOther,
    setStateOther,
    city,
}) => {
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    }
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    }
    const handleGenderChange = (e) => {
      setGender(e.target.value);
    };
    const handleSOChange = (e) => {
      setSexualOrientation(e.target.value);
    };
    const changeYear = (e) => {
      const newDate = new Date(birthday).setFullYear(e.target.value);
      setBirthday(new Date(newDate));
    };
  
    const changeMonth = (e) => {
      const newDate = new Date(birthday).setMonth(e.target.value);
      setBirthday(new Date(newDate));
    };
  
    const changeDay = (e) => {
      const newDate = new Date(birthday).setDate(e.target.value);
      setBirthday(new Date(newDate));
    };

    const days = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ];

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const years = range(1910, 2021);   

    const changeState = (e) => {
      setState(e.target.value);
    };

    const handleCityChange = (e) => {
      setCity(e.target.value);
    };

    const handleStateOtherChange = (e) => {
      setStateOther(e.target.value);
    };

    const changeCountry = (e) => {
      setCountry(e.target.value);
    };
    
    return (
        <> 
            <h4 className="madeGentle mb-4">Creator Info</h4>
            <Row>
                <Col xs="6">
                    <div className="campaign">
                        <div className="campaign-title">
                            <h6 className="">First Name of Creator</h6>
                        </div>
                        <AvField
                            name="firstName"
                            value={firstName}
                            onChange={handleFirstNameChange}
                            type="text"
                            className="inputField mb-3"
                            errorMessage="Invalid First Name"
                            validate={{
                            required: { value: true },
                            }}
                        />
                    </div>
                </Col>
                <Col xs="6">
                    <div className="campaign">
                        <div className="campaign-title">
                            <h6 className="">Last Name of Creator</h6>
                        </div>
                        <AvField
                            name="lastName"
                            value={lastName}
                            onChange={handleLastNameChange}
                            type="text"
                            className="inputField mb-3"
                            errorMessage="Invalid Last Name"
                            validate={{
                            required: { value: true },
                            }}
                        />
                    </div>
                </Col>
            </Row>
            
            <Row>
                <Col xs="6">
                    <div className="campaign">
                        <div className="campaign-title">
                            <h6 className=" ">
                                Gender of Creator
                            </h6>
                        </div>
                        <select
                            className="custom-select mb-3 inputField"
                            id="Sortbylist-job"
                            value={gender}
                            onChange={handleGenderChange}
                        >
                            {GenderIdentity.map((e, i) => {
                                return (
                                    <option value={e.GenderIdentityID} key={i}>
                                        {e.GenderIdentityDescription}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </Col>
                <Col xs="6">
                    <div className="campaign">
                        <div className="campaign-title">
                            <h6 className=" ">
                                {" "}
                                Sexual Orientation of Creator
                            </h6>
                        </div>
                        <select
                            className="custom-select mb-3 inputField"
                            id="Sortbylist-job"
                            value={sexualOrientation}
                            onChange={handleSOChange}
                        >
                            {SexualOrientation.map((e, i) => {
                                return (
                                    <option value={e.SexualOrientationID} key={i}>
                                    {e.SexualOrientationDescription}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </Col>
            </Row>
            <div className="campaign mb-3">
                <div className="campaign-title">
                    <h6 className="">Birthday of Creator</h6>
                </div>
                <div className="birthdayInput">
                    <select
                        onChange={changeMonth}
                        className="inputField"
                        value={new Date(birthday).getMonth()}
                    >
                        {months.map((option, i) => (
                            <option key={option} value={i}>
                            {option}
                            </option>
                        ))}
                    </select>
                    <select
                        onChange={changeDay}
                        className="inputField"
                        value={days[parseFloat(new Date(birthday).getDate() - 1)]}
                    >
                        {days.map((option) => (
                            <option key={option} value={option}>
                            {option}
                            </option>
                        ))}
                    </select>
                    <select
                        onChange={changeYear}
                        className=" inputField "
                        value={new Date(birthday).getFullYear()}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                            {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <Row>
                <Col>
                    <div className="campaign">
                        <div className="campaign-title">
                            <h6 className="">Address of Creator</h6>
                        </div>
                        <AvField
                            name="address"
                            value={address}
                            onChange={(e) => {setAddress(e.target.value)}}
                            type="text"
                            className="inputField mb-3"
                            errorMessage="Invalid Address"
                            validate={{
                            required: { value: true },
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <div className="campaign">
                <div className="campaign-title">
                    <h6 className="">City of Creator</h6>
                </div>
                <AvField
                    name="city"
                    value={city}
                    onChange={handleCityChange}
                    type="text"
                    className="inputField mb-3"
                    errorMessage="Invalid City"
                    validate={{
                        required: { value: true },
                    }}
                />
            </div>
            <div className="campaign">
                <div className="campaign-title">
                    <h6 className="">State/Province of Creator</h6>
                </div>
                {States && (
                    <select
                    onChange={changeState}
                    value={state}
                    className="mb-3 custom-select inputField"
                    >
                    {States.map((option, i) => (
                        <option key={i} value={option.StateID}>
                        {option.StateName}
                        </option>
                    ))}
                    </select>
                )}
            </div>
            {(state === "111" || state === 111) && (
                <div className="campaign">
                    <div className="campaign-title">
                        <h6 className="text-black">
                            State/Province Other
                        </h6>
                    </div>
                    <AvField
                        name="stateOther"
                        value={stateOther}
                        onChange={handleStateOtherChange}
                        type="text"
                        className="inputField mb-3"
                        errorMessage="Invalid State Other"
                        validate={{
                            required: { value: true },
                        }}
                    />
                </div>
            )}
            <div className="campaign">
                <div className="campaign-title">
                    <h6 className=" ">Zip/Postal Code of Creator</h6>
                </div>
                <AvField
                    name="postalCode"
                    value={postalCode}
                    onChange={(e) => {setPostalCode(e.target.value)}}
                    type="text"
                    className="inputField mb-3"
                    errorMessage="Invalid Postal Code"
                    validate={{
                    required: { value: true },
                    }}
                />
            </div>
            <div className="campaign">
                <div className="campaign-title">
                    <h6 className="">Country of Creator</h6>
                </div>
                <select
                    onChange={changeCountry}
                    value={country}
                    className="mb-3 inputField custom-select"
                >
                    {Countries.map((option, i) => (
                        <option key={i} value={option.CountryID}>
                        {option.CountryName}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

export default CreatorInfo;