import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm } from "availity-reactstrap-validation";
import S3FileUpload from "react-s3";
import { Row, Col, Alert } from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import { LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { TbAlertCircleFilled } from "react-icons/tb";
import { useEffect } from "react";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const Photo = () => {
  const [secretAccessKey, setSecretAccessKey] = useState();
  useEffect(() => {
    if (!secretAccessKey) {
      setSecretAccessKey(process.env.REACT_APP_SECRET_ACCESS_KEY);
    }
  }, [process.env])


  const s3Config = {
    bucketName: "genni-creator-assets",
    region: "us-east-1",
    accessKeyId: "AKIA23FTX7LAJZTPJ2VF",
    secretAccessKey: `${secretAccessKey}`,
  };
  const history = useHistory();
  const [error,setError] = useState();
  const [file, updateFile] = useState(null);
  const [fileName, updateFileName] = useState("Upload Image");
  const [fileResult, updateFileResult] = useState(null);
  const [errorMessage,setErrorMessage]= useState();
  const loginToken = LoginToken(); // => 'value'
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  function handleImageChange(event) {
    const {
      target: { value, files },
    } = event;
    const fileForUpload = files[0];
    if (fileForUpload.type.includes("pdf") || fileForUpload.type.includes("jpeg") || fileForUpload.type.includes("png")){
      let reader = new FileReader();
      // console.log("reader", reader);
      reader.onloadend = function (e) {
        updateFileResult([reader.result]);
      }.bind(this);

      if (
        fileForUpload &&
        (fileForUpload.type === "image/png" ||
          fileForUpload.type === "image/jpg" ||
          fileForUpload.type === "image/jpeg")
      ) {
        setError("");
        updateFileName(fileForUpload.name.split(".")[0]);

        updateFile(fileForUpload || value);
      } else {
        setError("Must be a valid png or jpg");
      }
    } else {
      setErrorMessage("Invalid File");
    }
    
    
    // if (
    //   reader.result.includes("image/jpeg") ||
    //   reader.result.includes("image/png") ||
    //   reader.result.includes("image/jpg")
    // ) {
      
    // } else {
    //   setError("Must be a valid png or jpg");
    // }
      
  }
  const finishUpload = () => {
    setIsLoading(true);
    try {
      const new_file = new File([file], makeid(32));
      S3FileUpload.uploadFile(new_file, s3Config)
        .then(async (data) => {
          fetch(`https://portalapi.genni.com/api/v1/creatorapi/photo`, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              "x-api-key": loginToken // <-- Specifying the Content-Type
            }),
            body: JSON.stringify({fileUrl: data.location}), // <-- Post parameters
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw res;
              //const jsonRes = JSON.parse(res);
            })
            .then((data) => {
              history.push(`/settings${TokenAppenditure()}`);
              
            });
        })
        .catch((err) => {
          // console.log(err);
        });
    } catch (err) {
      // console.log("error: ", err);
    }
  };
  
  
  // console.log(data);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
            {Breadcrumbs("Choose Profile Photo", "Account Settings", `/settings${TokenAppenditure()}`, true)}

              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 madeGentle">Choose Profile Photo</h4>
              </div>
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              <div className="card p-3">
                <AvForm>
                  <div className="campaigns">
                    <div className="campaign">
                      <div className="campaign-title">
                        <h5 className="fw-bold">
                          SELECT PHOTO (JPG or PNG FILE)
                        </h5>
                      </div>
                    </div>
                    <div className="campaign">
                      {file ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {file.name}
                          <div className="mt-2">
                            <div
                              className="btn btn-pink float-right"
                              onClick={() => {
                                finishUpload();
                              }}
                            >
                              {isLoading ? (
                                <Loader type="Bars" color="#fff" width={20} height={20} />

                              ) : 
                                "Finish Upload"
                              }
                              
                            </div>
                            <div
                              className="btn btn-secondary float-right mx-1"
                              onClick={() => {
                                updateFile(null);
                                updateFileResult(null);
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <p className="text-danger">{error}</p>
                          
                          <label
                            htmlFor="file-upload"
                            className="full-width float-right"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "flex",
                              flexDirection:'column',
                              justifyContent: "center",
                              alignItems: "center",
                              cursor:'pointer',
                              border: "2px dashed #575767",
                              padding:30,

                            }}
                          >
                            <i className="mdi mdi-cloud-upload text-pink" style={{fontSize:48}}></i>
                            <h4>Upload File</h4>
                            <span>JPG or PNG</span>
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            id="file-upload"
                            onChange={handleImageChange}
                            style={{ width: "140px", display: "none" }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Photo;
