import { MdOutlineChevronRight } from "react-icons/md";

const faqQuestion = (props) => {
    const {e, token, isNative} = props;
    return (
        <a
            href={`/faqlist/?FaqID=${e.FaqID}${isNative ? `&token=${token}` : ""}`}
        >
            <div className="row-table pb-3 pt-3 px-2 border-bottom cursor-pointer">
            <div className="row-group">
                <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                }}
                >
                <h6
                    className=" mb-0"
                    style={{ fontSize: "1rem" }}
                >
                    {e.Question}
                </h6>
                <p
                    className="text-muted mb-0"
                    style={{
                    textTransform: "capitalize",
                    fontSize: 14,
                    }}
                >
                    {e.subtext}
                </p>
                </div>
            </div>
            <MdOutlineChevronRight size={24} color="#162638"/>

            </div>
        </a>
    );
}



export default faqQuestion;