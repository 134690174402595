import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import defaultImg from "../../assets/images/icon.png";
import { Capacitor } from "@capacitor/core";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Badge,
  Alert,
} from "reactstrap";
import { TokenAppenditure, CommonLoader } from "../Utility/common";
import AddCreatorModal from "../Authentication/ManagerOnboardingSteps/AddCreatorModal";
import { FaCheckCircle, FaPlusCircle } from "react-icons/fa";
import { HiPlusCircle, } from "react-icons/hi";
import { MdClose, MdDelete, MdDeleteForever, MdOutlineDeleteForever } from "react-icons/md";

const Accounts = () => {
  const [controllingAccounts, setControllingAccounts] = useState();
  const [isLoadingRoster, setIsLoadingRoster] = useState(true);
  const [isLoadingControllingAccounts, setIsLoadingControllingAccounts] = useState(true);
  const [linkedAccounts, setLinkedAccounts] = useState();
  const [roster, setRoster] = useState();
  const [pendingAccounts, setPendingAccounts] = useState();
  const [currentRemoval, setCurrentRemoval] = useState();
  const [firstName, setFirstName] = useState();
  const history = useHistory();
  const [lastName, setLastName] = useState();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const loginToken = Capacitor.isNativePlatform() ? params.token : Cookies.get("LoginToken");
  const [tikTokProfiles, setTikTokProfiles] = useState([]);
  const [tikTokRates, setTikTokRates] = useState([]);
  const [instagramProfiles, setInstagramProfiles] = useState([]);
  const [instagramRates, setInstagramRates] = useState([]);
  const [youtubeProfiles, setYouTubeProfiles] = useState([]);
  const [youtubeRates, setYouTubeRates] = useState([]);
  const [searchVal, setSearchVal] = useState();
  const handleSearchVal = (e) => {
    setSearchVal(e.target.value);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [modalRemove, setModalRemove] = useState(false);
  const [contactType, setContactType] = useState();
  const toggle = () => {
    setFirstName();
    setLastName();
    setTikTokProfiles([]);
    setInstagramProfiles([]);
    setYouTubeProfiles([]);
    setModal(!modal);
  };

  const toggleRemove = () => {
    setModalRemove(!modalRemove);
  };
  useEffect(() => {
    if (!roster) {
      fetch(`https://portalapi.genni.com/api/v1/creatorapi/manager/roster`, {
        method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          setRoster(data.data.roster);
          setIsLoadingRoster(false);
        });
    }
    if (!controllingAccounts) {
      fetch(`https://portalapi.genni.com/api/v1/creatorapi/accounts`, {
        method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          
          setIsLoadingControllingAccounts(false);
          setContactType(data.contactDetail.contactType);
          data.results.Accounts.map((e) => {
            if (e.type === "controlling") {
              setControllingAccounts(e.entries);
            } else {
              setPendingAccounts(e.entries);
            }
          });
            // console.log("data", data);
         
        });
    }

  }, [controllingAccounts]);


  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const accessAction = (e, action) => {
    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/accounts`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({action: action, email:e.Email, linkedContactId: e.ContactID})
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          window.location.reload();
        } else {
          // window.location.href = "/login";
        }
      });
  };

  const unlinkCreator = (e) => {
    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/unlink-creator-from-manager`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({ creatorContactId: e.ContactID })
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (data.results.success) {
          setControllingAccounts();
          setRoster();
          setSuccessMessage("Successfully Removed Creator!")
          toggleRemove();
        } else {
          // window.location.href = "/login";
        }
      });
  }

  const switchAction = (ContactID) => {
    // console.log(ContactID);
    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/switch`,
      { method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({contactId: ContactID.ContactID})
       }
  )
      .then((res) => {
      if (res.ok) {
          return res.json();
      }
      throw res;
      //const jsonRes = JSON.parse(res);
      })
      .then(async (data) => {
        if (data.results.success) {
          await Cookies.set("ContactDetail", "");
          const redirectLink = `/dashbord${TokenAppenditure()}`;
          history.push(redirectLink);
        }
        
      });
  };

  const pageTitle = contactType === "creator" ? "Genni Accounts" : "Manage Roster";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {!Capacitor.isNativePlatform() && (
                <ol className="breadcrumb mt-0 mb-3">
                  <BreadcrumbItem>
                    <a href={`/settings${TokenAppenditure()}`}>Account Settings</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <a>{pageTitle}</a>
                  </BreadcrumbItem>
                </ol>
              )}


              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              {successMessage && (
                <Alert color="success"><FaCheckCircle className="mr-2" />{successMessage}</Alert>
              )}
              <div className="page-title-box d-flex al-center justify-content-between mb-3">
                <h4 className="mb-0 madeGentle">{pageTitle}</h4>
                {isLoadingControllingAccounts ? (
                  <>
                  </>
                ) : (
                  <>
                    {contactType !== "creator" && (
                      <div className="flex ">
                        <button
                          className="btn btn-pink"
                          onClick={toggle}
                        >
                          <HiPlusCircle size={17} style={{ marginTop: -2, marginRight: 4 }} />
                          <span className="">
                            Add Creator
                          </span>
                        </button>
                      </div>
                    )}
                  </>
                )}

              </div>

              {isLoadingRoster ? (
                <CommonLoader />
              ) : (
                <>
                  {contactType !== "creator" ? (
                    <div className="p-0">
                      {(roster && roster.length > 0) || isLoadingRoster ? (
                        <>
                          <div className="row">
                            {roster ? roster.map(e => {
                              return (
                                <div className="col-xl-3 col-sm-6">
                                  <div className="card text-center">
                                    <div className="card-body" style={{ paddingTop: 12, paddingLeft: 12, paddingRight: 12 }}>
                                      {false ? (
                                        <div className="d-flex mb-4" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                          <div className="">
                                            <span className="badge bg-danger rounded-pill">
                                              Action Required
                                            </span>
                                          </div>
                                          <div className="dropdown float-end">
                                            <a
                                              className="text-body dropdown-toggle font-size-16"
                                              href="#"
                                              role="button"
                                              data-bs-toggle="dropdown"
                                              aria-haspopup="true"
                                            >
                                              <MdClose />
                                            </a>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="dropdown float-end">
                                          <a
                                            className="text-body dropdown-toggle font-size-10 btn btn-light p-1"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            onClick={() => {
                                              setCurrentRemoval(e);
                                              toggleRemove();
                                            }}
                                          >
                                            <MdClose style={{marginTop:-2}}/> Remove
                                          </a>
                                        
                                        </div>
                                      )}

                                      <div className="clearfix" />
                                      <div className="mb-4">
                                        {e.image_url ? (
                                          <img
                                            src={e.image_url}
                                            alt=""
                                            className="avatar-lg rounded-circle "
                                          />
                            
                                        ) : (
                                          <div className="avatar-lg mx-auto mb-4">
                                            <div className="avatar-title bg-primary-subtle rounded-circle text-primary">
                                              <i className="mdi mdi-account-circle display-4 m-0 text-primary" />
                                            </div>
                                          </div>
                                        )}


                                      </div>
                                      <h5 className="font-size-16 mb-1">
                                        <a href="#" className="text-reset ">
                                          {`${e.FirstName} ${e.LastName}`}
                                        </a>
                                      </h5>
                                      {/* <p className="text-muted mb-2">{e.login_username}</p> */}
                                    </div>
                                    <div className="btn-group" role="group">
                                      <Link to={`/account/admin?ContactID=${e.ContactID}&UID=${e.UID}`} style={{width:'100%'}}>
                                        <button style={{width:'100%',borderRadius:0, borderBottomLeftRadius:4}} type="button" className="btn btn-outline-light text-truncate">
                                          <i className="uil uil-cog me-1" /> Admin
                                        </button>
                                      </Link>
                                      
                                      <button style={{width:'100%',borderRadius:0, borderBottomRightRadius:4}} type="button" className="btn btn-outline-light text-truncate" onClick={() => {switchAction(e);}}>
                                        <i className="uil uil-unlock me-1" /> Login
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            }): (
                              <CommonLoader />
                            )}
                          </div>
                        </>
                      ) : (
                        <div
                          className="d-flex align-items-center text-align-center p-3 card"
                          style={{ margin: "auto", justifyContent: 'center' }}
                        >
                          There are no accounts you can access yet.
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="card">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0 p-3 madeGentle">Who Can Access My Account</h5>
                      </div>
                      <div className="p-3">
                        {controllingAccounts && controllingAccounts.length > 0 ? (
                          <>
                            {controllingAccounts.map((e) => {
                              return (
                                <div
                                  className="d-flex border-bottom pb-2 pt-2"
                                  style={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <h6 className="mb-0 font-weight-bold">{e.Email}</h6>
                                    <p className="mb-0">{e.Name}</p>
                                  </div>
                                  <button
                                    className="btn btn-pink"
                                    style={{ maxHeight: 40 }}
                                    onClick={() => {
                                      accessAction(e, "remove");
                                    }}
                                  >
                                    Remove
                                  </button>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div
                            className="d-flex align-items-center text-align-center p-3"
                            style={{ margin: "auto", justifyContent: 'center' }}
                          >
                            You are the only one who has permission to access your
                            account.
                          </div>
                        )}
                      </div>
                    </div>
                  )}


                </>
              )}

            </Col>
          </Row>
        </div>
      </div>
      <AddCreatorModal setControllingAccounts={setControllingAccounts} setSuccessMessage={setSuccessMessage} setErrorMessage={setErrorMessage} isLoggedIn={true} modalOpen={modal} toggle={toggle} />

      <Modal isOpen={modalRemove} toggle={toggleRemove}>
        <ModalHeader className="">
          Are you sure you want to remove access to this account?
        </ModalHeader>

        <ModalFooter>
          <Button color="" className="btn-light" onClick={toggleRemove}>
            Cancel
          </Button>
          <Button
            color="pink"
            className={`btn-pink`}
            onClick={() => {
              unlinkCreator(currentRemoval);
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Accounts;
