import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import Cookies from "js-cookie";
import { Row, Col, Alert, Container ,CardBody,Card, } from "reactstrap"
import { useLocation } from "react-router-dom";
import { faBell } from '@fortawesome/free-solid-svg-icons'
import queryString from "query-string";
import { Capacitor } from '@capacitor/core';
// Redux
import logo from "../../assets/images/Logo.png";
import { connect } from "react-redux"
import Loader from "react-loader-spinner";
import { withRouter, Link } from "react-router-dom"
import logowhite from "../../assets/images/logowhite.png";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import logolight from "../../assets/images/logo-light.png"
import { TokenAppenditure, LoginToken } from '../Utility/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TbAlertCircleFilled } from 'react-icons/tb';

//Import config
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Login = (props) => {
  const [userID, setUserID] = useState();
  const [email, setEmail] = useState();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [isUploading, setIsUploading] = useState(false);
  const [form, setForm] = useState(1);
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleUserIDChange = (e) => {
    setUserID(e.target.value);
  };
   const handleEmailChange = (e) => {
     setEmail(e.target.value);
   };
  const [password, setPassword] = useState();
   // handleValidSubmit
   const handleValidSubmit = (event, values) => {
    // console.log(values)

  }
  const [error, setError] = useState("");

  const loginAttempt = () => {
    setIsUploading(true);
    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/auth/login`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: userID, password:password})
      }
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setError(JSON.parse(errText.message).messages[0] + ". Having trouble logging in? Contact outreach@genni.com");
            setIsUploading(false);
          });
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then(async (data) => {
        if (data.error) {
          setError(`${data.messages[0]} Having trouble logging in? Contact outreach@genni.com`);
        } else {
          await Cookies.set("ContactDetail", JSON.stringify(data.contactDetail));
          await Cookies.set("LoginToken", data.data.token);
          const redirectLink = Capacitor.isNativePlatform() ? `/dashboard?token=${data.data.token}` : "/dashboard"
          window.location.href = redirectLink;
        }
       
      });
  };

  const linkEmail = () => {
    fetch(
      `https://api.genni.com/username?username=${encodeURIComponent(email)}&x-api-key=${Cookies.get(
        "LoginToken"
      )}`,
      { method: "POST" }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then(async (data) => {
        // console.log("data", data);
        setForm(3);
      })
      .catch((error) => {
        setError("Duplicate or Invalid Credentials");
      });
  };

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    if (params.token || Cookies.get("LoginToken")){
      fetch(
        `https://portalapi.genni.com/api/v1/creatorapi/check`,
        { method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key":params.token || Cookies.get("LoginToken") },
        
         }
      )
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          }else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setError(JSON.parse(errText.message).messages[0]);
          });
        }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then(async (data) => {
          // console.log("data",data);
          if (data.results.success) {
            if (params.token) {
              await Cookies.set("LoginToken", params.token);
              await Cookies.set("ContactDetail", JSON.stringify(data.results.contactDetail));
              window.location.href = `/dashboard`
            } else {
              window.location.href = `/dashboard`
            }
          }
          
        });
    }
    return function cleanup() {
      document.body.className = "";
    };
  });

  const returnForm = () => {
    if (form === 1) {
      return (
        <>
          <div className="text-center mt-2">
            {error && <Alert color="danger">{error}</Alert>}
            {params.confirmation && !error && <Alert color="success">You have successfully confirmed your email!</Alert>}

            <img src={logo} style={{width:140, height:'auto'}} className="mb-3" alt="genni" />
            <p className="text-muted">Sign in to continue to genni.</p>
          </div>
          <div className="p-2 mt-4">
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.error && typeof props.error === "string" ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}

              <div className="mb-3">
                <AvField
                  name="userID"
                  label="Email"
                  value={userID}
                  onChange={handleUserIDChange}
                  validate={{
                    required: { value: true },
                  }}
                  className="form-control inputField"
                  placeholder="Enter Email"
                  type="text"
                  required
                />
              </div>

              <div className="mb-3">
                <div className="float-end">
                  <Link to="/forgot-password" className="text-muted">
                    Forgot password?
                  </Link>
                </div>
                <AvField
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  className="form-control inputField"
                  label="Password"
                  errorMessage="Invalid Password"
                  value={password}
                  onChange={handlePasswordChange}
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>

              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customControlInline"
                />
                <label
                  className="form-check-label"
                  htmlFor="customControlInline"
                >
                  Remember me
                </label>
              </div>

              <div className="mt-3">
                <button
                  className="btn gradient-pink btn-pink text-white w-100 waves-effect waves-light"
                  onClick={loginAttempt}
                >
                  {isUploading ? (
                    <Loader type="Bars" color="#fff" height={20} width={40} />
                  ) : (
                    "Log In"
                  )}
                </button>
              </div>
            </AvForm>
            <div className="mt-4 text-center">
                <p className="mb-0">
                  Don't have an account?{" "}
                  <a
                    href="/hello"
                    className="fw-medium text-pink"
                  >
                    {" "}
                    Sign Up{" "}
                  </a>{" "}
                </p>
              </div>
          </div>
        </>
      );
    } else if (form ===2) {
      return (
        <>
          <div className="text-center mt-2">
            <h5 className="text-pink madeGentle">Link A Unique Email To Your Account</h5>
            <p className="text-muted">This email will be used to help you login going forward.</p>
          </div>
          <div className="p-2 mt-4">
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.error && typeof props.error === "string" ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}

              <div className="mb-3">
                <AvField
                  name="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  validate={{
                    required: { value: true },
                  }}
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  required
                />
              </div>


              <div className="mt-3">
                <p className="text-danger">{error}</p>
                <button
                  className={`btn gradient-navy text-white w-100 waves-effect waves-light ${!validateEmail(email) && 'disabled'}`}
                  onClick={()=>{
                    if(validateEmail(email)){
                      linkEmail();
                    }

                  }}
                >
                 Submit
                </button>
              </div>

              {/* <div className="mt-4 text-center">
                        <p className="mb-0">
                          Don't have an account ?{" "}
                          <a
                            href="/register"
                            className="fw-medium text-pink"
                          >
                            {" "}
                            Signup now{" "}
                          </a>{" "}
                        </p>
                      </div> */}
            </AvForm>
          </div>
        </>
      );
    } else if (form === 3) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "-30px",
            }}
          >
            <i
              className="mdi mdi-check-decagram"
              style={{ fontSize: 70, color: "#162638" }}
            />
            Thank you for registering! We've sent an email to verify your
            account.
          </div>
        </>
      );
    }
  }

  return (
    <React.Fragment>
      <div className="bg-pink" style={{height:5, width:'100%'}}>
      
      </div>

      
      <div className="account-pages pt-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>

              <Card style={{marginTop:0}}>
                <CardBody className="p-4">
                  {returnForm()}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter((Login))

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}