import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Rightbar from "../CommonForBoth/Rightbar";
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
import logo from "../../assets/images/Logo.png";
import Pullable from "react-pullable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, } from '@fortawesome/free-solid-svg-icons'
import Cookies from "js-cookie";
import { withTranslation } from "react-i18next";
import { get } from "lodash";
import { TokenAppenditure, LoginToken } from "../../pages/Utility/common";
import { Capacitor } from "@capacitor/core";

const NativeHeader = props => {
  const [showMenu, setShowMenu] = useState(false);
  const {isDashboard, isBottomMenu, pathName} = props;
  const pathnameOptions = {"/campaigns": "Campaigns","/dashboard": "Home","/notifications": "Notifications","/settings": "Account","/payments": "Payments","/contests": "Contests", "/detail": "Campaign Detail", "/contestdetail":"Contest", "/contact": "Contact", "/analytics":"Analytics", "/faq":"FAQ", "/faqlist":"FAQ","/account/credentials/":"Login Information","/account/contact":"Contact Information","/account/vendor":"Payment Information","/account/tax":"Tax Documents","/account/social":"Social Media Profiles","/account/updatesocial":"Update Social","/account/photo":"Profile Photo", "/genni/accounts":"Genni Accounts" };
  const history = useHistory();
  const location = useLocation();
  const title = get(pathnameOptions, [pathName], "");
  const back = () =>{
    history.goBack();
  }
  const loginToken = LoginToken(); // => 'value'
  const [data, setData] = useState();
  useEffect(() => {
    fetch(`https://portalapi.genni.com/api/v1/creatorapi/switch`, {
      method: "GET",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          // window.location.href = "/login";
        }
      })
      .then(async (data) => {
        // console.log("Switch",data);
        await Cookies.set("ContactDetail", JSON.stringify(data.contactDetail));
        setData(data);
       
      })
      .catch((error) => {
        // console.log(error);
        if (loginToken) {
          fetch(`https://portalapi.genni.com/api/v1/creatorapi/auth/logout`,
            {
              method: "post",
              headers: { "Content-Type": "application/json", "x-api-key": loginToken },
            })
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw res;
              //const jsonRes = JSON.parse(res);
            })
            .then((data) => {
              Cookies.set("LoginToken", "");
              history.push("/login");
            }).catch((error) => {
              Cookies.set("LoginToken", "");
              history.push("/login");
            });
        }
      });
  }, []);
  const [key, setKey] = useState(0);

  const handleReload = () => {
    setKey(prevKey => prevKey + 1);
  };

  const returnPadding = () => {
    let paddingHeight = 0;
    if (isDashboard) {
      paddingHeight ='0px';
    } else if (title === "Campaigns" || pathName === "/payments") {
      paddingHeight = '155px';
    } else if ( pathnameOptions.pathName === "/contests") {
      paddingHeight = "150px";
    }

    return (<Pullable id="pullable" className={`bg-white pullableDiv `} spinnerOffset={0} spinnerColor="#162638" style={{}} onRefresh={()=>{window.location.reload();}}><div className="bg-white" style={{width:'100%', height:paddingHeight}}></div></Pullable> )
    
  }
  return (
    <React.Fragment>

      <div className="bg-white border-bottom" style={{height:`${`${isDashboard ? "100px" : "100px"}`}`,  width:'100%', paddingTop:'2.1rem', position:`fixed`, zIndex:`${"999"}`}}>
        <React.Fragment>
        
          <div className="navbar-header container pt-2" >
            <div className="d-flex">
              <div className="navbar-brand-box al-center jc-space-between">
                {isBottomMenu ? (
                  <>
                    {title === "Home" ? (
                      <Link to={`/dashboard${TokenAppenditure()}`} className="logo logo-light">
                        <span className="logo-lg mb-0">
                          <img src={logo} alt="genni" className="nav-logo mb-0" style={{width:100}} />
                        </span>
                      </Link>
                    ) : (
                      <Link to={`/dashboard${TokenAppenditure()}`} className="logo logo-light">
                        <h4 className="madeGentle al-center margin-auto mb-0">{title}</h4>
                      </Link>
                    )}
                    
                  </>
                ) : (
                  <div onClick={back} className="cursor-pointer" style={{fontSize:30}}>
                    <i className="mdi mdi-arrow-left"></i>
                  </div>
                )}
              </div>
            </div>
            {!isBottomMenu && (<h5 className="d-flex jc-center madeGentle" style={{marginBottom:0, position:'absolute',left:0,right:0,zIndex:'-1'}}>{title}</h5>)}
            <div className="d-flex">
              {/* <ProfileMenu /> */}
              <FontAwesomeIcon onClick={()=>{setShowMenu(!showMenu)}}  icon={faBars}  style={{width:30,height:30, cursor:'pointer', color:'#162638',margin:'auto',}} />

            </div>
          </div>
          
          {<Rightbar data={data} showMenu={showMenu} setShowMenu={setShowMenu} />}
        </React.Fragment>
      
      </div>
      
        {returnPadding()}
        

    </React.Fragment>
  );
};

NativeHeader.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(NativeHeader));
