import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { LoginToken, TokenAppenditure } from "../Utility/common";
import { PhoneInput } from 'react-international-phone';
import queryString from "query-string";
import { useLocation, } from "react-router-dom";
import {
    Row,
    Col,
    Alert,
    BreadcrumbItem,
} from "reactstrap";
import { first } from "lodash";
import { TbAlertCircleFilled } from "react-icons/tb";


function range(start, thisYear) {
    const arr = [];
    for (let index = start; index < thisYear; index++) {
        arr.push(index);
    }
    return arr;
}

const EditProfileModal = ({ isOpen, toggle, contactInfo, states, countries, setRefetch, refetch, setSuccessMessage }) => {
    const [step, setStep] = useState(0);
    const oldFirstName = contactInfo.FirstName;
    const [firstName, setFirstName] = useState(contactInfo.FirstName || "");
    const [mobilePhone, setMobilePhone] = useState(contactInfo.Phone_Primary || "");
    const oldLastName = contactInfo.LastName;
    const [lastName, setLastName] = useState(contactInfo.LastName || "");
    const oldAddress = contactInfo.AddressLine1;
    const [address, setAddress] = useState(contactInfo.AddressLine1 || "");
    const [city, setCity] = useState(contactInfo.City || "");
    const [country, setCountry] = useState(contactInfo.CountryID || 1);
    const [state, setState] = useState(contactInfo.StateID || 1);
    const [stateOther, setStateOther] = useState(contactInfo.State_Other || "");
    const [postalCode, setPostalCode] = useState(contactInfo.PostalCode || "");
    const [birthday, setBirthday] = useState(contactInfo.Birthday
        ? new Date(contactInfo.Birthday)
        : new Date("1/1/1910"));
    const location = useLocation();
    let params = queryString.parse(location.search);
    const years = range(1910, 2021);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const changeYear = (e) => {
        const newDate = new Date(birthday).setFullYear(e.target.value);
        setBirthday(new Date(newDate));
    };

    const changeMonth = (e) => {
        const newDate = new Date(birthday).setMonth(e.target.value);
        setBirthday(new Date(newDate));
    };

    const changeDay = (e) => {
        const newDate = new Date(birthday).setDate(e.target.value);
        setBirthday(new Date(newDate));
    };
    const days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
        22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ];
    const [errorMessage, setErrorMessage] = useState();
    const loginToken = LoginToken();
    // console.log(state);
    const submitContactInfo = () => {
        const reportedState = state ? state : 111;
        const reportedCountry = country ? country : 1;
        // console.log(city, reportedState, oldPrimaryEmail, reportedCountry)
        fetch(
            `https://portalapi.genni.com/api/v1/creatorapi/admininformation?x-api-key=${loginToken}`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ContactID: parseInt(params.ContactID),
                    UID: params.UID,
                    FirstName: firstName,
                    LastName: lastName,
                    AddressLine1: address,
                    City: city,
                    Birthday: birthday,
                    StateId: parseInt(reportedState),
                    PostalCode: postalCode,
                    CountryId: parseInt(reportedCountry),
                    PrimaryPhone: mobilePhone,
                    Birthday: `${birthday.getMonth() + 1}/${birthday.getDate()}/${birthday.getFullYear()}`
                })
            }
        )
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    await res.text().then((text) => {
                        const errText = new Error(text);
                        setErrorMessage(JSON.parse(text).errorReason.response.errors[0]);
                        setSuccessMessage()
                    });
                }
                throw res;
                //const jsonRes = JSON.parse(res);
            })
            .then((d) => {
                setRefetch(refetch + 1);
                setSuccessMessage("Profile info successfully updated.");
                toggle();
            });
    }

    const changeState = (e) => {
        setState(e.target.value);
    };
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader className="">
                Edit Info
            </ModalHeader>
            <ModalBody
                style={{
                    overflowY: "scroll",
                }}
            >
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                {step === 0 && (
                    <AvForm className="">
                        <div className="campaigns">

                            <Row className="mb-0">
                                <Col lg="6" sm="6" xs="6">
                                    <div className="campaign">
                                        <div className="campaign-title">
                                            <h6 className=" ">First Name</h6>
                                        </div>
                                        <AvField
                                            name="firstName"
                                            value={firstName}
                                            onChange={(e) => { setFirstName(e.target.value) }}
                                            type="text"
                                            className="inputField mb-3"
                                            errorMessage="Invalid First Name"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col lg="6" sm="6" xs="6">
                                    <div className="campaign">
                                        <div className="campaign-title">
                                            <h6 className=" ">Last Name</h6>
                                        </div>
                                        <AvField
                                            name="lastName"
                                            value={lastName}
                                            onChange={(e) => { setLastName(e.target.value) }}
                                            type="text"
                                            className="inputField mb-3"
                                            errorMessage="Invalid Last Name"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="campaign mb-3">
                                <div className="campaign-title">
                                    <h6 className="text-black ">Birthday</h6>
                                </div>
                                <div className="birthdayInput">
                                    <select
                                        onChange={changeMonth}
                                        className="inputField"
                                        value={new Date(birthday).getMonth()}
                                    >
                                        {months.map((option, i) => (
                                            <option key={option} value={i}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        onChange={changeDay}
                                        className="inputField"
                                        value={
                                            days[parseFloat(new Date(birthday).getDate() - 1)]
                                        }
                                    >
                                        {days.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        onChange={changeYear}
                                        className="inputField"
                                        value={new Date(birthday).getFullYear()}
                                    >
                                        {years.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <>
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className=" ">Mobile Phone</h6>
                                    </div>
                                    <PhoneInput
                                        defaultCountry="us"
                                        className="mb-3"
                                        value={mobilePhone}
                                        onChange={(phone) => setMobilePhone(phone)}
                                    />
                                </div>
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className=" ">Address</h6>
                                    </div>
                                    <AvField
                                        name="address"
                                        value={address}
                                        onChange={(e) => { setAddress(e.target.value) }}
                                        type="text"
                                        className="inputField mb-3"
                                        errorMessage="Invalid Address"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </div>
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className=" ">City</h6>
                                    </div>
                                    <AvField
                                        name="city"
                                        value={city}
                                        onChange={(e) => { setCity(e.target.value) }}
                                        type="text"
                                        className="inputField mb-3"
                                        errorMessage="Invalid City"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </div>
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className=" ">State/Province</h6>
                                    </div>
                                    {states && (
                                        <select
                                            onChange={changeState}
                                            value={state}
                                            className="mb-3 custom-select inputField"
                                        >
                                            {states.map((option, i) => (
                                                <option key={i} value={option.StateID}>
                                                    {option.StateName}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                                {(state === "111" || state === 111) && (
                                    <div className="campaign">
                                        <div className="campaign-title">
                                            <h6 className="text-black ">
                                                State/Province Other
                                            </h6>
                                        </div>
                                        <AvField
                                            name="stateOther"
                                            value={stateOther}
                                            onChange={(e) => { setStateOther(e.target.value) }}
                                            type="text"
                                            className="inputField mb-3"
                                            errorMessage="Invalid State Other"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </div>
                                )}
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className=" ">Zip/Postal Code</h6>
                                    </div>
                                    <AvField
                                        name="postalCode"
                                        value={postalCode}
                                        onChange={(e) => { setPostalCode(e.target.value) }}
                                        type="text"
                                        className="inputField mb-3"
                                        errorMessage="Invalid Post Code"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </div>
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className=" ">Country</h6>
                                    </div>
                                    {countries && (
                                        <select
                                            onChange={(e) => { setCountry(e.target.value) }}
                                            value={country}
                                            className="mb-3 inputField custom-select"
                                        >
                                            {countries.map((option, i) => (
                                                <option key={i} value={option.CountryID}>
                                                    {option.CountryName}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            </>
                        </div>
                    </AvForm>
                )}

            </ModalBody>
            <ModalFooter>
                <Button color="pink" onClick={submitContactInfo}>
                    Update
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default EditProfileModal;