import { Box, Step, Stepper, StepLabel } from "@material-ui/core";

const ProgressSteps = ({steps, activeStep}) => {
    const returnActiveStepInt = () => {
        let c = 0;
        if (steps){
          steps.map((e, i) => {
            if (e === activeStep) {
    
              c= i;
            }
          });
        }
        return c;
    }
    if (steps) {
        return (
            <>
              <div className="card container py-3 hide-desktop" style={{ width: "100%" }}>
                <h5 className="mb-2 madeGentle" style={{fontSize:16}}>Step {returnActiveStepInt()+1} of {steps.length}: {activeStep}</h5>
                <div style={{width:'100%',height:15,borderRadius:4,background:'#e9ecf2',}}>
                  <div style={{
                    width:`${((returnActiveStepInt() +1)/steps.length )*100}%`, 
                    background:'#f853bd', 
                    height:'100%', 
                    borderTopLeftRadius:4, 
                    borderBottomLeftRadius:4,
                    borderTopRightRadius:returnActiveStepInt() +1 === steps.length ? 4 : 0,
                    borderBottomRightRadius:returnActiveStepInt() +1 === steps.length ? 4 : 0}}>
                    
                  </div>
                </div>
              </div>
              <div className="card hide-mobile" style={{ width: "100%" }}>
                <Stepper
                    activeStep={returnActiveStepInt(activeStep)}
                    alternativeLabel
                    style={{ background: "transparent !important" }}
                    >
                    {steps &&
                        steps.map((label) => (
                        <Step key={label}>
                            <StepLabel className="madeGentle">{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </div>
            </>
        );
    }
}

export default ProgressSteps;