import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import logo from "../../assets/images/Logo.png";
import { Capacitor } from "@capacitor/core";
import { LoginToken } from "../Utility/common";
import { MdOutlineChevronRight } from "react-icons/md";

const FAQ = () => {
  const returnSkeleton = (randWidth) => {
    return (
      <SkeletonTheme color="#9c9c9c" highlightColor="#c9c9c9">
        <Skeleton height={15} width={randWidth} />
      </SkeletonTheme>
    );
  };
  const location = useLocation();
  
  let params = queryString.parse(location.search);
  const loginToken = LoginToken();

  const [data, setData] = useState();
  const cardsArr = [
    {
      name: "Invited",
      url: "/campaigns/invited",
      subtext: "2 Campaigns",
    },
    {
      name: "Accepted",
      url: "/campaigns/accepted",
      subtext: "3 Campaigns",
    },
    {
      name: "Active",
      url: "/campaigns/active",
      subtext: "2 Campaigns",
    },
    {
      name: "Completed",
      url: "/campaigns/completed",
      subtext: "0 Campaigns",
    },
    {
      name: "Expired",
      url: "/campaigns/expired",
      subtext: "1 Campaign",
    },
    {
      name: "Declined",
      url: "/campaigns/declined",
      subtext: "2 Campaigns",
    },
  ];

  useEffect(() => {
    fetch(`https://portalapi.genni.com/api/v1/creatorapi/faq?type=public`, 
    { method: "GET", 
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          setData(data);
        } else {
          // window.location.href = "/login";
        }
      });
  }, []);

  // console.log(data);
  return (
    <React.Fragment>
      <div className="navbar-header container">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-light">
              <span className="logo-lg">
                <img src={logo} alt="genni" className="nav-logo" />
              </span>
            </Link>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="d-flex">
            <Link to="/login">
              <span className="text-black">Login</span>
            </Link>
          </div>
          <div className="">
            <Link
              to="/hello"
              className="btn btn-md btn-pink "
              style={{ padding: 10, marginLeft: 16 }}
            >
              Get Started
            </Link>
          
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 madeGentle">FAQ</h4>
              </div>

              <div className="card p-3">
                {data
                  ? data.results.Row_FaqQuestions.map((e, i) => {
                      return (
                        <Link to={`/pagefaqlist/?FaqID=${e.FaqID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`} key={i}>
                          <div className="row-table pb-3 pt-3 px-3 border-bottom cursor-pointer">
                            <div className="row-group">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <h6
                                  className="madeGentle mb-0"
                                  style={{ fontSize: "1rem" }}
                                >
                                  {e.Question}
                                </h6>
                                <p
                                  className="text-muted mb-0"
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: 14,
                                  }}
                                >
                                  {e.subtext}
                                </p>
                              </div>
                            </div>
                            <MdOutlineChevronRight style={{fontSize:24, color:'#162638'}}/>

                          </div>
                        </Link>
                      );
                    })
                  : cardsArr.map((e, i) => {
                      return (
                        <div key={i} className="dark-card">
                          <div className="inner-card">
                            <div className="card-text">
                              <p>
                                {returnSkeleton(
                                  Math.floor(Math.random() * 100) + 100
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};;

export default FAQ;
