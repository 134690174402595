import { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { AvForm, AvField, } from "availity-reactstrap-validation";
import {Stepper, Step, StepLabel,} from "@material-ui/core";
import CreatorInfo from "./CreatorInfo";
import Profiles from "../SignUpSteps/Profiles";
import { first, last } from "lodash";

const AddCreatorModal = (
    {
    modalOpen, 
    toggle, 
    setCreators, 
    creators,
    firstName,
    lastName,
    address,
    gender,
    sexualOrientation,
    birthday,
    postalCode,
    city,
    contactPlatforms,
    country,
    state,
    stateOther,
    index
}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [fName, setFName] = useState(firstName);
    const [lName, setLName] = useState(lastName);
    const [addy, setAddy] = useState(address);
    const [gen, setGen] = useState(gender);
    const [sO, setSO] = useState(sexualOrientation);
    const [cit, setCit] = useState(city);
    const [st, setSt] = useState(state);
    const [stOther, setStOther] = useState(stateOther);
    const [pc, setPc] = useState(postalCode);
    const [cn, setCn] = useState(country);
    const [bDay, setBDay] = useState(birthday);
    const [profs, setProfs] = useState(contactPlatforms);
    const creatorObj = {
      firstName: fName,
      lastName: lName,
      addressLine1: addy,
      genderId: gen,
      sexualOrientationId: sO,
      city:cit,
      stateId:st,
      stateOther: stOther,
      postalCode: pc,
      countryId:`${cn ? cn.toString() : '1'}`,
      birthday:bDay,
      contactPlatforms:profs
    }
    function replaceObjectAtIndex(index, newObject) {
      setCreators(prevCreators => {
        const updatedCreators = [...prevCreators];
        
        // Check if the index is within the bounds of the array
        if (index >= 0 && index < updatedCreators.length) {
          // Replace the object at the specified index with the new object
          updatedCreators[index] = newObject;
        } else {
          // Handle the case when the index is out of bounds
          console.error("Index out of bounds");
        }
        
        // Return the updated array to update the state
        return updatedCreators;
      });
    }
    // console.log("creator obj",creatorObj);
    return (
        <>
            <Modal isOpen={modalOpen} toggle={toggle}>
                <AvForm className="">
                <ModalHeader>
                  <Stepper activeStep={currentStep} alternativeLabel className="full-width mt-0 pt-0 pb-0 mb-0">
                    <Step key="Info">
                      <StepLabel>Info</StepLabel>
                    </Step>
                    <Step key="Handles">
                      <StepLabel>Handles</StepLabel>
                    </Step>
                  </Stepper>
                </ModalHeader>
                <ModalBody>
                  {currentStep === 0 ? (
                    <CreatorInfo 
                      firstName={fName}
                      setFirstName={setFName}
                      lastName={lName}
                      setLastName={setLName}
                      address={addy}
                      setAddress={setAddy}
                      gender={gen}
                      setGender={setGen}
                      sexualOrientation={sO}
                      setSexualOrientation={setSO}
                      city={cit}
                      setCity={setCit}
                      state={st}
                      setState={setSt}
                      stateOther={stOther}
                      setStateOther={setStOther}
                      postalCode={pc}
                      setPostalCode={setPc}
                      country={cn}
                      setCountry={setCn}
                      birthday={bDay}
                      setBirthday={setBDay}
                    />
                  ) : (
                    <Profiles 
                      profiles={profs}
                      setProfiles={setProfs}
                    />
                  )}
                </ModalBody>
                <ModalFooter>
                    {currentStep === 0 ? (
                      <div className="btn btn-pink fw-semi" onClick={()=>{setCurrentStep(currentStep + 1)}}>Next</div>
                    ) : (
                      <div className="d-flex full-width" style={{justifyContent:'space-between'}}>
                        <div className="btn btn-secondary text-white fw-semi float-left" onClick={()=>{setCurrentStep(currentStep - 1)}}>Back</div>
                        <div className="btn btn-pink fw-semi" onClick={()=>{toggle(); replaceObjectAtIndex(index, creatorObj); setCurrentStep(0);}}>Submit</div>
                      </div>
                    )}
                </ModalFooter>
                </AvForm>
            </Modal>
        </>
    );
}

export default AddCreatorModal;