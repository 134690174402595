import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {Capacitor} from "@capacitor/core";
import Cookies from "js-cookie";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import "moment-timezone";
//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";

const MessageModal = (props) => {
    const {modal, message, subject, toggle} = props;
    
    return (
        <>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader className="madeGentle">{subject}</ModalHeader>
            <ModalBody style={{ maxHeight: 300, overflowY: "scroll" }}>
            <div dangerouslySetInnerHTML={{ __html: message }}></div>
            </ModalBody>
            <ModalFooter>
            
            <Button
                color="pink"
                onClick={toggle}
            >
                Close
            </Button>
            </ModalFooter>
        </Modal>
        </>
    );
};

export default withTranslation()(MessageModal);

MessageModal.propTypes = {
  t: PropTypes.any,
};
