import React, { useEffect, useState } from "react";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Container, Alert } from "reactstrap";
import Feedback from "../Feedback";
import { LoginToken, ValidateEmail } from "../Utility/common";

const TrackersContact = () => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("Trackers Request");
  const [name, setName] = useState("");
  const [accountType, setAccountType] = useState("Label");
  
  const [senderEmail, setSenderEmail] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();
  const [error, setError] = useState("");
  let params = queryString.parse(location.search);
  const loginToken = LoginToken();
  const [data, setData] = useState();
  const handleAccountTypeChange = (e) => {
    setAccountType(e.target.value);
  };
  const handleToChange = (e) => {
    setTo(e.target.value);
  };

  const handleSenderEmailChange = (e) => {
    setSenderEmail(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const [successMessage, setSuccessMessage] = useState();
  const returnHTML = () => {
    return (
      <p>
        The following person has requested access to trackers: {senderEmail}<br/>
        Type: accountType<br/>
        Message: {message}
      </p>
    );
  };
  const sendEmail = () => {
    fetch("https://mandrillapp.com/api/1.0/messages/send.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "md-fhneX5PkBIP4iGyWiZ_BeQ",
        message: {
          from_email: "genni@genni.com",
          to: [{ email: "info@genni.com" }, { email: "tech@genni.com" }],
          subject: subject,
          html: `The following person has requested access to marketing portal: ${senderEmail}, account type: ${accountType}, message: ${message}`,
        },
      }),
    })
      .then(async function(response) {
        if (response.ok) {
          return response.json();
        } else {
          await response.text().then((text) => {
            const errText = new Error(text);
            setSuccessMessage();
            setError("There has been an error.");
          });
        }
      })
      .then(function (data) {
        // console.log(data);
        setError("");
        setSuccessMessage("Message successfully sent! A member of our team will reach out to you soon.");
      })
      .catch(function (error) {
        // console.log(error);
        setError("There has been an error.")
      });
      fetch("https://mandrillapp.com/api/1.0/messages/send.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "md-fhneX5PkBIP4iGyWiZ_BeQ",
        message: {
          from_email: "genni@genni.com",
          to: [{ email: "website.inquiries@songfluencer.me" }],
          subject: "app.genni.com/trackerscontact | Get In Touch About Trackers",
          html: `[]EmailAddress||${senderEmail}[]PersonType||${accountType}[]InquiryMessage||${message}[]`,
        },
      }),
    })
      .then(async function(response) {
        if (response.ok) {
          return response.json();
        } else {
          await response.text().then((text) => {
            const errText = new Error(text);
            setSuccessMessage();
            setError("There has been an error.");
          });
        }
      })
      .then(function (data) {
        // console.log(data);
        setError("");
        setSuccessMessage("Message successfully sent! A member of our team will reach out to you soon.");
      })
      .catch(function (error) {
        // console.log(error);
        setError("There has been an error.")
      });
    
  };

  const isReadyToSubmit = ValidateEmail(senderEmail) && message;

  return (
    <React.Fragment>
      <div className="bg-pink" style={{height:5, width:'100%'}}>
      
      </div>
      <div className="page-content" style={{marginTop:'30px'}}>
        <Container className="bg-white p-3 card pb-4">
          {successMessage && <Alert color="success">{successMessage}</Alert>}
          {error && <Alert color="danger">{error}</Alert>}

          <>
            <p
              className="madeGentle pb-1"
              style={{ fontSize: 24, marginBottom: 0 }}
            >
              Get In Touch About Trackers 
            </p>
            <AvForm>
              <div className="campaigns">
                <div className="campaign mt-3">
                  <div className="campaign-title">
                    <h6 className="">Your Email</h6>
                  </div>
                  <AvField
                    name="email"
                    type="email"
                    className="inputField Archivo"
                    errorMessage="Invalid Email"
                    value={senderEmail}
                    onChange={handleSenderEmailChange}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
                <AvRadioGroup
                  inline
                  name="accountType"
                  label="Who Are You?"
                  required
                  onChange={handleAccountTypeChange}
                  value={accountType}
                >
                  <div className='d-flex'>
                    <div className={`p-3 option-button ${accountType === "Label" && "option-active"}`} onClick={() => { setAccountType("Label"); }}>
                      <AvRadio label="Label" value="Label" />
                    </div>
                    <div className={`p-3 ml-2 option-button ${accountType === "Artist" && "option-active"}`} onClick={() => { setAccountType("Artist"); }}>
                      <AvRadio label="Artist" value="Artist" />
                    </div>
                    <div className={`p-3 ml-2 option-button ${accountType === "Other" && "option-active"}`} onClick={() => { setAccountType("Other"); }}>
                      <AvRadio label="Other" value="Other" />
                    </div>
                  </div>
                </AvRadioGroup>
                
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className="">Message</h6>
                  </div>
                  <textarea
                    className="inputField Archivo"
                    name="message"
                    placeholder="Tell us about yourself and what you need to use Trackers for"
                    style={{ minWidth: "100%",minHeight:150 }}
                    value={message}
                    onChange={handleMessageChange}
                  ></textarea>
                </div>
                {!successMessage ? (
                  <div className="campaign">
                    <div
                      className={`btn btn-pink gradient-pink full-width float-right mt-3 ${!isReadyToSubmit && "disabled"}`}
                      style={{ width: "100%" }}
                      onClick={()=>{
                        if (isReadyToSubmit) {
                          sendEmail()
                        }
                      }}
                    >
                      Submit
                    </div>
                  </div>
                ) : (
                  <div className="campaign">
                    <div
                      className="btn full-width float-right"
                      style={{ width: "100%", backgroundColor:'lightgray', cursor:'unset' }}
                    >
                      Already Submitted
                    </div>
                  </div>
                )}
              </div>
            </AvForm>
          </>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TrackersContact;
