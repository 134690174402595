import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import queryString from "query-string";
import {
  Row,
  Col,

} from "reactstrap";

import { Capacitor } from "@capacitor/core";
import Skeleton from './skeleton';
import FAQQuestion from './faqquestion';
import { LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";

const FAQ = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const loginToken = LoginToken();
  const [data, setData] = useState();
  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  useEffect(() => {
    fetch(`https://portalapi.genni.com/api/v1/creatorapi/faq?type=account`, 
      { method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
       }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          setData(data);
        }
      });
  }, []);

  // console.log(data);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
            {Breadcrumbs("FAQ", "Home", `/dashboard${TokenAppenditure()}`)}

              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 madeGentle">FAQ</h4>
              </div>
        
              <div className="card p-3">
                {data
                  ? data.results.Row_FaqQuestions.map((e, i) => {
                      return (
                        <FAQQuestion e={e} token={loginToken} isNative={Capacitor.isNativePlatform()} />
                      );
                    })
                  : (
                    <>
                      <Skeleton/>
                      <Skeleton/>
                      <Skeleton/>
                      <Skeleton/>
                      <Skeleton/>
                    </>
                  )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};;

export default FAQ;
