import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'

import Skeleton from "react-skeleton-loader";
import { withRouter, Link  } from "react-router-dom";
import { Capacitor } from '@capacitor/core';
//i18n
import Cookies from "js-cookie";
import NotificationDropdown from "./NotificationDropdown";
import plusSign from "../../../assets/images/plus-sign.svg";
import { LoginToken, TokenAppenditure } from "../../../pages/Utility/common";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [modal, setModal] = useState(false);
  const {data, isLoading} = props;
  const contactImageUrl = Cookies.get("ContactDetail") && JSON.parse(Cookies.get("ContactDetail")).contactImageUrl;
  const contactName = Cookies.get("ContactDetail") && JSON.parse(Cookies.get("ContactDetail")).contactName;
  const hasImage = (Cookies.get("ContactDetail") && JSON.parse(Cookies.get("ContactDetail")).contactImageUrl) ;
  return (
    <React.Fragment>
      <div className="d-flex jc-center ai-center mr-2" style={{color: '#bfc3c9'}}>
      {!Capacitor.isNativePlatform() && (<NotificationDropdown />)}
        {hasImage || (data && data.results.LinkedAccounts.length > 0) ? (
            <div
              className={`rounded-circle header-profile-user ${Capacitor.isNativePlatform() ? "bg-white" : "bg-pink"}`}
              style={{
                padding: "0px",
                background: `url(${
                  hasImage && contactImageUrl
                })`,
                backgroundSize: "contain !important",
                display: "flex",
              }}
            ></div>
          ) : (
            <Link to={`/account/photo${TokenAppenditure()}`}>
              <div
                className="rounded-circle header-profile-user bg-pink"
                style={{
                  padding: "0px",
                  display: "flex",
                }}
              >
                {data && (
                  <img
                    src={plusSign}
                    alt="plusSign"
                    style={{ width: 20, height: "auto", margin: "auto" }}
                  />
                )}
              </div>
            </Link>
          )}

          {contactName ? (
            <>
              <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15 text-muted">
                {contactName}
              </span>{" "}
            </>
          ) : (
            <>
              <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
                <Skeleton width="100px" height="14px" color="#ced5e0" style={{marginTop:6}} />
              </span>{" "}
            </>
          )}
      
      </div>
      
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}


export default withRouter((ProfileMenu))
