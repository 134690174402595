
const States = [{StateName: ' Other/None', StateID: 111},
{StateName: 'Aguascalientes', StateID: 80},
{StateName: 'Alabama', StateID: 1},
{StateName: 'Alaska', StateID: 2},
{StateName: 'Alberta', StateID: 67},
{StateName: 'American Samoa', StateID: 52},
{StateName: 'Arizona', StateID: 3},
{StateName: 'Arkansas', StateID: 4},
{StateName: 'Armed Forces', StateID: 60},
{StateName: 'Armed Forces', StateID: 61},
{StateName: 'Armed Forces', StateID: 62},
{StateName: 'Baja California Norte', StateID: 81},
{StateName: 'Baja California Sur', StateID: 82},
{StateName: 'British Columbia', StateID: 68},
{StateName: 'California', StateID: 5},
{StateName: 'Campeche', StateID: 85},
{StateName: 'Canal Zone', StateID: 63},
{StateName: 'Chiapas', StateID: 87},
{StateName: 'Chihuahua', StateID: 83},
{StateName: 'Coahuila', StateID: 86},
{StateName: 'Colima', StateID: 84},
{StateName: 'Colorado', StateID: 6},
{StateName: 'Commonwealth of the Northern Mariana Islands', StateID: 66},
{StateName: 'Connecticut', StateID: 7},
{StateName: 'Delaware', StateID: 8},
{StateName: 'District of Columbia', StateID: 9},
{StateName: 'Distrito Federal', StateID: 88},
{StateName: 'Durango', StateID: 89},
{StateName: 'Federated States of Micronesia', StateID: 57},
{StateName: 'Florida', StateID: 10},
{StateName: 'Georgia', StateID: 11},
{StateName: 'Guam', StateID: 53},
{StateName: 'Guanajuato', StateID: 91},
{StateName: 'Guerrero', StateID: 90},
{StateName: 'Hawaii', StateID: 12},
{StateName: 'Hidalgo', StateID: 92},
{StateName: 'Idaho', StateID: 13},
{StateName: 'Illinois', StateID: 14},
{StateName: 'Indiana', StateID: 15},
{StateName: 'Iowa', StateID: 16},
{StateName: 'Jalisco', StateID: 93},
{StateName: 'Kansas', StateID: 17},
{StateName: 'Kentucky', StateID: 18},
{StateName: 'Louisiana', StateID: 19},
{StateName: 'Maine', StateID: 20},
{StateName: 'Manitoba', StateID: 69},
{StateName: 'Marshall Islands', StateID: 58},
{StateName: 'Maryland', StateID: 21},
{StateName: 'Massachusetts', StateID: 22},
{StateName: 'Michigan', StateID: 23},
{StateName: 'Michoacan', StateID: 94},
{StateName: 'Minnesota', StateID: 24},
{StateName: 'Mississippi', StateID: 25},
{StateName: 'Missouri', StateID: 26},
{StateName: 'Montana', StateID: 27},
{StateName: 'Morelos', StateID: 95},
{StateName: 'Nayarit', StateID: 96},
{StateName: 'Nebraska', StateID: 28},
{StateName: 'Nevada', StateID: 29},
{StateName: 'New Brunswick', StateID: 70},
{StateName: 'New Hampshire', StateID: 30},
{StateName: 'New Jersey', StateID: 31},
{StateName: 'New Mexico', StateID: 32},
{StateName: 'New York', StateID: 33},
{StateName: 'Newfoundland and Labrador', StateID: 71},
{StateName: 'North Carolina', StateID: 34},
{StateName: 'North Dakota', StateID: 35},
{StateName: 'Northern Mariana Islands', StateID: 54},
{StateName: 'Northwest Territories', StateID: 72},
{StateName: 'Nova Scotia', StateID: 73},
{StateName: 'Nuevo Leon', StateID: 97},
{StateName: 'Nunavut', StateID: 74},
{StateName: 'Oaxaca', StateID: 98},
{StateName: 'Ohio', StateID: 36},
{StateName: 'Oklahoma', StateID: 37},
{StateName: 'Ontario', StateID: 75},
{StateName: 'Oregon', StateID: 38},
{StateName: 'Palau', StateID: 59},
{StateName: 'Pennsylvania', StateID: 39},
{StateName: 'Philippine Islands', StateID: 64},
{StateName: 'Prince Adward Island', StateID: 76},
{StateName: 'Puebla', StateID: 99},
{StateName: 'Puerto Rico', StateID: 55},
{StateName: 'Quebec', StateID: 77},
{StateName: 'Queretaro', StateID: 101},
{StateName: 'Quintana Roo', StateID: 100},
{StateName: 'Rhode Island', StateID: 40},
{StateName: 'San Luis Potosi', StateID: 103},
{StateName: 'Saskatchewan', StateID: 78},
{StateName: 'Sinaloa', StateID: 102},
{StateName: 'Sonora', StateID: 104},
{StateName: 'South Carolina', StateID: 41},
{StateName: 'South Dakota', StateID: 42},
{StateName: 'Tabasco', StateID: 105},
{StateName: 'Tamaulipas', StateID: 107},
{StateName: 'Tennessee', StateID: 43},
{StateName: 'Texas', StateID: 44},
{StateName: 'Tlaxcala', StateID: 106},
{StateName: 'Trust Territory of the Pacific Islands', StateID: 65},
{StateName: 'Utah', StateID: 45},
{StateName: 'Veracruz', StateID: 108},
{StateName: 'Vermont', StateID: 46},
{StateName: 'Virgin Islands', StateID: 56},
{StateName: 'Virginia', StateID: 47},
{StateName: 'Washington', StateID: 48},
{StateName: 'West Virginia', StateID: 49},
{StateName: 'Wisconsin', StateID: 50},
{StateName: 'Wyoming', StateID: 51},
{StateName: 'Yucatan', StateID: 109},
{StateName: 'Yukon', StateID: 79},
{StateName: 'Zacatecas', StateID: 110}];

export default States;