import { CommonLoader, NoResults, LoginToken,  } from "../Utility/common";
import PaymentTable from "../Utility/paymenttable";
import {  useHistory } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import moment from "moment";
import "moment-timezone";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";

const calcDateValue = (d) => {
  const t = new Date("January 1 1970 00:00:00-0:00");
  t.setSeconds(t.getSeconds() + parseFloat(d));
  const momentT = moment(t);
  return momentT.tz(moment.tz.guess()).format("MMM D hh:mmA z");
};

const CampaignPayments = ({gridType, data, setCurrentPage, currentPage, totalPages}) => {
    const history = useHistory();
    const loginToken = LoginToken();
   
    return (
        <>
        {gridType === "grid" ? (
            <div className="campaigns mt-2">
            {data ? (
              <div className="campaigns">
                {data.results.data.Row_Campaigns.length > 0 ? (
                  data.results.data.Row_Campaigns.map((e, i) => {
                    // console.log(e);
                    return (
                      <div
                        key={i}
                        className="campaign card p-3 mb-2 mt-0"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push(`/detail?PostID=${e.PostID}&UID=${e.UID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`);
                        }}
                      >
                        <div
                          className="campaign-title border-bottom border-ppink pb-2 mb-2"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <h5 className="fw-bold mb-0 paymentsTitle" style={{fontSize:'16px',}}>
                            {e.CampaignTitle}
                          </h5>
                          <h5
                            className="fw-bold mb-0"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: 0,
                              maxHeight: 19,
                              fontSize:16,
                            }}
                          >
                            <span>{e.PostPayment}</span>{" "}
                          </h5>
                        </div>
                        {
                          e.Row_CampaignDetails.map((a,n)=>{
                            return (
                              <div className="campaign-details mt-1 mb-0">
                                <div className="campaign-detail">
                                  <p className="fw-bold mb-0 ">{a.FieldName}: <span className="fw-normal">{a.FieldName.includes("Date") ? calcDateValue(a.FieldValue) : a.FieldValue}</span></p>
                                </div>
                              </div>
                            )
                          })
                        }
                        
                      </div>
                    );
                  })
                ) : (
                  <NoResults className="mt-4" />
                )}
              </div>
            ) : (
              <CommonLoader />
            )}
            {currentPage && totalPages > 1 && (
              <div className="card mt-2" style={{width:'100%'}}>
                <div
                  className="px-4"
                  style={{
                    textAlign: "right",
                    marginTop: 16,
                    paddingBottom: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <p className="px-4" style={{ fontSize: 14 }}>
                    Page {currentPage} of {totalPages}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 27,
                      marginTop: -10,
                      width: 72,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className={`pagination-btn ${
                        currentPage > 1
                          ? ""
                          : "disabled-pagination-btn text-muted"
                      }`}
                      onClick={() => {
                        if (currentPage > 1) {
                          setCurrentPage(parseInt(currentPage) - 1);
                        }
                      }}
                    >
                      {" "}
                      <MdOutlineChevronLeft />
                    </div>
                    <div
                      className={`pagination-btn ${
                        currentPage < totalPages
                          ? ""
                          : "disabled-pagination-btn text-muted"
                      }`}
                      onClick={() => {
                        if (currentPage < totalPages) {
                          setCurrentPage(parseInt(currentPage) + 1);
                          
                        }
                      }}
                    >
                      {" "}
                      <MdOutlineChevronRight />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          ) : (
            <PaymentTable
              data={data ? data.results.data.Row_Campaigns : null}
              pagination={true}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />

          )}
        </>
    )
}

export default CampaignPayments;