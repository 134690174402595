import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { useLocation } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import queryString from "query-string";
import Contests from "./contests";
import { API } from "aws-amplify";
import { listCompetitions } from "../../graphql/queries";
import MyEntries from "./myentries";
import countriesContinentList from "./countries-continents";
import { Capacitor } from "@capacitor/core";
import useGeoLocation from "react-ipgeolocation";
import HowItWorks from "./howitworks";
import {
  LoginToken,
  TokenAppenditure,
  Breadcrumbs,
  stylingTabCode,
} from "../Utility/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

function compare(a, b) {
  const aPostsLength = a.posts.items.length ? a.posts.items.length : 1;
  const aInvoiceAmount = a.invoiceAmount ? a.invoiceAmount : 1000;
  const aExpectedValue = aInvoiceAmount / 20;
  const bPostsLength = b.posts.items.length ? b.posts.items.length : 1;
  const bInvoiceAmount = b.invoiceAmount ? b.invoiceAmount : 1000;
  const bExpectedValue = bInvoiceAmount / 20;
  const aRatio = aExpectedValue - aPostsLength;
  const bRatio = bExpectedValue - bPostsLength;
  if (aRatio > bRatio) {
    return -1;
  }
  if (aRatio < bRatio) {
    return 1;
  }
  return 0;
}

const Campaigns = () => {
  const location = useLocation();
  const [exclusiveCompetitions, setExclusiveCompetitions] = useState();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, val) => {
    setTabValue(val);
  };
  const loginToken = LoginToken();

  const [myLocation, setMyLocation] = useState(null);
  const geoLocation = useGeoLocation();

  useEffect(() => {
    const fetchLocation = () => {
      if (geoLocation.country !== undefined) {
        // If the country is defined, set it as the location and stop polling
        setMyLocation(geoLocation);
      } else {
        // If the country is still undefined, wait and then fetch again
        setTimeout(fetchLocation, 1000); // Adjust the timeout duration as needed
      }
    };

    if (myLocation === null) {
      // If location is null, start the initial fetch
      fetchLocation();
    }
  }, [geoLocation.country, myLocation]);

  const getContinentByCountryCode = (countryCode) => {
    const mapping = countriesContinentList.find(
      (item) => item.CC === countryCode
    );
    return mapping.continent;
  };

  const fetchExclusiveCompeititions = async () => {
    const myLocationCountry = get(myLocation, ["country"], "");

    if (!exclusiveCompetitions) {
      fetch(
        `https://portalapi.genni.com/api/v1/creatorapi/check-approval-status`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": loginToken,
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then(async (d) => {
          if (d.results.approvalStatus.ApprovalStatusDescription !== "Denied") {
            let competitions = [];
            let nextToken = null;

            do {
              const apiData = await API.graphql({
                query: listCompetitions,
                variables: {
                  limit: 10000,
                  filter: { isActive: { eq: true } },
                  nextToken: nextToken,
                },
              });

              const myContinent = getContinentByCountryCode(
                d.results.countrycode
              );

              if (
                (d.results.countrycode !== "PH" &&
                  myLocationCountry !== "PH") ||
                d.results.approvalStatus.ApprovalStatusDescription ===
                  "Approved"
              ) {
                const items = apiData.data.listCompetitions.items.filter(
                  (e) => {
                    if (e.countries || e.continents) {
                      const validCountry =
                        !e.countries ||
                        (e.countries.includes(d.results.countrycode) &&
                          (!myLocationCountry ||
                            e.countries.includes(myLocationCountry)));
                      const validContinent =
                        !e.continents || e.continents.includes(myContinent);

                      if (validCountry && validContinent) {
                        return e._deleted !== true && !e.hasEnded && e.isActive;
                      }
                    } else {
                      return e._deleted !== true && !e.hasEnded && e.isActive;
                    }
                  }
                );

                competitions = competitions.concat(items);
              }

              nextToken = apiData.data.listCompetitions.nextToken;
            } while (nextToken);

            setExclusiveCompetitions(competitions.sort(compare));
          }
        });
    }
  };

  const [postStatusID, setPostStatusID] = useState("3");
  const returnTab = () => {
    if (tabValue === 0) {
      return (
        <Contests
          competitions={exclusiveCompetitions}
          exclusiveCompetitions={exclusiveCompetitions}
        />
      );
    } else if (tabValue === 1) {
      return <MyEntries />;
    } else if (tabValue === 2) {
      return <HowItWorks />;
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    fetchExclusiveCompeititions();
  }, [postStatusID, myLocation]);
  return (
    <React.Fragment>
      <style>
        {Capacitor.isNativePlatform()
          ? `.PrivateTabIndicator-colorSecondary-3 {
        background-color: #fff !important;
        height: 3px !important;
      }
      .MuiTab-wrapper {color:#162638 !important;}.MuiTabs-indicator {background-color: #162638 !important;}`
          : `.MuiTabs-indicator {background-color: #f853bd !important;}`}
      </style>

      {Capacitor.isNativePlatform() && (
        <>
          <div
            style={{
              height: 500,
              background: "#fff",
              width: "100%",
              marginTop: "-500px",
            }}
          ></div>

          <div
            className="card mobileTabs bg-white shadow-none border-none"
            style={{ boxShadow: "none !important" }}
          >
            <Tabs
              className=""
              value={tabValue}
              TabIndicatorProps={{
                style: {
                  justifyContent: "space-between",
                  background: `${
                    Capacitor.isNativePlatform() ? "#f853bd" : `#fff`
                  }`,
                },
              }}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Active Contests"
                disableRipple
                value={0}
                {...a11yProps(0)}
              />
              <Tab
                label="My Entries"
                disableRipple
                value={1}
                {...a11yProps(1)}
              />
              <Tab
                label="How It Works"
                disableRipple
                value={2}
                {...a11yProps(1)}
              />
            </Tabs>
          </div>
        </>
      )}

      <div className="page-content">
        {Capacitor.isNativePlatform() && (
          <div style={{ marginTop: "-12px" }}></div>
        )}
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {Breadcrumbs("Contests", "Home", `/dashboard${TokenAppenditure}`)}

              {!Capacitor.isNativePlatform() && (
                <h4 className="madeGentle mb-0">Contests</h4>
              )}
              {!Capacitor.isNativePlatform() && (
                <div
                  className="mt-2 mb-3"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid #e5e7eb",
                  }}
                >
                  <Tabs
                    className=""
                    value={tabValue}
                    TabIndicatorProps={{ style: { background: `#fff` } }}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Active Contests"
                      disableRipple
                      value={0}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="My Entries"
                      disableRipple
                      value={1}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label="How It Works"
                      disableRipple
                      value={2}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </div>
              )}

              {returnTab()}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Campaigns;
