import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import Cookies from "js-cookie";
import logo from "../../assets/images/whiteg.png";
import { withRouter, Link } from "react-router-dom"
import { Preferences } from '@capacitor/preferences';
import { useLocation, useHistory } from "react-router-dom";
import { Capacitor } from '@capacitor/core';
import NativeLogin from './NativeLogin';
import SignUp from './SignUp';


const Welcome = (props) => {
  const [error, setError] = useState("");
  const [localToken, setLocalToken] = useState();
  const history = useHistory();
  const location = useLocation();
  const [welcomeState, setWelcomeState] = useState("Welcome");
  const [cardHeight, setCardHeight] = useState("50vh");
  const [borderRadius, setBorderRadius] = useState("60px");
  const getLoginTokenState = async ()=>{
    const { value } = await Preferences.get({ key: 'LoginToken' });
    const tok = value  ? value : "none";
    // console.log(`Hello ${value}!`);
    setLocalToken(tok);
    return tok;
  }

 
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    getLoginTokenState()
    
    if (localToken && localToken !== "none"){
      fetch(
        `https://portalapi.genni.com/api/v1/creatorapi/check`,
        { method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key":localToken },
        
         }
      )
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          }else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setError(JSON.parse(errText.message).messages[0]);
          });
        }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then(async (data) => {
          // console.log("data",data);
          if (data.results.success) {
            history.push(`/dashboard${Capacitor.isNativePlatform() ? `?token=${localToken}` : ""}`);
          }
        });
      
    }
    return function cleanup() {
      document.body.className = "";
    };
  }, [localToken]);

  const returnCardBody = () => {
    if (welcomeState === "Welcome") {
      return (<>
        <h2 className='madeGentle mt-5'>Welcome To Genni!</h2>
          <span className='text-muted'>The Ultimate Way To Monetize Your Socials</span>
          <div className='d-flex flex-col mt-4'>
            <div onClick={()=>{setWelcomeState("login");setCardHeight("60vh"); setBorderRadius(60);}} className='btn gradient-pink btn-pink mt-2' style={{width:290, minHeight:50}}>Log In</div>
            <div onClick={()=>{setWelcomeState("hello");setCardHeight("100vh"); setBorderRadius(0);}} className='btn gradient-pink btn-light mt-3 bg-light' style={{width:290, minHeight:50, }}>Sign Up</div>

          </div>
      </>);
    } else if (welcomeState === "login") {
      return (<NativeLogin setWelcomeState={setWelcomeState} setCardHeight={setCardHeight} setBorderRadius={setBorderRadius} />);
    } else {
      return (<SignUp setWelcomeState={setWelcomeState} setCardHeight={setCardHeight} setBorderRadius={setBorderRadius} />);
    }
    
  }

  return (
    <div >
      <div className="bg-pink" style={{display:'flex',height:'150vh', width:'100%', marginTop:'-50vh', position:'relative'}}>
        <img src={logo} alt="white-logo" style={{height: 90, width: 90, margin: 'auto', marginTop:'75vh'}} />
        <div className='bg-white pt-4 nativeRoundedCard' style={{overflowY:'scroll', height:`${cardHeight}`, width:'100%', position:'absolute',display:'flex',flexDirection:'column', alignItems:'center', bottom:0,borderTopLeftRadius:borderRadius, borderTopRightRadius:borderRadius}}>
          
          {returnCardBody()}
        </div>
      </div>
      
    </div>
  );
}

export default withRouter((Welcome))

Welcome.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}