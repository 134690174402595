import { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { HiPlusCircle, HiQuestionMarkCircle } from "react-icons/hi";
import { MdInfoOutline } from "react-icons/md";

export const SongRateTooltip = () => {
    const [modal, setModal] = useState();
    const toggle = () => {
        setModal(!modal);
    }
    return (
        <>
            <HiQuestionMarkCircle className="cursor-pointer" onClick={toggle} style={{fontSize:17,marginTop:'-2px'}} />
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className="d-flex">
                    Song Rates
                </ModalHeader>
                <ModalBody>
                    <p>
                        <h6>What is a Song Rate?</h6>
                        The price in USD you would charge for promoting a song in one post.<br /><br />
                        <h6>What should I Charge?</h6>
                        In order to maximize offers, we recommend charging $1.50 for every thousand views. For example, if you average 100K views per video, we'd recommend that your song rate be $150.
                        <br/><br/>
                        <h6>Can I Negotiate?</h6>
                        Yes. Once an offer is sent, you have the ability to decline due to price. However, it is up to the client whether to counter.
                
                    </p>
                </ModalBody>
            </Modal>
        </>
    );
}

export const BrandRateTooltip = () => {
    const [modal, setModal] = useState();
    const toggle = () => {
        setModal(!modal);
    }
    return (
        <>
            <HiQuestionMarkCircle className="cursor-pointer" onClick={toggle} style={{fontSize:17, marginTop:'-2px'}} />
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className="d-flex">
                    Brand Rates
                </ModalHeader>
                <ModalBody>
                    <p>
                        <h6>What is a Brand Rate?</h6>
                        The price in USD you would charge for promoting a brand or product in one post.<br /><br />
                        <h6>What should I Charge?</h6>
                        In order to maximize offers, we recommend charging $3 for every thousand views. For example, if you average 100K views per video, we'd recommend that your brand rate be $300.
                        <br/><br/><h6>Can I Negotiate?</h6>
                        Yes. Once an offer is sent, you have the ability to decline due to price. However, it is up to the client whether to counter.
                    </p>
                </ModalBody>
            </Modal>
        </>
    );
}

export const ResponseRateTooltip = () => {
    const [modal, setModal] = useState();
    const toggle = () => {
        setModal(!modal);
    }
    return (
        <>
            <MdInfoOutline className="cursor-pointer" onClick={toggle} />
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className="d-flex">
                    What is a response rate?
                </ModalHeader>
                <ModalBody>
                    <p>
                        A response rate is the percentage of invitations that were responded to before the deadline. It is one of the main determining factors when choosing creators for a campaign. Declining a campaign invitation does not negatively impact your response rate, only ignoring or letting the invitation expire has a negative effect.
                    </p>
                </ModalBody>
            </Modal>
        </>
    );
}