import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { LoginToken, TokenAppenditure, ValidateEmail } from "../Utility/common";
import Loader from "react-loader-spinner";
import {
    Row,
    Col,
    Alert,
    BreadcrumbItem,
} from "reactstrap";
import { MdCheckCircle, MdEdit, MdEditDocument, MdEditNote, MdUpdate, MdWarning, MdAddCircle } from "react-icons/md";
import { add, first, set } from "lodash";
import { TbAlertCircleFilled } from "react-icons/tb";

const ConfirmInfoModal = ({ isOpen, toggle, contactInfo, paymentInfo, profiles }) => {
    // console.log("profiles",profiles);
    const [contactPlatforms, setContactPlatforms] = useState(profiles.results.ContactPlatforms)
    const [Platforms, setPlatforms] = useState(profiles.results.Platforms);
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(0);
    const oldPaymentInfo = paymentInfo;
    const [PayPalEmail, setPayPalEmail] = useState(paymentInfo);
    const oldFirstName = contactInfo.results.ContactInformation.FirstName;
    const [firstName, setFirstName] = useState(contactInfo.results.ContactInformation.FirstName);
    const oldLastName = contactInfo.results.ContactInformation.LastName;
    const [lastName, setLastName] = useState(contactInfo.results.ContactInformation.LastName);
    const oldAddress = contactInfo.results.ContactInformation.AddressLine1;
    const [address, setAddress] = useState(contactInfo.results.ContactInformation.AddressLine1);
    const oldCity = contactInfo.results.ContactInformation.City;
    const [city, setCity] = useState(contactInfo.results.ContactInformation.City);
    const oldCountry = contactInfo.results.ContactInformation.Country;
    const [country, setCountry] = useState(contactInfo.results.ContactInformation.CountryID);
    const oldState = contactInfo.results.ContactInformation.State;
    const [state, setState] = useState(contactInfo.results.ContactInformation.StateID);
    const oldStateOther = contactInfo.results.ContactInformation.State_Other;
    const [stateOther, setStateOther] = useState(contactInfo.results.ContactInformation.State_Other);
    const oldPostalCode = contactInfo.results.ContactInformation.PostalCode;
    const oldPrimaryEmail = contactInfo.results.ContactInformation.EmailAddress_Primary;
    const [postalCode, setPostalCode] = useState(contactInfo.results.ContactInformation.PostalCode);
    const [errorMessage, setErrorMessage] = useState();
    const loginToken = LoginToken();
    const handlePayPalEmailChange = (e) => {
        setPayPalEmail(e.target.value);
    };
    // console.log(state);
    const proceed = () => {
        if (isGoodToProceed()) {
            setStep(step + 1);
        }
    }
    const back = () => {
        setStep(step - 1);
    }
    const submitContactInfo = () => {
        setIsLoading(true);
        const reportedState = state ? state : 111;
        const reportedCountry = country ? country : 1;
        // console.log(city, reportedState, oldPrimaryEmail, reportedCountry)
        const platformsArr = profiles.results.Platforms;
        contactPlatforms.forEach(profile => {
            // Find the corresponding platform object
            const platform = platformsArr.find(platform => platform.PlatformName === profile.Platform);
            // If platform found, add PlatformID to profile
            if (platform) {
                profile.PlatformID = platform.PlatformID;
            }
            if (profile.SongRate) {
                profile.SongRate = !Number.isInteger(profile.SongRate) ? parseFloat(profile.SongRate.replace('$', '')) : profile.SongRate;
            }
            if (profile.BrandRate) {
                profile.BrandRate = !Number.isInteger(profile.BrandRate) ? parseFloat(profile.BrandRate.replace('$', '')) : profile.BrandRate;
            }
        });
        fetch(
            `https://portalapi.genni.com/api/v1/creatorapi/confirm-info`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json", "x-api-key": loginToken },
                body: JSON.stringify(
                    {
                        FirstName: firstName,
                        LastName: lastName,
                        Address: address,
                        City: city,
                        StateID: parseInt(reportedState),
                        PostalCode: postalCode,
                        CountryID: parseInt(reportedCountry),
                        PaymentEmail: PayPalEmail,
                        profileArray: contactPlatforms
                      }
                )
            }
        )
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    await res.text().then((text) => {
                        const errText = new Error(text);
                        setErrorMessage(JSON.parse(errText.message).messages[0]);
                    });
                }
                throw res;
                //const jsonRes = JSON.parse(res);
            })
            .then((d) => {
                if (d.results.success) {
                    if (oldPaymentInfo === PayPalEmail) {
                        toggle();
                    } else {
                        setStep(step+1);
                    }
                } else {
                    setIsLoading(false);
                    setErrorMessage(d.results.message);
                }
                
            });
    }

    const changeState = (e) => {
        setState(e.target.value);
    };

    const isGoodToProceed = () => {
        if (step === 0 && firstName && lastName && address && city && postalCode) {
            return true;
        }
        if (step === 1 && ValidateEmail(PayPalEmail)) {
            return true;
        }
    }
    return (
        <Modal isOpen={isOpen}>
            <ModalHeader className="fw-bolder">
                Please Confirm The Below Information
            </ModalHeader>
            <ModalBody
                style={{
                    overflowY: "scroll",
                }}
            >
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                {step === 0 && (
                    <AvForm className="">
                        <div className="campaigns">

                            <Row className="mb-0">
                                <Col lg="6" sm="6" xs="6">
                                    <div className="campaign">
                                        <div className="campaign-title">
                                            <h6 className=" ">First Name</h6>
                                        </div>
                                        <AvField
                                            name="firstName"
                                            value={firstName}
                                            onChange={(e) => { setFirstName(e.target.value) }}
                                            type="text"
                                            className="inputField mb-3"
                                            errorMessage="Invalid First Name"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col lg="6" sm="6" xs="6">
                                    <div className="campaign">
                                        <div className="campaign-title">
                                            <h6 className=" ">Last Name</h6>
                                        </div>
                                        <AvField
                                            name="lastName"
                                            value={lastName}
                                            onChange={(e) => { setLastName(e.target.value) }}
                                            type="text"
                                            className="inputField mb-3"
                                            errorMessage="Invalid Last Name"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <>
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className=" ">Address</h6>
                                    </div>
                                    <AvField
                                        name="address"
                                        value={address}
                                        onChange={(e) => { setAddress(e.target.value) }}
                                        type="text"
                                        className="inputField mb-3"
                                        errorMessage="Invalid Address"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </div>
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className=" ">City</h6>
                                    </div>
                                    <AvField
                                        name="city"
                                        value={city}
                                        onChange={(e) => { setCity(e.target.value) }}
                                        type="text"
                                        className="inputField mb-3"
                                        errorMessage="Invalid City"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </div>
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className=" ">State/Province</h6>
                                    </div>
                                    {contactInfo && (
                                        <select
                                            onChange={changeState}
                                            value={state}
                                            className="mb-3 custom-select inputField"
                                        >
                                            {contactInfo.results.SelectionTables.State.map((option, i) => (
                                                <option key={i} value={option.StateID}>
                                                    {option.StateName}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                                {(state === "111" || state === 111) && (
                                    <div className="campaign">
                                        <div className="campaign-title">
                                            <h6 className="text-black ">
                                                State/Province Other
                                            </h6>
                                        </div>
                                        <AvField
                                            name="stateOther"
                                            value={stateOther}
                                            onChange={(e) => { setStateOther(e.target.value) }}
                                            type="text"
                                            className="inputField mb-3"
                                            errorMessage="Invalid State Other"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </div>
                                )}
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className=" ">Zip/Postal Code</h6>
                                    </div>
                                    <AvField
                                        name="postalCode"
                                        value={postalCode}
                                        onChange={(e) => { setPostalCode(e.target.value) }}
                                        type="text"
                                        className="inputField mb-3"
                                        errorMessage="Invalid Post Code"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </div>
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className=" ">Country</h6>
                                    </div>
                                    {contactInfo && (
                                        <select
                                            onChange={(e) => { setCountry(e.target.value) }}
                                            value={country}
                                            className="mb-3 inputField custom-select"
                                        >
                                            {contactInfo.results.SelectionTables.Country.map((option, i) => (
                                                <option key={i} value={option.CountryID}>
                                                    {option.CountryName}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            </>
                        </div>
                    </AvForm>
                )}
                {step === 1 && (
                    <AvForm>
                        <>

                            <div className="campaigns">
                                <div className="campaign">
                                    <div className="campaign-title">
                                        <h6 className="text-black ">PayPal Email</h6>
                                        {/* <p className="text-muted">Genni pays creators via PayPal. If you don't have an account, please sign up at PayPal.com.</p> */}
                                    </div>
                                    <AvField
                                        name="PayPalEmail"
                                        value={PayPalEmail}
                                        onChange={handlePayPalEmailChange}
                                        type="text"
                                        className="inputField mb-3 "
                                        errorMessage="Invalid Email"
                                    />
                                </div>

                            </div>
                        </>
                    </AvForm>
                )}

                {step === 2 && (
                    <>
                        <div className="d-flex jc-space-between mb-1 al-center">
                            <h6 className="fw-semi">Profiles</h6>
                        </div>
                        {contactPlatforms &&
                            contactPlatforms.map((e, x) => {
                                return (
                                    <div className="bg-light p-3 mb-3" style={{ borderRadius: 8, }}>
                                        <Row>
                                            <Col xs={5} >
                                                <p className="mb-0">Platform</p>
                                            </Col>
                                            <Col xs={7}>
                                                <p className="mb-0">Handle</p>
                                            </Col>
                                        </Row>
                                        <>
                                            <Row className="mb-2">
                                                <Col xs={5}>
                                                    <select
                                                        className="inputField custom-select bg-white"
                                                        id="Sortbylist-job"
                                                        value={e.Platform}
                                                        onChange={(event) => {
                                                            const shallowCopy = [...contactPlatforms];
                                                            shallowCopy[x].Platform = event.target.value;
                                                            setContactPlatforms(shallowCopy);
                                                        }}
                                                    >
                                                        {Platforms.map((a, i) => {
                                                            return (
                                                                <option key={i} value={a.PlatformName}>
                                                                    {a.PlatformName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </Col>

                                                <Col xs={6}>
                                                    <input
                                                        value={`${e.Handle[0] !== "@" ? "@" : ""}${e.Handle}`}
                                                        onChange={(a) => {
                                                            const shallowCopy = [...contactPlatforms];
                                                            shallowCopy[x].Handle = a.target.value;
                                                            setContactPlatforms(shallowCopy);
                                                        }}
                                                        className="inputField form-control"
                                                        disabled={!e.IsEditable}
                                                    />
                                                </Col>
                                                <Col xs={1} style={{ marginTop: '-70px', justifyContent: 'end', display: 'flex' }}>
                                                    <div
                                                        className="d-flex"
                                                        style={{ alignItems: "center", height: "100%" }}
                                                        onClick={() => {
                                                            const profilesWithRemoval = contactPlatforms.filter(
                                                                (profile, count) => count !== x
                                                            );
                                                            setContactPlatforms(profilesWithRemoval);
                                                        }}
                                                    >
                                                        <i className="mdi mdi-close-circle" style={{ fontSize: 20, }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={5} >
                                                    {/* <CustomTooltip tooltipText="The price you'd charge for promoting a song in a post" titleText="Song Promo Rate"/> */}
                                                    Song Rate 
                                                </Col>
                                                <Col xs={7}>
                                                    {/* <CustomTooltip tooltipText="The price you'd charge for promoting a brand in a post" titleText="Brand Promo Rate"/> */}
                                                    Brand Rate 
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={5}>
                                                    <i className="mdi mdi-currency-usd input-icon"></i>
                                                    <input
                                                        value={`${e.SongRate[0] === "$" ? parseFloat(e.SongRate.substring(1)) : e.SongRate}`}
                                                        onChange={(a) => {
                                                            const shallowCopy = [...contactPlatforms];
                                                            shallowCopy[x].SongRate = a.target.value;
                                                            setContactPlatforms(shallowCopy);
                                                        }}
                                                        style={{ paddingLeft: 18 }}
                                                        type="number"
                                                        className="inputField form-control"
                                                    />
                                                </Col>
                                                <Col xs={6}>
                                                    <i className="mdi mdi-currency-usd input-icon" ></i>
                                                    <input
                                                        value={`${e.BrandRate[0] === "$" ? parseFloat(e.BrandRate.substring(1)) : e.BrandRate}`}
                                                        onChange={(a) => {
                                                            const shallowCopy = [...contactPlatforms];
                                                            shallowCopy[x].BrandRate = a.target.value;
                                                            setContactPlatforms(shallowCopy);
                                                        }}
                                                        style={{ paddingLeft: 18 }}
                                                        type="number"
                                                        className="inputField form-control"
                                                    />
                                                </Col>

                                            </Row>
                                        </>
                                    </div>
                                );

                            })}
                            <Button size="md" color="pink" onClick={() => {
                                const shallowCopy = [...contactPlatforms];
                                shallowCopy.push({ Platform: 'TikTok', Handle: 'example', SongRate: "$10.00", BrandRate: "$20.00", IsEditable: true });
                                setContactPlatforms(shallowCopy);
                            }}>
                                <MdAddCircle style={{marginTop:'-2px'}} /> Add Profile
                            </Button>
                    </>
                )}
                {step === 3 && (
                    <>
                        <div className="flex flex-col justify-center text-center py-2">
                            <div className="rounded-full" style={{ background: 'rgb(249, 250, 251)', height: 68, width: 68, borderRadius: '100%', display: 'flex', justifyContent: 'center', margin: "AUTO", alignItems: 'center' }}>
                                <MdWarning size={36} color="rgb(239, 68, 68)" />
                            </div>
                            <h4 className="mt-3">One more step!</h4>
                            <p className="mt-0 font-semibold" style={{ fontSize: '1rem' }}>
                                Please click the confirmation link in your email to approve the change to your PayPal.
                            </p>
                        </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                {step === 0 && (
                    <Button color="pink" disabled={!isGoodToProceed()} onClick={proceed}>
                        Next
                    </Button>
                )}
                {step === 1 && (
                    <>
                        <Button color="default" onClick={back}>
                            Back
                        </Button>
                        <Button color="pink" disabled={!ValidateEmail(PayPalEmail)} onClick={proceed}>
                            Next
                        </Button>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Button color="default" onClick={back}>
                            Back
                        </Button>
                        <Button color="pink" onClick={submitContactInfo}>
                            {isLoading ? (
                                <Loader
                                    type="Bars"
                                    color="#fff"
                                    width={20}
                                    height={20}
                                />
                            ) : (
                                "Submit"
                            )}
                            
                        </Button>
                    </>
                )}
                {step === 3 && (
                    <>  
                        <Button color="pink" onClick={toggle}>
                            Okay
                        </Button>
                    </>
                )}
            </ModalFooter>
        </Modal>
    );
}

export default ConfirmInfoModal;