import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
} from "reactstrap";
import { get } from "lodash";
import { useLocation } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Capacitor } from "@capacitor/core";
import useGeoLocation from "react-ipgeolocation";
import HowItWorks from "./howitworks";
import { LoginToken, TokenAppenditure, Breadcrumbs, CommonLoader, NoResults } from "../Utility/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Explore = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const handleTabChange = (e, val) => {
    setTabValue(val);
  }
  const loginToken = LoginToken();

  const [myLocation, setMyLocation] = useState(null);
  const geoLocation = useGeoLocation();

  useEffect(() => {
    const fetchLocation = () => {
      if (geoLocation.country !== undefined) {
        // If the country is defined, set it as the location and stop polling
        setMyLocation(geoLocation);
      } else {
        // If the country is still undefined, wait and then fetch again
        setTimeout(fetchLocation, 1000); // Adjust the timeout duration as needed
      }
    };

    if (myLocation === null) {
      // If location is null, start the initial fetch
      fetchLocation();
    }
  }, [geoLocation.country, myLocation]);

  const [opportunities, setOpportunities] = useState()
  const returnTab = () => {
    if (tabValue === 0) {
      return (
        <>
          {isLoading ? (
            <>
              <CommonLoader />
            </>
          ) : (
            <>
              {opportunities && opportunities.explore.length > 0 ? opportunities.explore.map(e => {
                return (
                  <div className="card exploreOpportunity">
                    <img className="exploreImg" src={e.ExploreSurvey_ImageURLLocation} />
                    <div className="p-4 d-flex" style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                      <h4 className="madeGentle">{e.ExploreSurveyTitle_ExternalPublic}</h4>
                      <p>
                        {e.ExploreSurveyDescription}
                      </p>
                      <p style={{ fontStyle: 'italic' }}> <i className="mdi mdi-clock text-pink mr-1 mb-0"></i>{" "}
                        <>
                          <span className="">Deadline To Apply:</span>{" "}
                          <span className="">{e.ExploreSurveyEndDate}</span>
                        </></p>

                      <a target="_blank" href={`https://info.genni.com/survey/response.cfm?ID=${e.encryptedID}&UID=${e.ExploreSurveyUID}`}>
                        <div className="btn btn-light" style={{ maxWidth: 120 }}>Apply Now</div>
                      </a>
                    </div>
                    <div>

                    </div>
                  </div>
                );
              }) : (
                <NoResults className="mt-4" />
              )}
            </>
          )}


        </>
        // 


      );
    } else if (tabValue === 1) {
      return (
        <HowItWorks />
      );
    }
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  useEffect(() => {
    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/explore?x-api-key=${loginToken}`,
      { method: "get" }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        setIsLoading(false);
        setOpportunities(data);
      })
      .catch((error) => {
      });
  }, []);
  return (
    <React.Fragment>
      <style>{Capacitor.isNativePlatform() ? `.PrivateTabIndicator-colorSecondary-3 {
        background-color: #fff !important;
        height: 3px !important;
      }
      .MuiTab-wrapper {color:#162638 !important;}.MuiTabs-indicator {background-color: #162638 !important;}` : `.MuiTabs-indicator {background-color: #f853bd !important;}`
      }</style>


      {Capacitor.isNativePlatform() && (
        <>
          <div style={{ height: 500, background: '#fff', width: '100%', marginTop: '-500px' }}></div>

          <div
            className="card mobileTabs bg-white shadow-none border-none"
            style={{ boxShadow: 'none !important', }}
          >
            <Tabs className="" value={tabValue} TabIndicatorProps={{ style: { justifyContent: 'space-between', background: `${Capacitor.isNativePlatform() ? "#f853bd" : `#fff`}` } }} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="Active Opportunities" disableRipple value={0} {...a11yProps(0)} />
              <Tab label="How It Works" disableRipple value={1} {...a11yProps(1)} />
            </Tabs>
          </div>
        </>
      )}


      <div className="page-content">
        {Capacitor.isNativePlatform() && (<div style={{ marginTop: '-12px' }}></div>)}
        <div className="container">

          <Row>

            <Col className="col-12 mt-0">
              {Breadcrumbs("Explore", "Home", `/dashboard${TokenAppenditure}`)}

              {!Capacitor.isNativePlatform() && (<h4 className="madeGentle mb-0">Explore Opportunities</h4>)}
              {!Capacitor.isNativePlatform() && (
                <div
                  className="mt-2 mb-3"
                  style={{ display: "flex", flexDirection: "row", borderBottom: '1px solid #e5e7eb' }}
                >
                  <Tabs className="" value={tabValue} TabIndicatorProps={{ style: { background: `#fff` } }} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Active Opportunities" disableRipple value={0} {...a11yProps(0)} />
                    <Tab label="How It Works" disableRipple value={1} {...a11yProps(1)} />
                  </Tabs>
                </div>
              )}

              {returnTab()}

            </Col>
          </Row>
        </div>
      </div>

    </React.Fragment>
  );
};

export default Explore;