import React, {useState} from "react";
import UpdateCreatorModal from "./UpdateCreatorModal";
import { update } from "lodash";
import States from "../SignUpSteps/States";
import Countries from "../SignUpSteps/Countries";
import { MdEdit, MdClose } from "react-icons/md";

const CreatorRow = ({ e,i, creators,setCreators }) => {
    const [updateCreatorModalOpen, setUpdateCreatorModalOpen] = useState(false);
    
    const toggleUpdate = () =>{
        setUpdateCreatorModalOpen(!updateCreatorModalOpen);
    }
    function getStateNameById(stateId) {
        const state = States.find(item => item.StateID === parseInt(stateId));
        return state ? state.StateName : null;
    }
    function getCountryNameById(countryID) {
        const country = Countries.find(item => item.CountryID === parseInt(countryID));
        return country ? country.CountryName : null;
    }
    const removeItemAtIndex = (index) => {
        const updatedItems = [...creators]; // Create a copy of the original array
        updatedItems.splice(index, 1); // Remove the element at the specified index
        setCreators(updatedItems); // Update the state with the new array
    };
    return (
        <tr>
            <td>{e.firstName}</td>
            <td>{e.lastName}</td>
            <td>{e.addressLine1}</td>
            <td>{e.city}</td>
            <td>{getStateNameById(e.stateId)}</td>
            <td>{e.postalCode}</td>
            <td>{getCountryNameById(e.countryId)}</td>
            <td>{e.birthday.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            })}</td>
            <td>{e.contactPlatforms.length}</td>
            <td>
                <div className="d-flex">
                    <div className="btn btn-light btn-small mr-2" onClick={toggleUpdate}>
                        <MdEdit />
                    </div>
                    <div className="btn btn-light" onClick={() => { removeItemAtIndex(i) }}>
                        <MdClose />
                    </div>
                </div>
            </td>
            <UpdateCreatorModal
                creators={creators}
                setCreators={setCreators}
                modalOpen={updateCreatorModalOpen}
                toggle={toggleUpdate}
                firstName={e.firstName}
                lastName={e.lastName}
                address={e.addressLine1}
                city={e.city}
                state={e.stateId}
                stateOther={e.stateOther}
                postalCode={e.postalCode}
                country={e.country}
                birthday={e.birthday}
                gender={e.genderId}
                sexualOrientation={e.sexualOrientationId}
                contactPlatforms={e.contactPlatforms}
                index={i}
            />
        </tr>
    )

};

export default CreatorRow;