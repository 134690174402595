import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import moment from "moment";
import Loader from "react-loader-spinner";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import "moment-timezone";
//Import images


//i18n
import { withTranslation } from "react-i18next";

const DisputeModal = (props) => {
    const {modal, setModal, message, email, setEmail} = props;
    const [successMessage, setSuccessMessage] = useState();
    const [isLoading, setIsLoading] = useState();
    const [error, setError] = useState();
    const changeEmail =(e)=>{
        setEmail(e.target.value);
    }

    const toggle = () =>{
        setModal(!modal);
    }

    const submit = () => {
        setIsLoading(true);
        fetch("https://mandrillapp.com/api/1.0/messages/send.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "md-fhneX5PkBIP4iGyWiZ_BeQ",
        message: {
          from_email: "genni@genni.com",
          to: [{ email: "tech@genni.com" }, { email: "outreach@genni.com" }],
          subject: "Dispute Claim",
          html: `${email}, message: ${message}`,
        },
      }),
    })
      .then(async function(response) {
        if (response.ok) {
          return response.json();
        } else {
          await response.text().then((text) => {
            const errText = new Error(text);
            setSuccessMessage();
            setError("There has been an error.");
          });
        }
      })
      .then(function (data) {
        // console.log(data);
        setError("");
        setIsLoading(false);
        setSuccessMessage("Dispute successfully sent! A member of our team will reach out to you soon.");
      })
      .catch(function (error) {
        // console.log(error);
        setError("There has been an error.")
      });
    }

    return (
        <>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader className="madeGentle">Resolve Dispute</ModalHeader>
            <ModalBody style={{ maxHeight: 300, overflowY: "scroll" }}>
                {!successMessage ? (
                    <>
                        <Alert color="danger">
                            {message} If you own the social media account and want access to it, please fill out the following form:
                        </Alert>
                        <AvForm>
                            <div className="campaigns">
                                <div className="campaign mt-3">
                                    <div className="campaign-title">
                                        <h6 className="">Email</h6>
                                    </div>
                                    <AvField
                                        name="email"
                                        type="email"
                                        className="inputField Archivo"
                                        errorMessage="Invalid Email"
                                        value={email}
                                        disabled
                                    />
                                </div>
                            </div>
                        </AvForm>
                    </>
                ) : (
                    <Alert color="success">{successMessage}</Alert>
                )}
            </ModalBody>
            <ModalFooter>
            <Button
                color="default"
                onClick={toggle}
            >
                Close
            </Button>
            <Button
                color="pink"
                onClick={()=>{
                    if (email && !successMessage) {
                        submit();
                    }
                }}
                className={`${(!email || successMessage) && 'disabled'} border-none`}
            >
              {isLoading ? (
                <Loader
                  type="Bars"
                  color="#fff"
                  width={20}
                  height={20}
                />
              ) : (
                "Submit"
              )}
            </Button>
            </ModalFooter>
        </Modal>
        </>
    );
};

export default withTranslation()(DisputeModal);

DisputeModal.propTypes = {
  t: PropTypes.any,
};
