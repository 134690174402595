import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  Row,
  Col,
} from "reactstrap";
import CustomTooltip from './CustomTooltip';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Step, Stepper, StepLabel } from "@material-ui/core";
//Import images
import { AvForm, AvField } from "availity-reactstrap-validation";
import './style.scss';
import { MdClose } from "react-icons/md";

//i18n
import { withTranslation } from "react-i18next";
import { LoginToken } from "../Utility/common";
import { Capacitor } from "@capacitor/core";
import { BrandRateTooltip, SongRateTooltip } from "../Utility/modals";

function range(start, thisYear) {
  const arr = [];
  for (let index = start; index < thisYear; index++) {
    arr.push(index);
  }
  return arr;
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const OnboardingForm = (props) => {
    const {isOpen} = props;
    // Declare a new state variable, which we'll call "menu"
    const location = useLocation();
    let params = queryString.parse(location.search);
    const loginToken = LoginToken(); // => 'value'
    const [details, setDetails] = useState();
    const [steps, setSteps] = useState();
    const [current, setCurrent] = useState(0);
    const [data,setData] = useState();
    const [activeStep, setActiveStep] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [companyName, setCompanyName] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [allowText, setAllowText] = useState(true);
    const [gender, setGender] = useState("1");
    const [sexualOrientation, setSexualOrientation] = useState("1");
    const [personalPronoun, setPersonalPronoun] = useState(1);
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState(111);
    const [topMarkets, setTopMarkets] = useState(['United States','United States','United States']);
    const [postalCode, setPostalCode] = useState();
    const [country, setCountry] = useState();
    const [primaryPhone, setPrimaryPhone] = useState();
    const [mobilePhone, setMobilePhone] = useState();
    const [stateOther, setStateOther] = useState();
    const [birthday, setBirthday] = useState(new Date("1/1/1910"));
    const [paymentEmail, setPaymentEmail] = useState();
    const [profiles, setProfiles] = useState();
    
    const submit = () => {
      setIsLoading(true);
      const newProfiles= profiles && profiles.map((e)=>{
        const platformID = data && data.data.SelectionTables.Platform.filter(a=>{
          if (e.Platform === a.PlatformName) {
            return a.PlatformID;
          }
        });
        const BrandRate = e.BrandRate && e.BrandRate[0] === "$" ? e.BrandRate.substring(1) : e.BrandRate;
        const SongRate = e.SongRate && e.SongRate[0] === "$" ? e.SongRate.substring(1) : e.SongRate;
        return {handle: e.Handle, platformId: platformID[0].PlatformID,brandRate:parseFloat(BrandRate), rate:parseFloat(SongRate)};
      });
      const topMarketList = [];
      topMarkets.map(e=>{
        data.data.SelectionTables.Country.map(a=>{
          if (e === a.CountryName) {
            topMarketList.push(a.CountryID);
          }
        })
      });
      fetch(
        `https://api.genni.com/profiles?x-api-key=${loginToken}`,
        {
          method: "POST",
          body: `profileArray=`+JSON.stringify(newProfiles)+``, // <-- Post parameters

        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          fetch(
            `https://api.genni.com/information?x-api-key=${loginToken}&firstname=${firstName}&lastname=${lastName}&addressline1=${address}&city=${city}&stateid=${state}&postalcode=${postalCode}&countryid=${country}&primaryphone=${primaryPhone}&mobilephone=${mobilePhone}&primaryemail=${email}&genderidentityid=${gender}&sexualOrientationId=${sexualOrientation}&AllowText=${allowText}&otherState=${stateOther}&birthday=${birthday.getFullYear()}-${
              birthday.getMonth() + 1
            }-${birthday.getDate()}`,
            { method: "POST" }
          )
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw res;
              //const jsonRes = JSON.parse(res);
            })
            .then((d) => {
              fetch(
                `https://api.genni.com/vendor?x-api-key=${loginToken}&email=${encodeURIComponent(paymentEmail)}`,
                { method: "POST" }
              )
                .then((res) => {
                  if (res.ok) {
                    return res.json();
                  }
                  throw res;
                  //const jsonRes = JSON.parse(res);
                })
                .then((da) => {
                  fetch(
                    `https://api.genni.com/market?x-api-key=${loginToken}&topmarketlist=${topMarketList}`,
                    { method: "POST" }
                  )
                    .then((res) => {
                      if (res.ok) {
                        return res.json();
                      }
                      throw res;
                      //const jsonRes = JSON.parse(res);
                    })
                    .then((dat) => {
                      setIsLoading(false);
                      setCurrent(current + 1);
                    });
                });
            });
        });
    }

    useEffect(() => {
      fetch(
        `https://api.genni.com/fullProfile?x-api-key=${loginToken}`,
        { method: "GET" }
      )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          // console.log("onboarding data",data);
          setData(data);
          setDetails(data.data.Details);
          setSteps(data.data.Step.Available);
          // setCurrent(data.data.Step.Available.indexOf(data.data.Step.Current));
          setCompanyName(data.data.Details[0].Fields[0].Value);
          setFirstName(data.data.Details[0].Fields[1].Value);
          setLastName(data.data.Details[0].Fields[2].Value);
          setAddress(data.data.Details[0].Fields[4].Value);
          setCity(data.data.Details[0].Fields[6].Value);
          setState(
            data.data.Details[0].Fields[7].Value
              ? data.data.Details[0].Fields[7].Value
              : "-"
          );
          setStateOther(data.data.Details[0].Fields[8].Value);
          setPostalCode(data.data.Details[0].Fields[9].Value);
          setCountry(data.data.Details[0].Fields[10].Value ? data.data.Details[0].Fields[10].Value : "1");
          setPrimaryPhone(data.data.Details[0].Fields[11].Value);
          setAllowText(true);
          setEmail(data.data.Details[0].Fields[14].Value);
          setGender(data.data.Details[0].Fields[16].Value);
          setPersonalPronoun(data.data.Details[0].Fields[17].Value);
          setBirthday(
            new Date(data.data.Details[0].Fields[3].Value.slice(0, -5))
          );
          setSexualOrientation(data.data.Details[0].Fields[18].Value);
          setPaymentEmail(data.data.Details[1].Fields[12].Value);
          
        }
      });
      fetch(`https://portalapi.genni.com/api/v1/creatorapi/profiles?x-api-key=${loginToken}`, {
        method: "GET",
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          setProfiles(data.results.ContactPlatforms);
        });
    }, []);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handlePostalCodeChange = (e) => {
    setPostalCode(e.target.value);
  };

  const handlePrimaryPhoneChange = (e) => {
    setPrimaryPhone(e.target.value);
  };

  const handleStateOtherChange = (e) => {
    setStateOther(e.target.value);
  };

  const handleMobilePhoneChange = (e) => {
    setMobilePhone(e.target.value);
  };

  const handleSOChange = (e) => {
    setSexualOrientation(e.target.value);
  };

  const handlePrimaryEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const changeState = (e) => {
    setState(e.target.value);
  };

  const changeCountry = (e) => {
    setCountry(e.target.value);
  };

  const handlePaymentEmailChange = (e) => {
    setPaymentEmail(e.target.value);
  };

  const changeYear = (e) => {
    const newDate = new Date(birthday).setFullYear(e.target.value);
    setBirthday(new Date(newDate));
  };

  const changeMonth = (e) => {
    const newDate = new Date(birthday).setMonth(e.target.value);
    setBirthday(new Date(newDate));
  };

  const changeDay = (e) => {
    const newDate = new Date(birthday).setDate(e.target.value);
    setBirthday(new Date(newDate));
  };

  const isGoodToProceed = () => {
    if (current === 0 && (firstName && lastName) && address && city && state && postalCode && primaryPhone !== undefined && primaryPhone && isValidDate(birthday) && birthday.getTime() !== new Date("1/1/1910").getTime()) {
      return true;
    } else if (current === 1 && paymentEmail){
      return true;
    } else if (current === 2 && profiles.length > 0){
      return true;
    } else if (current === 3 && topMarkets) {
      return true;
    } else if (current === 4) {
      return true;
    }
    return false;
  }

  const years = range(1910, 2009);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
    const returnProperForm = () => {
      if (current === 0) {
        return (
          <AvForm className="">
            <div className="campaigns">
              {!firstName && (
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className="">First Name</h6>
                  </div>
                  <AvField
                    name="firstName"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    type="text"
                    className="inputField mb-3 required"
                    errorMessage="Invalid First Name"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
              )}
              {!lastName && (
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className="">Last Name</h6>
                  </div>
                  <AvField
                    name="lastName"
                    value={lastName}
                    onChange={handleLastNameChange}
                    type="text"
                    className="inputField mb-3 required"
                    errorMessage="Invalid Last Name"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
              )}
              <Row>
                <Col xs="6">
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className=" ">
                    {/* <CustomTooltip tooltipText="By providing your gender, we'll be able to send you
                      additional campaigns." titleText="Gender"/> */}
                      Gender
                    </h6>
                  </div>
                  <select
                    className="custom-select mb-3 inputField"
                    id="Sortbylist-job"
                    value={gender}
                    onChange={handleGenderChange}
                  >
                    {data &&
                      data.data.SelectionTables.GenderIdentity.map((e, i) => {
                        return (
                          <option value={e.GenderIdentityID} key={i}>
                            {e.GenderIdentityDescription}
                          </option>
                        );
                      })}
                  </select>
                </div>
                </Col>
                <Col xs="6">
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className=" ">
                      {/* <CustomTooltip tooltipText="By providing your sexual orientation, we'll be able to send you
                      additional campaigns." titleText="Sexual Orientation"/> */}
                      {" "}
                      Sexual Orientation
                    </h6>
                  </div>
                  <select
                    className="custom-select mb-3 inputField"
                    id="Sortbylist-job"
                    value={sexualOrientation}
                    onChange={handleSOChange}
                  >
                    {data &&
                      data.data.SelectionTables.SexualOrientation.map((e, i) => {
                        return (
                          <option value={e.SexualOrientationID} key={i}>
                            {e.SexualOrientationDescription}
                          </option>
                        );
                      })}
                  </select>
                </div>
                </Col>
              </Row>
              <div className="campaign mb-3">
                <div className="campaign-title">
                  <h6 className="text-black ">Birthday</h6>
                </div>
                <div className="birthdayInput">
                  <select
                    onChange={changeMonth}
                    className="required inputField"
                    value={new Date(birthday).getMonth()}
                  >
                    {months.map((option, i) => (
                      <option key={option} value={i}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    onChange={changeDay}
                    className="required inputField"
                    value={days[parseFloat(new Date(birthday).getDate() - 1)]}
                  >
                    {days.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    onChange={changeYear}
                    className="required inputField "
                    value={new Date(birthday).getFullYear()}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">Address</h6>
                </div>
                <AvField
                  name="address"
                  value={address}
                  onChange={handleAddressChange}
                  type="text"
                  className="inputField mb-3 required"
                  errorMessage="Invalid Address"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>

              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">City</h6>
                </div>
                <AvField
                  name="city"
                  value={city}
                  onChange={handleCityChange}
                  type="text"
                  className="inputField mb-3 required"
                  errorMessage="Invalid City"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">State/Province</h6>
                </div>
                {data && (
                  <select
                    onChange={changeState}
                    value={state}
                    className="mb-3 custom-select inputField required"
                  >
                    <option value="-">
                      -
                    </option>
                    {data.data.SelectionTables.State.map((option, i) => (
                      <option key={i} value={option.StateID}>
                        {option.StateName}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              {(state === "111" || state === 111) && (
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className="text-black ">
                      State/Province Other
                    </h6>
                  </div>
                  <AvField
                    name="stateOther"
                    value={stateOther}
                    onChange={handleStateOtherChange}
                    type="text"
                    className="inputField mb-3 required"
                    errorMessage="Invalid State Other"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
              )}
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">Zip/Postal Code</h6>
                </div>
                <AvField
                  name="postalCode"
                  value={postalCode}
                  onChange={handlePostalCodeChange}
                  type="text"
                  className="inputField mb-3 required"
                  errorMessage="Invalid Postal Code"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">Country</h6>
                </div>
                {data && (
                  <select
                    onChange={changeCountry}
                    value={country}
                    className="mb-3 inputField custom-select required"
                  >
                    {data.data.SelectionTables.Country.map((option, i) => (
                      <option key={i} value={option.CountryID}>
                        {option.CountryName}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">Mobile Phone</h6>
                </div>
                <AvField
                  name="primaryPhone"
                  value={primaryPhone}
                  onChange={handlePrimaryPhoneChange}
                  type="text"
                  className="inputField mb-3 required"
                  errorMessage="Invalid Primary"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>

              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={allowText}
                  onClick={() => {
                    setAllowText(!allowText);
                  }}
                  id="customControlInline"
                />
                <label
                  className="form-check-label"
                  htmlFor="customControlInline"
                >
                  Check to receive text message notifications
                </label>
              </div>
            </div>
          </AvForm>
        );
      } else if (current === 1) {
        return (
          <AvForm className="">
            <div className="campaigns">
             
              <Row>
                
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className="text-black ">PayPal Email</h6>
                  </div>
                  <AvField
                    name="paymentPrimaryEmail"
                    value={paymentEmail}
                    onChange={handlePaymentEmailChange}
                    type="text"
                    className="inputField mb-3 required"
                    errorMessage="Invalid Email"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
              </Row>
              
              
            </div>
          </AvForm>
        );
      } else if(current === 2) {
        return (
          <div>
            <div className="campaign-title">
              <h6 className="text-black ">Social Media Profiles</h6>
            </div>
            
              {profiles &&
                profiles.map((e, x) => {
                  return (
                    <div className="bg-light p-2 mb-3" style={{borderRadius:8}}>
                      <Row>
                        <Col xs={5} >
                          <p className="mb-0">Platform</p>
                        </Col>
                        <Col xs={7}>
                          <p className="mb-0">Handle</p>
                        </Col>
                      </Row>
                    <>
                      <Row className="mb-2">
                        <Col xs={5}>
                          <select
                            className="inputField custom-select bg-white"
                            id="Sortbylist-job"
                            value={e.Platform}
                            onChange={(event) => {
                              const shallowCopy = [...profiles];
                              shallowCopy[x].Platform = event.target.value;
                              setProfiles(shallowCopy);
                            }}
                          >
                            {data &&
                              data.data.SelectionTables.Platform.map((a, i) => {
                                return (
                                  <option key={i} value={a.PlatformName}>
                                    {a.PlatformName}
                                  </option>
                                );
                              })}
                          </select>
                        </Col>
                        
                        <Col xs={6}>
                          <input
                            value={`${e.Handle[0] !== "@" ? "@" : ""}${e.Handle}`}
                            onChange={(a) => {
                              const shallowCopy = [...profiles];
                              shallowCopy[x].Handle = a.target.value;
                              setProfiles(shallowCopy);
                            }}
                            className="inputField form-control"
                          />
                        </Col>
                        <Col xs={1} style={{marginTop:'-70px'}}>
                          <div
                            className="d-flex"
                            style={{ alignItems: "center", height: "100%" }}
                            onClick={() => {
                              const profilesWithRemoval = profiles.filter(
                                (profile, count) => count !== x
                              );
                              setProfiles(profilesWithRemoval);
                            }}
                          >
                            <div style={{background:'#162638', borderRadius:'100%', height:20, width:20, display:'flex', alignItems:'center', justifyContent:'center', marginTop:-8, cursor:'pointer'}}>
                              <MdClose style={{color:'#fff',fontSize:14}} />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={5} >
                        {/* <CustomTooltip tooltipText="The price you'd charge for promoting a song in a post" titleText="Song Promo Rate"/> */}
                        Song Rate <SongRateTooltip />
                        </Col>
                        <Col xs={7}>
                          {/* <CustomTooltip tooltipText="The price you'd charge for promoting a brand in a post" titleText="Brand Promo Rate"/> */}
                          Brand Rate <BrandRateTooltip />
                        </Col>
                      </Row>
                      <Row>
                      <Col xs={5}>
                        <i className="mdi mdi-currency-usd input-icon"></i>
                        <input
                          value={`${e.SongRate[0] === "$" ? parseFloat(e.SongRate.substring(1)) : e.SongRate}`}
                          onChange={(a) => {
                            const shallowCopy = [...profiles];
                            shallowCopy[x].SongRate = a.target.value;
                            setProfiles(shallowCopy);
                          }}
                          style={{paddingLeft:18}}
                          type="number"
                          className="inputField form-control"
                        />
                        </Col>
                        <Col xs={6}>
                          <i className="mdi mdi-currency-usd input-icon" ></i>
                          <input
                            value={`${e.BrandRate[0] === "$" ? parseFloat(e.BrandRate.substring(1)) : e.BrandRate}`}
                            onChange={(a) => {
                              const shallowCopy = [...profiles];
                              shallowCopy[x].BrandRate = a.target.value;
                              setProfiles(shallowCopy);
                            }}
                            style={{paddingLeft:18}}
                            type="number"
                            className="inputField form-control"
                          />
                        </Col>

                      </Row>
                    </>
                    </div>
                  );

                })}
            
            <div
              className="btn btn-pink float-right"
              onClick={() => {
                const shallowCopy = [...profiles];
                shallowCopy.push({Platform: 'TikTok', Handle:'example', SongRate: "$10.00", BrandRate:"$20.00" });
                setProfiles(shallowCopy);
              }}
            >
              <i className="mdi mdi-plus-circle mr-2"></i>Add Handle
            </div>
          </div>
        );
      } else if(current === 3) {
        return (
          <div>
            <h6>Where do your followers live?</h6>
            {topMarkets.map((e,i) =>{
              return (
                <>
                  <div style={{display:'flex',flexDirection:'column'}}>
                    <h6>{i + 1}.</h6>
                    {data && (
                      <select
                        onChange={(a) => {
                          const shallowCopy = [...topMarkets];
                          shallowCopy[i] = a.target.value;
                          setTopMarkets(shallowCopy);
                        }}
                        value={e}
                        className="inputField custom-select mb-3"
                      >
                        {data.data.SelectionTables.Country.map((option, i) => (
                          <option key={i} value={option.CountryName}>
                            {option.CountryName}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </>
              );

            })}
          </div>
        )
      } else if (current === 4) {
        return (
          <div className="p-2 margin-auto" style={{textAlign:'center'}}>
            Thanks for submitting! A representative will review your profile
            shortly. Check back soon!
          </div>
        );

      }
    }

    return (
      <div id="oc-body" className={`p-${location.pathname.includes("dashboard") ? "4" : "2"} pt-0`}>
          <style>{`${isOpen && `html{overflow:hidden}`}`}</style>
            <Stepper
              activeStep={current}
              alternativeLabel
              className="mt-3 pt-2 mb-4"
              style={{
                background: "transparent !important",
                width: "100%",
                paddingBottom: 0,
              }}
            >
              {steps &&
                steps.map((label) => (
                  <Step key={label}>
                    <StepLabel className="madeGentle">{label === "Top Markets" ? "Markets"  : label}</StepLabel>
                  </Step>
                ))}
            </Stepper>
          
            {returnProperForm()}
            <div className="d-flex mb-3 mt-3" style={{justifyContent:`${current > 0 ? `space-between` : `flex-end`}`}}>
              {current > 0 && current < 4 && (
                <Button
                  color="default"
                  onClick={() => {
                    document.getElementById("oc-body").scrollTop = 0;
                    setCurrent(current - 1);
                  }}
                >
                  Back
                </Button>
              )}
              {current < 3 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <div
                      color=""
                      className={`btn border-none btn-pink ${!isGoodToProceed() && "disabled"}`}
                      style={{ maxWidth: 100 }}
                      onClick={() => {
                        if (isGoodToProceed()) {
                          document.getElementById(
                            "oc-body"
                          ).scrollTop = 0;
                          setCurrent(current + 1);
                        }
                      }}
                    >
                      Next
                    </div>
                  </div>
                </>
              )}

              {current === 3 && (
                <Button
                  color="pink"
                  onClick={() => {
                    submit();
                  }}
                >
                  {isLoading ? (
                    <Loader type="Bars" color="#fff" width={20} height={20} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              )}

            </div>
            
          {!isGoodToProceed() && (
            <>
              <span className="text-danger mb-4" style={{textAlign:'right'}}>
                Fill out all required fields to continue.
              </span>
              <br/><br/>
            </>
          )}
          
      </div>
    );
};

export default withTranslation()(OnboardingForm);
