import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import Cookies from "js-cookie";
import { Link, withRouter, useLocation } from "react-router-dom"
import classname from "classnames"
import queryString from "query-string";
//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"

const Navbar = (props) => {
  const {data} = props;
  const location = useLocation();
  const isManager = (Cookies.get("ContactDetail") && JSON.parse(Cookies.get("ContactDetail")).contactType === "manager");
  return (
    <React.Fragment>
      <div
        className="topnav"
        style={{ display: "flex", position: "absolute" }}
      >
        <div className="container">
          <nav
            className="navbar navbar-light navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-expand-xs topnav-menu"
            id="navigation"
          >
            <div isOpen={props.leftMenu} id="topnav-menu-content">
              <ul
                className="navbar-nav"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <li className="nav-item ">
                  <Link className={`nav-link Archivo ${location.pathname.includes("/dashboard") && "active"}`} to={`/dashboard`}>
                    <i className="uil-home-alt me-2"></i> {props.t("Home")}
                  </Link>
                </li>

                <li className="nav-item dropdown ">
                  <Link
                    to={`/payments`}
                    
                    className={`nav-link Archivo ${location.pathname.includes("/payments") && "active"}`}
                  >
                    <i className="uil-wallet me-2"></i>
                    {props.t("Payments")}
                  </Link>
                </li>
                {isManager ? (
                  <li className="nav-item dropdown mr-0">
                    <Link
                      to={`/account/accounts`}

                      className={`nav-link Archivo ${location.pathname.includes("/account/accounts") && "active"}`}
                    >
                      <i className="uil-cog me-2"></i>
                      Manage Roster
                    </Link>
                  </li>
                ) : (
                  (
                    <li className="nav-item dropdown mr-0">
                      <Link
                        to={`/account/social/`}
  
                        className={`nav-link Archivo ${location.pathname.includes("/account/social/") && "active"}`}
                      >
                        <i className="uil-at me-2"></i>
                        My Profiles
                      </Link>
                    </li>
                  )
                )}
                
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)