import React, {useState} from "react";
import {
    Table,
} from "reactstrap";
import AddCreatorModal from "./AddCreatorModal";
import { MdAdd, MdAddCircle } from "react-icons/md"
import CreatorRow from "./CreatorRow";

const Creators = ({creators, setCreators}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const toggle = () =>{
        setModalOpen(!modalOpen);
    }
    // console.log("creators",creators);
    return (
        <div>
            <div className="campaign-title d-flex jc-space-between al-center mb-4">
              <h6 className="">Creator Roster</h6>
                <div className="btn btn-pink fw-bold" onClick={toggle}>
                    <MdAddCircle style={{marginTop: '-2px'}} /> Add Creator
                </div>
                <AddCreatorModal creators={creators} setCreators={setCreators} modalOpen={modalOpen} toggle={toggle} />
            </div>
            {creators && creators.length > 0 ? (
                <Table className="mb-5">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Zip</th>
                            <th>Country</th>
                            <th>Birthday</th>
                            <th>Handles</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {creators.map((e,i)=> {
                            return (
                                <CreatorRow i={i} e={e} creators={creators} setCreators={setCreators} />
                            );
                        })}
                    </tbody>
                </Table>
            ) : (
                <div className="hover:border-pink-600 hover:dark:border-gray-300 bg-transparent user-select-none" >
                    <div className="card-body dashed" style={{border:'1px dashed #f0f2f4 !important'}}>
                        <div className="d-flex flex-col jc-center al-center py-3 ">
                        <div className="bg-light text-center d-flex jc-center ai-center" style={{height:68, width:68, borderRadius:'100%', fontSize:40, color:'rgb(209, 213, 219)'}}>
                            <MdAdd />
                        </div>
                        <p className="text-center mt-3">Please add a creator to continue</p>
                        </div>
                    </div>
                </div>
            )}
          </div>
    );
}

export default Creators;