import { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { AvForm, AvField, } from "availity-reactstrap-validation";
import {Stepper, Step, StepLabel,} from "@material-ui/core";
import CreatorInfo from "./CreatorInfo";
import Platforms from '../SignUpSteps/Platforms';
import { LoginToken, } from "../../../pages/Utility/common";
import Profiles from "../SignUpSteps/Profiles";
import { first, last } from "lodash";

const AddCreatorModal = ({modalOpen, toggle, setCreators, creators, isLoggedIn, setErrorMessage, setControllingAccounts, setSuccessMessage}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [address, setAddress] = useState();
    const loginToken = LoginToken();
    const [gender, setGender] = useState("1");
    const [sexualOrientation, setSexualOrientation] = useState("1");
    const [birthday, setBirthday] = useState(new Date("1/1/1910"));
    const [postalCode, setPostalCode] = useState();
    const [city, setCity] = useState();
    const [contactPlatforms, setContactPlatforms] = useState([{Platform: 'TikTok', Handle:'example', SongRate: "$10.00", BrandRate:"$20.00" }]);
    const [country, setCountry] = useState(1);
    const [stateOther, setStateOther] = useState();
    const [state, setState] = useState(111);
    function getPlatformID(platformName) {
      const platform = Platforms.find(p => p.PlatformName === platformName);
      return platform ? platform.PlatformID : null;
    }
    const AddIndividualCreator = () => {
      contactPlatforms.forEach(contactPlatform => {
        const platformID = getPlatformID(contactPlatform.Platform);
        if (platformID !== null) {
          contactPlatform.PlatformID = platformID;
          delete contactPlatform.Platform;
        }
        const BrandRate = contactPlatform.BrandRate && contactPlatform.BrandRate[0] === "$" ? contactPlatform.BrandRate.substring(1) : contactPlatform.BrandRate;
        const SongRate = contactPlatform.SongRate && contactPlatform.SongRate[0] === "$" ? contactPlatform.SongRate.substring(1) : contactPlatform.SongRate;
        const Handle = contactPlatform.Handle && contactPlatform.Handle[0] === "@" ? contactPlatform.Handle.substring(1) : contactPlatform.Handle;

        contactPlatform.BrandRate = BrandRate;
        contactPlatform.SongRate = SongRate;
        contactPlatform.Handle = Handle;
      });
      fetch(
        `https://portalapi.genni.com/api/v1/creatorapi/link-creator-to-manager`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", "x-api-key": loginToken },
          body: JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            addressLine1: address,
            genderId: parseFloat(gender),
            sexualOrientationId: parseFloat(sexualOrientation),
            city:city,
            stateId:parseFloat(state),
            postalCode: postalCode,
            countryId:parseFloat(`${country ? country.toString() : '1'}`),
            birthday:birthday,
            contactPlatforms:contactPlatforms,
          })
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          if (!data.success) {
            setErrorMessage(data.message);
            setSuccessMessage("");
            toggle();
          } else {
            setErrorMessage("");
            setSuccessMessage(data.message);
            setControllingAccounts([]);
            toggle();
            reset();
          }

        })
        .catch((error) => {
          // console.log(error);
        });;
    }
    const reset = () => {
      setFirstName();
      setLastName();
      setAddress();
      setGender("1");
      setSexualOrientation("1");
      setPostalCode();
      setCity();
      setState();
      setContactPlatforms([{Platform: 'TikTok', Handle:'example', SongRate: "$10.00", BrandRate:"$20.00" }]);
    }
    const creatorObj = {
      firstName: firstName,
      lastName: lastName,
      addressLine1: address,
      genderId: gender,
      sexualOrientationId: sexualOrientation,
      city:city,
      stateId:state,
      postalCode: postalCode,
      countryId:`${country ? country.toString() : '1'}`,
      birthday:birthday,
      contactPlatforms:contactPlatforms
    }
    // console.log("creator obj",creatorObj);

    const disabled = !firstName || !lastName || !address || !city || !state || !postalCode || !country || birthday.getTime() === new Date("1/1/1910").getTime();
    return (
        <>
            
            <Modal isOpen={modalOpen} toggle={toggle}>
                <AvForm className="">
                <ModalHeader>
                  <Stepper activeStep={currentStep} alternativeLabel className="full-width mt-0 pt-0 pb-0 mb-0">
                    <Step key="Info">
                      <StepLabel>Info</StepLabel>
                    </Step>
                    <Step key="Handles">
                      <StepLabel>Handles</StepLabel>
                    </Step>
                  </Stepper>
                </ModalHeader>
                <ModalBody>
                  {currentStep === 0 ? (
                    <CreatorInfo 
                      firstName={firstName}
                      setFirstName={setFirstName}
                      lastName={lastName}
                      setLastName={setLastName}
                      address={address}
                      setAddress={setAddress}
                      gender={gender}
                      setGender={setGender}
                      sexualOrientation={sexualOrientation}
                      setSexualOrientation={setSexualOrientation}
                      city={city}
                      setCity={setCity}
                      state={state}
                      setState={setState}
                      stateOther={stateOther}
                      setStateOther={setStateOther}
                      postalCode={postalCode}
                      setPostalCode={setPostalCode}
                      country={country}
                      setCountry={setCountry}
                      birthday={birthday}
                      setBirthday={setBirthday}
                    />
                  ) : (
                    <Profiles 
                      profiles={contactPlatforms}
                      setProfiles={setContactPlatforms}
                    />
                  )}
                </ModalBody>
                <ModalFooter>
                    {currentStep === 0 ? (
                      <div className={`btn btn-pink fw-semi ${disabled && 'disabled border-light'}`} onClick={
                        ()=>{
                          if (!disabled) {
                            setCurrentStep(currentStep + 1)
                          }
                        }
                      }>Next</div>
                    ) : (
                      <>
                        <div className="d-flex full-width" style={{justifyContent:'space-between'}}>
                            <div className="btn btn-secondary text-white fw-semi float-left" onClick={()=>{setCurrentStep(currentStep - 1)}}>Back</div>
                            {!isLoggedIn ? (
                              <div className="btn btn-pink fw-semi" onClick={() => { toggle(); setCreators([...creators, creatorObj]); setCurrentStep(0); reset();}}>Submit</div>
                            ) : (
                              <div className="btn btn-pink fw-semi" onClick={() => {AddIndividualCreator();}}>Submit</div>
                            )}
                        </div>
                      </>
                    )}
                </ModalFooter>
                </AvForm>
            </Modal>
        </>
    );
}

export default AddCreatorModal;