import React, { useState, useEffect, useRef } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Row,
    Col,
  } from "reactstrap";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/build/index.css';

function range(start, thisYear) {
    const arr = [];
    for (let index = start; index < thisYear; index++) {
        arr.push(index);
    }
    return arr;
}

const Manager = ({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    company,
    setCompany,
    primaryPhone,
    setPrimaryPhone,
    allowText,
    setAllowText,
    paypal,
    setPayPal
}) => {
    
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };
    
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };
    

    const handlePayPalChange = (e) => {
        setPayPal(e.target.value);
    };

    const handleCompanyChange = (e) => {
        setCompany(e.target.value);
    };


    // console.log("primary phone",primaryPhone);

    return (
        <AvForm className="">
            <div className="campaigns">
                <Row>
                    <Col xs="6">
                        <div className="campaign">
                            <div className="campaign-title">
                                <h6 className="">First Name</h6>
                            </div>
                            <AvField
                                name="firstName"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                type="text"
                                className="inputField mb-3"
                                errorMessage="Invalid First Name"
                                validate={{
                                required: { value: true },
                                }}
                            />
                        </div>
                    </Col>
                    <Col xs="6">
                        <div className="campaign">
                        <div className="campaign-title">
                            <h6 className="">Last Name</h6>
                        </div>
                        <AvField
                            name="lastName"
                            value={lastName}
                            onChange={handleLastNameChange}
                            type="text"
                            className="inputField mb-3"
                            errorMessage="Invalid Last Name"
                            validate={{
                            required: { value: true },
                            }}
                        />
                        </div>
                    </Col>
                </Row>
                <div className="campaign">
                    <div className="campaign-title">
                        <h6 className="">PayPal Email</h6>
                    </div>
                    <AvField
                        name="paypal"
                        value={paypal}
                        onChange={handlePayPalChange}
                        type="email"
                        className="inputField mb-3"
                        errorMessage="Invalid PayPal"
                        validate={{
                            required: { value: true },
                        }}
                    />
                </div>
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">Company</h6>
                </div>
                <AvField
                  name="company"
                  value={company}
                  onChange={handleCompanyChange}
                  type="text"
                  className="inputField mb-3"
                  errorMessage="Invalid Company"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">Mobile Phone</h6>
                </div>
                {/* <AvField
                  name="primaryPhone"
                  value={primaryPhone}
                  onChange={handlePrimaryPhoneChange}
                  type="text"
                  className="inputField mb-3"
                  errorMessage="Invalid Primary"
                  validate={{
                    required: { value: true },
                  }}
                /> */}
                <PhoneInput
                  defaultCountry="us"
                  className="mb-3"
                  value={primaryPhone}
                  onChange={(phone) => setPrimaryPhone(phone)}
                />
              </div>

              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={allowText}
                  onClick={() => {
                    setAllowText(!allowText);
                  }}
                  id="customControlInline"
                />
                <label
                  className="form-check-label"
                  htmlFor="customControlInline"
                >
                  Check to receive text message notifications
                </label>
              </div>
            </div>
          </AvForm>
    );
}

export default Manager;