import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


const skeletonComponent = () => {
    return (<div className="dark-card">
      <div className="inner-card">
        <div className="card-text">
          <p>
          <SkeletonTheme color="#9c9c9c" highlightColor="#c9c9c9">
            <Skeleton height={15} width={Math.floor(Math.random() * 100) + 100} />
          </SkeletonTheme>
            
          </p>
        </div>
      </div>
    </div>);
};

export default skeletonComponent;