import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Preferences } from '@capacitor/preferences';
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Capacitor } from "@capacitor/core";
import { LoginToken, TokenAppenditure } from "../Utility/common";

const LandingPage = async () => {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [data, setData] = useState();
  const { value } = await Preferences.get({ key: 'LoginToken' });
  const loginToken = Capacitor.isNativePlatform() ? params.token : Cookies.get("LoginToken");  
  if (loginToken){
    window.location.href =`/dashboard${TokenAppenditure()}`;
    
  } else {
    if (Capacitor.isNativePlatform()) {
      window.location.href = "/welcome";
    } else {
      window.location.href = "/login";
    }

    
  }
  return (
    <React.Fragment>
      
    </React.Fragment>
  );
};;

export default LandingPage;
