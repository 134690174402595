import React, { Component, useState, useEffect, createRef } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  UncontrolledTooltip,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import S3FileUpload from "react-s3";
import { Capacitor } from "@capacitor/core";
import {welcomeCodeReturningCompetitors} from "../Emails/emails"
import queryString from "query-string";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { Auth } from "aws-amplify";
import Loader from "react-loader-spinner";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import badge from "../../assets/images/badge.svg";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import axios from "axios";
import { API, graphqlOperation } from "aws-amplify";
import {
  createPost as CreatePost,
  createReferral as CreateReferral,
  updatePromotion,
} from "../../graphql/mutations";
import './styles.scss';
import Badge from "reactstrap/lib/Badge";
import { sub } from "date-fns";
import { LoginToken } from "../Utility/common";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function isUrl(s) {
  var regexp = /^(ftp|http|https):\/\/[^ "]+$/;
  return regexp.test(s);
}

function SubmitPost({
  competitionId,
  modal,
  toggleModal,
  referral,
  postsArr,
  pathname,
  competitionName,
  organizer,
  promotion,
  platforms,
  myLocation
}) {
  
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [isCheckedGuidelines, setIsCheckedGuidelines] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [file, updateFile] = useState(null);
  const [error, setError] = useState();
  const [fileName, updateFileName] = useState("Upload Image");
  const [fileResult, updateFileResult] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [link, setLink] = useState("");
  const [linkError, setLinkError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  const [copied, setCopied] = useState(false);
  const postLinks = postsArr && postsArr.map((a) => a.link);
  const creatorsArr = postsArr && postsArr.map((a) => a.creator);
  let history = useHistory();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const loginToken = LoginToken();

  function handleImageChange(event) {
    const {
      target: { value, files },
    } = event;
    const fileForUpload = files[0];
    let reader = new FileReader();
    if (
      fileForUpload.type.includes("pdf") ||
      fileForUpload.type.includes("jpeg") ||
      fileForUpload.type.includes("png") ||
      fileForUpload.type.includes("MOV") ||
      fileForUpload.type.includes("MP4") ||
      fileForUpload.type.includes("video")
    ) {
      let url = reader.readAsDataURL(fileForUpload);
      reader.onloadend = function (e) {
        updateFileResult([reader.result]);
      }.bind(this);
      if (fileForUpload) {
        setError("");
        updateFileName(fileForUpload.name.split(".")[0]);
        updateFile(fileForUpload || value);
      } else {
        setError("Must be a valid png or jpg");
      }
    } else {
      setError("Invalid File");
    }
  }

  const isValidURL = () => {
    if (isUrl(link)) {
      const availablePlatforms = [];
      platforms.map(e => {
        if (e.toLowerCase().includes("tiktok")){
          availablePlatforms.push("tiktok");
        } else if (e.toLowerCase().includes("instagram")){
          availablePlatforms.push("instagram");
        } else if (e.toLowerCase().includes("youtube")){
          availablePlatforms.push("youtube");
        } else if (e.toLowerCase().includes("capcut")){
          availablePlatforms.push("capcut");
        }
      })
      
      if (availablePlatforms.some(value => link.includes(value))) {
        return true;
      } else {
        return false;
      }
      
    }
    return false;
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };
  const handleUsernameChange = (e) => {
    setUsername(e.target.value.toLowerCase());
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  function handleClick() {
    history.go(0);
  }
  const getPlatform = (url) => {
    if (url.toLowerCase().includes("tiktok.com")) {
      return "tiktok";
    } else if (url.toLowerCase().includes("youtube.com")) {
      return "youtube";
    } else if (url.toLowerCase().includes("capcut.com") || url.toLowerCase().includes("capcut.net")) {
      return "capcut";
    }
    return "instagram";
  };

  useEffect(() => {
    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/information`,
      { method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
       },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        // console.log("Data",data);
        setEmail(data.contactDetail.logInUsername);
        
      });
  }, []);

  const endSubmitting = async (vs) => {
    const createPostResult = await API.graphql(
      graphqlOperation(CreatePost, vs)
    );
    welcomeCodeReturningCompetitors(email, `/contestdetail?PostID=${params.PostID}`)
    setSuccess(true);
    setIsSubmitting(false);
  };

  const submitIG = (creator, platform) => {
    const variables = {
      input:{
        link: link,
        email: email,
        postId: competitionId,
        creator: creator,
        platform: platform,
        source: "genni",
        likes: 0,
      }
    };
    endSubmitting(variables);
    return "";
  };

  const handleIsChecked = () => {
    setIsChecked(!isChecked);
  };
  const handleIsCheckedGuidelines = () => {
    setIsCheckedGuidelines(!isCheckedGuidelines);
  };

  const submitTikTok = (creator, platform) => {
    const variables = {
      input:{
        link: link,
        country: myLocation,
        email: email,
        postId: competitionId,
        creator: creator,
        platform: platform,
        source: "genni",
        likes: 0,
      }
    };
    endSubmitting(variables);
    return "";
  };

  const submitYT = (creator, platform) => {
    const variables = {
      input: {

        country: myLocation,
        link: link,
        email: email,
        postId: competitionId,
        creator: creator,
        platform: platform,
        source: "genni",
        likes: 0,
      }
    };
    endSubmitting(variables);
    return "";
  };

  async function createPost(variables) {
    const createPostResult = await API.graphql(
      graphqlOperation(CreatePost, variables)
    );
    const newId = get(createPostResult, ["data", "createPost", "id"], null);
    await setReferralLink(newId);
    if (newId) {
      sendSubmitEmail(newId);
    }
  }

  async function createReferral() {
    const variables = {
      input: {
        referringLink: link,
        postId: referral,
      },
    };
    await API.graphql(graphqlOperation(CreateReferral, variables));
  }

  async function sendSubmitEmail(newId) {
    // console.log("hi");
  }
  const thePlatform = get(platforms, [0], "");

  const submitCapCut = (creator, platform, lnk) => {
    const variables = {
      input: {
        link: lnk,
        email: email,
        postId: competitionId,
        creator: creator,
        platform: platform,
        country: myLocation,
        likes: 0,
      },
    };
    endSubmitting(variables);
    return "";
  };
  const submitPost = async () => {
    const instancesOfCreator = creatorsArr.filter(function (value) {
      return value === username;
    });
    if (
      validateEmail(email) &&
    (isUrl(link)) &&
    (link.includes("tiktok.com") || link.includes("instagram.com") || link.includes("capcut.com") || link.includes("capcut.net") || link.includes("youtube.com")) &&
    !postLinks.includes(link) &&
    instancesOfCreator.length < 7
    ) {
      setIsSubmitting(true);
      setEmailError("");
      setLinkError("");
      const platform = getPlatform(link);
      
      if (platform === "tiktok") {
        const creator = username;
        await submitTikTok(creator, platform);
      } else if (platform === "youtube") {
        if (platforms.includes("YouTube Short")) {
          const newUrl = link.includes("?") ? link + "&__a=1" : link + "?__a=1";
          await submitYT(username, platform);
        } else {
          setIsSubmitting(false);
          setLinkError("This contest does not allow YouTube submissions");
        }
      }else if (platform === "capcut") {
        const creator = username;
        await submitYT(username, platform);
        // try {
        //   const new_file = new File([file], makeid(32), {type:file.type});
        //   S3FileUpload.uploadFile(new_file, s3Config)
        //   .then(async (d) => {
        //     setLink(d.location);
        //     await submitCapCut(username, platform, d.location);
        //   })
        //   .catch((err) => {
        //       // console.log(err);
        //   });
        //   } catch (err) {
        //       // console.log("error: ", err);
        //   }
      }  else {
        if (platforms.includes("Instagram Reel")) {
          const newUrl = link.includes("?") ? link + "&__a=1" : link + "?__a=1";
          await submitIG(username, platform);
        } else {
          setIsSubmitting(false);
          setLinkError("This contest does not allow Instagram submissions");
        }
      }
    } else {
      if (!validateEmail(email)) {
        setEmailError("Invalid Email Address");
      } else {
        setEmailError("");
      }
      if (!isUrl(link)) {
        setLinkError("Invalid Link (Must be http or https)");
      } else {
        if (
          !(
            link.includes("tiktok.com") ||
            link.includes("instagram.com") ||
            link.includes("youtube.com") ||
            link.includes("capcut.com") ||
            link.includes("capcut.net")
          )
        ) {
          setLinkError("Must be either a TikTok, YouTube, CapCut, or Instagram post");
        } else if (postLinks.includes(link)) {
          setLinkError("Post has already been submitted");
        } else if (instancesOfCreator.length >= 7) {
          setLinkError("Maximum 7 entries");
        } else {
          setLinkError("");
        }
      }
    }
  };
  const returnHandleString = () => {
    const numberOfPlatforms = platforms.length;

    if (numberOfPlatforms === 1) {
      return `${platforms[0]} Username:`;
    } else if (numberOfPlatforms === 2) {
      return `${platforms[0]} or ${platforms[1]} Username:`;
    } else if (numberOfPlatforms === 3) {
      return `${platforms[0]}, ${platforms[1]}, or ${platforms[2]} Username:`;
    } else {
      return "TikTok Username";
    }
  };
  return (
    <React.Fragment>
      <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true}>
        <ModalHeader
          className="bg-light p-4"
          toggle={success ? handleClick : toggleModal}
        >
          <span className="title">{success ? "" : "Submit Your Post"}</span>
        </ModalHeader>

        {success ? (
          <>
            <ModalBody
              className="p-2"
              style={{
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={badge}
                alt="badge"
                style={{ width: 80, height: "auto" }}
              />
              <h3 className="mt-2">Congratulations!</h3>
              <p className="mt-0 text-center">
                Your post is now in review.
                <br />
                If you win, we will email you with instructions on how to
                collect your prize.
              </p>
            </ModalBody>
          </>
        ) : (
          <ModalBody className="p-4">
            <Form>
              <FormGroup>
                <InputGroup
                  className="mb-3"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <AvForm className="login-form">
                    <AvGroup className="form-group position-relative">
                      <Label
                        className="mt-3"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {returnHandleString()}
                      </Label>
                      <p className="mdi mx-1 icons" style={{ marginTop: 0 }}>
                        @
                      </p>

                      <AvField
                        type="text"
                        className="form-control px-4"
                        name="username"
                        id="username"
                        required
                        placeholder="example"
                        value={username}
                        onChange={handleUsernameChange}
                        errorMessage=""
                      />
                    </AvGroup>
                    {/* {thePlatform.includes("CapCut") ? (
                      <>
                        <Label for="draft">Draft Of Template:</Label>
                        {file ? (
                          <>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                              {file.name}
                              <div className="ml-2 mb-2">
                                <div
                                  className="btn btn-pink full-width float-right "
                                  onClick={() => {
                                    updateFile(null);
                                    updateFileResult(null);
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            </div>
                            {isSubmitting && (
                              <span className="text-muted">
                                Please allow several minutes to upload.
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            {error && <p className="text-danger">{error}</p>}
                            <label
                              htmlFor="file-upload"
                              className="btn btn-pink btn-md mb-3 mt-0"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                maxWidth: '150px',
                                fontSize: "16px",
                              }}
                            >
                              <i className="mdi mdi-cloud-upload mr-2"></i>Upload File
                            </label>
                            <input
                              type="file"
                              id="file-upload"
                              accept="video/*"
                              onChange={handleImageChange}
                              style={{ width: "140px", display: "none" }}
                            />
                          </>
                        )
                        }
                      </>
                    ) : ( */}
                      <AvGroup className="form-group position-relative">
                      <Label
                        className="mt-3"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        Link To The Post:
                        <a
                          target="_blank"
                          href="https://preffy.com/link-to-post"
                        >
                          <span
                            className="text-secondary"
                            style={{ fontSize: 13 }}
                          >
                            Learn How{" "}
                            <i className="mdi mdi-help-circle text-secondary"></i>
                          </span>
                        </a>
                      </Label>
                      <i className="mdi mdi-link mx-1 icons"></i>
                      <AvField
                        type="text"
                        className="form-control px-4"
                        name="link"
                        id="link"
                        placeholder={`https://www.${thePlatform.toLowerCase()}.com/@preffydotcom/video/6881971490951154949`}
                        required
                        value={link}
                        onChange={handleLinkChange}
                        errorMessage=""
                        validate={{
                          required: {
                            value: true,
                            errorMessage: linkError,
                          },
                          pattern: {value: /^(ftp|http|https):\/\/[^ "]+$/},

                        }}
                      />

                      {linkError.length > 0 && (
                        <p
                          className="text-danger text-sm"
                          style={{ marginTop: -10 }}
                        >
                          {linkError}
                        </p>
                      )}
                    </AvGroup>
                    {/* )} */}
                    
                  </AvForm>
                  <FormGroup>
                    <div className="custom-control custom-checkbox">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        checked={isChecked}
                        onChange={handleIsChecked}
                      />
                      <Label
                        className="custom-control-label text-muted"
                        style={{ marginLeft: 8 }}
                        for="customCheck1"
                      >
                        I Agree To The{" "}
                        <Link
                          to="/terms"
                          className="text-muted text-decoration-underline"
                        >
                          Terms Of Services
                        </Link>
                      </Label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        checked={isCheckedGuidelines}
                        onChange={handleIsCheckedGuidelines}
                      />
                      <Label
                        className="custom-control-label text-muted"
                        style={{ marginLeft: 8 }}
                        for="customCheck1"
                      >
                        I Have Read and Followed The Contest's Guidelines
                      </Label>
                    </div>
                  </FormGroup>
                </InputGroup>
              </FormGroup>
            </Form>
          </ModalBody>
        )}

        <ModalFooter>
          {success ? (
            <Button color="secondary" onClick={handleClick}>
              Done
            </Button>
          ) : (
            <Button
              color="pink"
              onClick={submitPost}
              disabled={!isChecked || !username || (!isValidURL())}
            >
              {isSubmitting ? (
                <Loader type="Bars" color="#fff" height={22} width={55} />
              ) : (
                "Submit"
              )}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default withRouter(SubmitPost);
