import React, { useEffect, useState } from "react";
import { Progress } from "reactstrap";
import { get } from "lodash";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import Loader from "react-loader-spinner";
import sos from "../../assets/images/sos.jpg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import MusicalNotes from "../../assets/images/ion-ios-musical-notes.svg";
import ReactPlayer from "react-player/soundcloud";
import queryString from "query-string";
import ReactPlayerFile from "react-player/file";
import { Capacitor } from "@capacitor/core";
import useGeoLocation from "react-ipgeolocation";
import Cookies from "js-cookie";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import { LoginToken } from "../Utility/common";

const Contests = (props) => {
    const {setCampaignType, campaignType, competitions, exclusiveCompetitions} = props;
    const [isPlaying, setIsPlaying] = useState(0);
    const location = useLocation();
    const [myLocation, setMyLocation] = useState(null);
    const geoLocation = useGeoLocation();

    useEffect(() => {
      const fetchLocation = () => {
        if (geoLocation.country !== undefined) {
          // If the country is defined, set it as the location and stop polling
          setMyLocation(geoLocation);
        } else {
          // If the country is still undefined, wait and then fetch again
          setTimeout(fetchLocation, 1000); // Adjust the timeout duration as needed
        }
      };
  
      if (myLocation === null) {
        // If location is null, start the initial fetch
        fetchLocation();
      }
    }, [geoLocation.country, myLocation]);
    let params = queryString.parse(location.search);
    const loginToken = LoginToken();
    const skeletonData = ["", "", ""];
    const [filter, setFilter] = useState("");
    const returnFormattedDate = (d) => {
      const D = new Date(d);
      return `${D.getMonth() + 1}/${D.getDate()}/${D.getFullYear()}`;
    };

    const totalPrizes = (prizes) => {
      if (prizes) {
        let sum = 0;
        // let sum = prizes[0].includes("4th") ? 300 : 0;
        prizes.forEach((element, i) => {
          let val = element.split("$")[1];
          let label = element.split("$")[0];
          if (label.includes("-") && !label.includes("Followers")) {
            const rangeLow = element.split("-")[0];
            const rangeLowVal = rangeLow.substring(0, rangeLow.length - 2);
            const rangeHigh = element.split("-")[1].split(" Place")[0];
            const rangeHighVal = rangeHigh.substring(0, rangeHigh.length - 2);
            const multiplier = parseInt(rangeHighVal) - parseInt(rangeLowVal) + 1;
            val = val * multiplier;
          } else if (label.includes("Top")) {
            const amountOfWinners = parseInt(label.split(" ")[1]);
            val = amountOfWinners * val;
          }
  
          sum += parseFloat(val);
        });
        return "$" + sum;
      }
    };

    function reactPlayer(audio, image, key) {
      if (audio.includes("soundcloud.com")) {
        return (
          <>
            <ReactPlayer
              url={audio}
              playing={isPlaying === key}
              style={{ display: "none" }}
            />
            <button
              className="play-box"
              style={{ margin:'auto',backgroundImage: "url('" + image + "')" }}
              onClick={() => {
                if (isPlaying === key) {
                  setIsPlaying(0);
                } else {
                  setIsPlaying(key);
                }
              }}
            >
              <div className="overlay">
                <div className="play-button">
                  {isPlaying === key ? (
                    <i
                      className="mdi mdi-pause text-white"
                      style={{ fontSize: 40 }}
                    ></i>
                  ) : (
                    <svg
                      viewBox="0 0 26 26"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "100%", height: "100%", color: "white" }}
                    >
                      <title>Play</title>
                      <path
                        d="M7.712 22.04a.732.732 0 0 1-.806.007.767.767 0 0 1-.406-.703V4.656c0-.31.135-.544.406-.703.271-.16.54-.157.806.006l14.458 8.402c.266.163.4.4.4.709 0 .31-.134.546-.4.71L7.712 22.04z"
                        fill="currentColor"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  )}
                </div>
              </div>
            </button>
          </>
        );
      } else if (audio.includes("tiktok.com") || audio.includes("instagram.com") || audio.includes("youtube.com")) {
        return (
          <>
            <button
              className="play-box"
              style={{ margin:'auto',backgroundImage: "url('" + image + "')" }}
              onClick={() => {
                window.open(audio);
              }}
            >
              <div className="overlay">
                <div className="play-button">
                  {isPlaying ? (
                    <i
                      className="mdi mdi-pause text-white"
                      style={{ fontSize: 40 }}
                    ></i>
                  ) : (
                    <svg
                      viewBox="0 0 26 26"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "100%", height: "100%", color: "white" }}
                    >
                      <title>Play</title>
                      <path
                        d="M7.712 22.04a.732.732 0 0 1-.806.007.767.767 0 0 1-.406-.703V4.656c0-.31.135-.544.406-.703.271-.16.54-.157.806.006l14.458 8.332c.266.163.4.4.4.709 0 .31-.134.546-.4.71L7.712 22.04z"
                        fill="currentColor"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  )}
                </div>
              </div>
            </button>
          </>
        );
      } else {
        return (
          <>
            <ReactPlayerFile
              url={audio}
              playing={isPlaying === key}
              style={{ display: "none" }}
            />
            <button
              className="play-box"
              style={{ margin:'auto', backgroundImage: "url('" + image + "')" }}
              onClick={() => {
                if (isPlaying === key) {
                  setIsPlaying(0);
                } else {
                  setIsPlaying(key);
                }
              }}
            >
              <div className="overlay">
                <div className="play-button">
                  {isPlaying === key ? (
                    <i
                      className="mdi mdi-pause text-white"
                      style={{ fontSize: 33 }}
                    ></i>
                  ) : (
                    <svg
                      viewBox="0 0 26 26"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "100%", height: "100%", color: "white" }}
                    >
                      <title>Play</title>
                      <path
                        d="M7.712 22.04a.732.732 0 0 1-.806.007.767.767 0 0 1-.406-.703V4.656c0-.31.135-.544.406-.703.271-.16.54-.157.806.006l14.458 8.332c.266.163.4.4.4.709 0 .31-.134.546-.4.71L7.712 22.04z"
                        fill="currentColor"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  )}
                </div>
              </div>
            </button>
          </>
        );
      }
    }

    const returnSkeletonComps = (i) => {
      const oneSkeleton = (
        <Col lg="4" md="6" xs="12" className="mb-3">
          <div
            className="job-box rounded shadow position-relative overflow-hidden bg-white"
            style={{ minHeight: 298 }}
          >
            <div className="position-relative p-4">
              <div
                className="top-part"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Skeleton
                  widthRandomness={0}
                  width="80px"
                  height="80px"
                  baseColor="#e6ebf2"
                  highlightColor="#f0f4f7"
                />
                <br />
                <Skeleton
                  widthRandomness={0}
                  width="120px"
                  height="20px"
                  baseColor="#e6ebf2"
                  highlightColor="#f0f4f7"
                />
                <Skeleton
                  widthRandomness={0}
                  width="150px"
                  height="20px"
                  baseColor="#e6ebf2"
                  highlightColor="#f0f4f7"
                />
              </div>
            </div>
          </div>
        </Col>
      );
      return (
        <>
          {oneSkeleton}
          {oneSkeleton}
          {oneSkeleton}
        </>
      );
    };

    const myLocationCountry = get(myLocation, ['country'], '');

    return (
      <React.Fragment>
        
        <Row className="">
          {competitions && competitions.length > 0 ? (
            <>
              {competitions.map((e, key) => {
                const countries = get(e, "countries", false);
                if ((!countries || countries.length === 0) || countries.includes(myLocationCountry) || myLocationCountry === ''){
                  if (filter !== "YouTube" || (e.platforms && e.platforms[0] === "YouTube Short")) {
                    return (
                      <Col lg="4" md="6" xs="12" className="pt-0 mb-3 mt-0">
                        <div
                          className="job-box rounded shadow position-relative overflow-hidden bg-white"
                          style={{ cursor: "auto", display:'flex',flexDirection:'column', paddingTop:28, }}
                        >
                          {e.platforms && e.platforms[0] === "YouTube Short" && (
                            <div className="ribbon ribbon-top-right ">
                              <span>YT Shorts</span>
                            </div>
                          )}
                          
                          {reactPlayer(e.audio, e.image, key + 1)}
                          <div className="position-relative p-4 pt-0">
                            <div
                              className="top-part"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            > 
                              <div className="text-center mt-3" >
                                <h5 className="mb-0 song-name madeGentle">
                                  <Link
                                    to={`/contestdetail?PostID=${e.id}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`}
                                    className="company-name" style={{color:'#162638'}}
                                  >
                                    {e.name}
                                  </Link>
                                </h5>
                                <p className="text-muted songSubMessage">
                                  <Link
                                    to={`/contestdetail?PostID=${e.id}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`}
                                    className="video-play-icon text-muted"
                                  >
                                    {e.organizer}
                                  </Link>
                                </p>
                              </div>
                            </div>
                            <ul className="job-facts list-unstyled flex-column" style={{display:'flex',flexDirection:'column'}}>
                              <li className="list-inline-item " style={{fontSize:15}}>
                                <i className="mdi mdi-trophy-variant text-pink mr-1"></i>{" "}
                                <span className=" ">Total Prizes:</span>{" "}
                                <span className="text-muted">{totalPrizes(e.prizes)}</span>
                              </li>
    
                              <li className="list-inline-item " style={{fontSize:15}}>
                                <i className="mdi mdi-link text-pink mr-1"></i>{" "}
                                <span className=" ">Platform(s):</span>{" "}
                                <span className="text-muted">{e.platforms ? e.platforms.map((a,i)=>{return i === e.platforms.length - 1 ? a : `${a}, `}) : "TikTok"}</span>
                              </li>
                              
                              <li className="list-inline-item " style={{fontSize:15}}>
                                <i className="mdi mdi-clock text-pink mr-1"></i>{" "}
                                <>
                                  <span className="">End Date:</span>{" "}
                                  <span className="text-muted">{returnFormattedDate(e.endDate)}</span>
                                </>
                              </li>
                            </ul>
                            <div
                              className="post-meta mt-3"
                              style={{ display: "flex", width: "100%" }}
                            >
                              <Link
                                to={`/contestdetail?PostID=${e.id}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`}
                                className="waves-effect btn btn-light gradient-light btn-md float-left full-width "
                                style={{width:'100%'}}
                              >
                                View Contest{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                    </Col>
                    );
                  }
                  
                }
                
              })}
            </>
          ) : (
            <>
              {returnSkeletonComps()}
            </>
          )}
        </Row>
      </React.Fragment>
    );
    };

export default Contests;
