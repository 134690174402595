/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompetition = /* GraphQL */ `
  query GetCompetition($id: ID!) {
    getCompetition(id: $id) {
      id
      image
      name
      organizer
      prize
      audio
      isActive
      endDate
      lastUpdated
      creativeDirection
      prizes
      tiktokSound
      instagramSound
      tiktok
      competitionType
      instagram
      spotify
      platforms
      posts (limit: 500000) {
        items {
          id
          link
          email
          creator
          platform
          likes
          postId
          createdAt
          updatedAt
          _deleted
          creativityScore
          referrals {
            items {
              referringLink
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompetitions = /* GraphQL */ `
  query ListCompetitions(
    $filter: ModelCompetitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompetitions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        _deleted
        _version
        name
        organizer
        image
        startDate
        endDate
        invoiceAmount
        isActive
        prizes
        audio
        prize
        hasEnded
        countries
        continents
        platforms
        createdAt
        posts(limit: 1000000) {
          items {
            id
            link
            platform
            _deleted
            creator
            _version
          }
        }
        promotions {
          items {
            isActive
            Amount
          }
        }
      }
      nextToken
    }
  }
`;

export const listAllCompetitions = /* GraphQL */ `
  query listAllCompetitions($limit: Int) {
    listCompetitions(limit: $limit) {
      items {
        id
        _deleted
        _version
        name
        organizerEmail
        organizer
        image
        startDate
        endDate
        lastUpdated
        isActive
        prizes
        audio
        prize
        hasEnded
        campaignID
        invoiceAmount
        createdAt
        isConfirmed
        posts(limit: 1000000) {
          items {
            id
            link
            platform
            _deleted
            creator
            email
            _version
            createdAt

          }
        }
      }
    }
  }
`;

export const listAllWinners = /* GraphQL */ `
  query listWinners {
    listWinnerss(limit: 1000000) {
      items {
        id
        email
        paypal
        isUnsubscribed
        balance
        _deleted
        _version
      }
    }
  }
`;

export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      link
      email
      postId
      competition {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const listPayments = /* GraphQL */ `
  query listPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        _deleted
        _version
        createdAt
        CompetitionID
        ContactEmail
        ContactID
        VendorID
        PaymentEmail
        Amount
        Status
        updatedAt
      }
      nextToken
    }
  }
`;

export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        link
        email
        creator
        likes
        platform
        postId
        competition {
          id
          name
          createdAt
          competitionType
          updatedAt
          posts(limit: 1000000) {
            items {
              id
              creator
              email
              platform
              likes
            }
          }
          hasEnded
          _deleted
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getWinners = /* GraphQL */ `
  query GetWinners($id: ID!) {
    getWinners(id: $id) {
      id
      email
      balance
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;

export const listWinnerss = /* GraphQL */ `
  query ListWinnerss(
    $filter: ModelWinnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWinnerss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        paypal
        isBanned
        balance
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listAllPromotions = /* GraphQL */ `
  query listAllPromotions($limit: Int) {
    listPromotions(limit: $limit) {
      items {
        id
        _deleted
        _version
        Name
        startDate
        endDate
        isActive
        Amount
        creatorEmails
        RemainingBalance
        CompetitionID
      }
    }
  }
`;

export const listPrizess = /* GraphQL */ `
  query ListPrizess(
    $filter: ModelPrizesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrizess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Key
        Value
      }
      nextToken
      startedAt
    }
  }
`;