import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useLocation, Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Capacitor } from "@capacitor/core";
import { LoginToken, NoResults, CommonLoader } from "./common";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
  } from "@material-ui/core";
import campaignImg from "../../assets/images/campaigns3.svg";
import contestImg from "../../assets/images/contests2.svg";
import 'react-loading-skeleton/dist/skeleton.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import moment from "moment";
import "moment-timezone";

const PaymentsTable = (props) => {
  const { data, currentPage, setCurrentPage, totalPages} = props;
  const location = useLocation();
  const loginToken = LoginToken();
  const history = useHistory();

  const columns = [
    {
      id: 1,
      label: "Competition ID",
    },
    {
        id: 2,
        label: "PayPal Email",
    },
    {
      id: 3,
      label: "Amount",
    },
    {
      id: 4,
      label: "Last Updated",
    },
    {
      id: 4,
      label: "Status",
    },
    
  ];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const Tabl = () => {
    return (
        <>
        <Paper className=" border p-0 mb-5 pt-0 mt-4" style={{ width: "100%", borderRadius:6 }}>
            <TableContainer>
                <Table aria-label="sticky table">
                <TableHead>
                    <TableRow>
                    {columns.map((column) => {
                        return (
                        <TableCell key={column.id}>
                            <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                            >
                            {column.label}
                            </div>
                        </TableCell>
                        );
                    })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ).map((row, i) => {
                        return (
                        <TableRow
                            hover
                            role="checkbox"
                            style={{ cursor: "pointer" }}
                            onClick={() =>  history.push(`/contestdetail?PostID=${row.CompetitionID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`)}
                            tabIndex={-1}
                            key={i}
                        >
                            <TableCell key={i} className="minTableCell">
                                <div className="d-flex al-center">
                                    {row.CompetitionID}
                                </div>
                            </TableCell>
                            
                            <TableCell key={i} >
                                {row.PaymentEmail}
                            </TableCell>

                            <TableCell key={i} >
                                {row.Amount}
                            </TableCell>
                            <TableCell key={i} >
                                {new Date(row.updatedAt).toDateString()}
                            </TableCell>

                            <TableCell key={i} >
                                {row.Status}
                            </TableCell>
                        </TableRow>
                        );
                    })}
                </TableBody>
                
                </Table>
                
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            
        </Paper>
        
        </>
    );
  }
  return (
    <React.Fragment>
        {data ? (
            <>
                {data.length > 0 ? (
                    <Tabl />
                ) : (
                    <NoResults className="mt-4" />
                )}
            </>
        ) : (<><CommonLoader /></>)}
        
      
      
      
    </React.Fragment>
  );
};;


export default PaymentsTable;
