import React, {useEffect, useState} from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  BreadcrumbItem,
} from "reactstrap";
import MusicalNotes from "../../assets/images/ion-ios-musical-notes.svg";
import moment from "moment";
import "moment-timezone";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Cookies from "js-cookie";
import { Storage, API, graphqlOperation } from "aws-amplify";
import ReactPlayer from "react-player/soundcloud";
import ReactPlayerFile from "react-player/file";
import { listAllCompetitions, getCompetition, listCompetitions } from "../../graphql/queries";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Tablelist from "../Utility/tablelist";

const Campaigns = () => {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [isPlaying, setIsPlaying] = useState(0);
  const [campaignType, setCampaignType] = useState("Open");
  const skeletonData = ["", "", ""];
  const [competitions, setCompetitions] = useState([]);
  const fetchCompetitions = async () => {
    let competitions = [];
    let nextToken = null;
  
    do {
      const apiData = await API.graphql({
        query: listCompetitions,
        variables: {
          limit: 100000,
          nextToken: nextToken,
        },
      });
  
      const items = apiData.data.listCompetitions.items.filter((e) => 
        e._deleted !== true && e.isActive === true && !e.hasEnded
      );
  
      competitions = competitions.concat(items);
      nextToken = apiData.data.listCompetitions.nextToken;
  
    } while (nextToken);
  
    setCompetitions(competitions);
  };
  const [postStatusID, setPostStatusID] = useState("3");
  const calcTotalPrizes = (prizes) => {
    if (prizes) {
      let sum = 0;
      // let sum = prizes[0].includes("4th") ? 300 : 0;
      prizes.forEach((element, i) => {
        let val = element.split("$")[1];
        let label = element.split("$")[0];
        if (label.includes("-") && !label.includes("Followers")) {
          const rangeLow = element.split("-")[0];
          const rangeLowVal = rangeLow.substring(0, rangeLow.length - 2);
          const rangeHigh = element.split("-")[1].split(" Place")[0];
          const rangeHighVal = rangeHigh.substring(0, rangeHigh.length - 2);
          const multiplier = parseInt(rangeHighVal) - parseInt(rangeLowVal) + 1;
          val = val * multiplier;
        } else if (label.includes("Top")) {
          const amountOfWinners = parseInt(label.split(" ")[1]);
          val = amountOfWinners * val;
        }

        sum += parseFloat(val);
      });
      return "$" + sum;
    }
  };
  const [data, setData] = useState();
  const [campaigns, setCampaigns] = useState();
  

  function reactPlayer(audio, image, key) {
    if (audio.includes("soundcloud.com")) {
      return (
        <>
          <ReactPlayer
            url={audio}
            playing={isPlaying === key}
            style={{ display: "none" }}
          />
          <button
            className="play-box mr-2"
            style={{ backgroundImage: "url('" + image + "')" }}
            onClick={() => {
              if (isPlaying === key) {
                setIsPlaying(0);
              } else {
                setIsPlaying(key);
              }
            }}
          >
            <div className="overlay">
              <div className="play-button">
                {isPlaying === key ? (
                  <i
                    className="mdi mdi-pause text-white"
                    style={{ fontSize: 40 }}
                  ></i>
                ) : (
                  <svg
                    viewBox="0 0 26 26"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: "100%", height: "100%", color: "white" }}
                  >
                    <title>Play</title>
                    <path
                      d="M7.712 22.04a.732.732 0 0 1-.806.007.767.767 0 0 1-.406-.703V4.656c0-.31.135-.544.406-.703.271-.16.54-.157.806.006l14.458 8.402c.266.163.4.4.4.709 0 .31-.134.546-.4.71L7.712 22.04z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                )}
              </div>
            </div>
          </button>
        </>
      );
    } else if (audio.includes("tiktok.com")) {
      return (
        <>
          <button
            className="play-box mr-2"
            style={{ backgroundImage: "url('" + image + "')" }}
            onClick={() => {
              window.open(audio);
            }}
          >
            <div className="overlay">
              <div className="play-button">
                {isPlaying ? (
                  <i
                    className="mdi mdi-pause text-white"
                    style={{ fontSize: 40 }}
                  ></i>
                ) : (
                  <svg
                    viewBox="0 0 26 26"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: "100%", height: "100%", color: "white" }}
                  >
                    <title>Play</title>
                    <path
                      d="M7.712 22.04a.732.732 0 0 1-.806.007.767.767 0 0 1-.406-.703V4.656c0-.31.135-.544.406-.703.271-.16.54-.157.806.006l14.458 8.332c.266.163.4.4.4.709 0 .31-.134.546-.4.71L7.712 22.04z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                )}
              </div>
            </div>
          </button>
        </>
      );
    } else {
      return (
        <>
          <ReactPlayerFile
            url={audio}
            playing={isPlaying === key}
            style={{ display: "none" }}
          />
          <button
            className="play-box mr-2"
            style={{ backgroundImage: "url('" + image + "')" }}
            onClick={() => {
              if (isPlaying === key) {
                setIsPlaying(0);
              } else {
                setIsPlaying(key);
              }
            }}
          >
            <div className="overlay">
              <div className="play-button">
                {isPlaying === key ? (
                  <i
                    className="mdi mdi-pause text-white"
                    style={{ fontSize: 33 }}
                  ></i>
                ) : (
                  <svg
                    viewBox="0 0 26 26"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: "100%", height: "100%", color: "white" }}
                  >
                    <title>Play</title>
                    <path
                      d="M7.712 22.04a.732.732 0 0 1-.806.007.767.767 0 0 1-.406-.703V4.656c0-.31.135-.544.406-.703.271-.16.54-.157.806.006l14.458 8.332c.266.163.4.4.4.709 0 .31-.134.546-.4.71L7.712 22.04z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                )}
              </div>
            </div>
          </button>
        </>
      );
    }
  }

  const returnFormattedDate = (d) => {
    const D = new Date(d);
    return `${D.getMonth() + 1}/${D.getDate()}/${D.getFullYear()}`;
  };
  useEffect(() => {
    fetchCompetitions();
  }, [postStatusID]);
  return (
    <React.Fragment>
      <div
        className="page-content "
        style={{ height: "1500px", background: "#f8f9fa" }}
      ></div>
    </React.Fragment>
  );
};

export default Campaigns;