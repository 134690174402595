import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useLocation, Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Capacitor } from "@capacitor/core";
import { LoginToken, NoResults, CommonLoader } from "./common";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
  } from "@material-ui/core";
import campaignImg from "../../assets/images/campaigns3.svg";
import contestImg from "../../assets/images/contests2.svg";
import 'react-loading-skeleton/dist/skeleton.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import moment from "moment";
import "moment-timezone";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";

const PaymentsTable = (props) => {
  const { data, currentPage, setCurrentPage, totalPages} = props;
  const location = useLocation();
  const loginToken = LoginToken();
  const history = useHistory();
  
  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00-0:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    const momentT = moment(t);
    return momentT.tz(moment.tz.guess()).format("M/D/YYYY") === "Invalid date"
      ? ""
      : momentT.tz(moment.tz.guess()).format("MMM D hh:mmA z");
  };

  const columns = [
    {
      id: 1,
      label: "Title",
    },
    {
        id: 2,
        label: "Platform",
    },
    {
      id: 2,
      label: "Handle",
    },
    {
        id: 3,
        label: "Status",
    },
    {
        id: 4,
        label: "Date",
    },
    {
        id: 5,
        label: "Rate",
    },
  ];
  const Tabl = () => {
    return (
        <>
        <Paper className=" border p-0 mb-5 pt-0 mt-4" style={{ width: "100%", borderRadius:6 }}>
            <TableContainer>
                <Table aria-label="sticky table">
                <TableHead>
                    <TableRow>
                    {columns.map((column) => {
                        return (
                        <TableCell key={column.id}>
                            <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                            >
                            {column.label}
                            </div>
                        </TableCell>
                        );
                    })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, i) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            style={{ cursor: "pointer" }}
                            onClick={() =>  history.push(`/detail?PostID=${row.PostID}&UID=${row.UID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`)}
                            tabIndex={-1}
                            key={i}
                          >
                              <TableCell key={i} className="minTableCell">
                                  <div className="d-flex al-center">
                                      {row.CampaignTitle}
                                  </div>
                              </TableCell>
                              {
                                row.Row_CampaignDetails.map((a,n)=>{
                                  return (
                                    <TableCell key={i} >
                                        {a.FieldName.includes("Date") ? calcDateValue(a.FieldValue) : a.FieldValue}
                                    </TableCell>
                                  )
                                })
                              }
                              <TableCell key={i} >
                                  {row.PostPayment}
                              </TableCell>
                          </TableRow>
                        );
                    })}
                </TableBody>
                
                </Table>
                
            </TableContainer>
            {currentPage && totalPages > 1 && (
                    <div
                      className="px-4"
                      style={{
                        textAlign: "right",
                        marginTop: 16,
                        paddingBottom: 0,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <p className="px-4" style={{ fontSize: 14 }}>
                        Page {currentPage} of {totalPages}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          fontSize: 27,
                          marginTop: -10,
                          width: 72,
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className={`pagination-btn ${
                            currentPage > 1
                              ? ""
                              : "disabled-pagination-btn text-muted"
                          }`}
                          onClick={() => {
                            if (currentPage > 1) {
                              setCurrentPage(parseInt(currentPage) - 1);
                            }
                          }}
                        >
                          {" "}
                          <MdOutlineChevronLeft />
                        </div>
                        <div
                          className={`pagination-btn ${
                            currentPage < totalPages
                              ? ""
                              : "disabled-pagination-btn text-muted"
                          }`}
                          onClick={() => {
                            if (currentPage < totalPages) {
                              setCurrentPage(parseInt(currentPage) + 1);
                              
                            }
                          }}
                        >
                          {" "}
                          <MdOutlineChevronRight />
                        </div>
                      </div>
                    </div>

                )}
        </Paper>
        
        </>
    );
  }
  return (
    <React.Fragment>
        {data ? (
            <>
                {data.length > 0 ? (
                    <Tabl />
                ) : (
                    <NoResults  className="mt-4" />
                )}
            </>
        ) : (<><CommonLoader /></>)}
        
      
      
      
    </React.Fragment>
  );
};;


export default PaymentsTable;
