import Loader from "react-loader-spinner";
import {Capacitor} from "@capacitor/core";
import { useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import {
    BreadcrumbItem,
  } from "reactstrap";
import Cookies from "js-cookie";
import { FaTrophy } from "react-icons/fa";

export function LoginToken() {
    const location = useLocation();
    let params = queryString.parse(location.search);
    const loginToken = Capacitor.isNativePlatform() ? params.token : Cookies.get("LoginToken");
    return loginToken;
}

export function TokenAppenditure() {
    return Capacitor.isNativePlatform() ? `?token=${LoginToken()}` : "";
}

export function CommonLoader() {
    return (
        <div className="card">
            <div
                className="mt-4 pt-4 mb-4 pb-4"
                style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                }}
            >
                <Loader type="Bars" color="#f853bd" height={30} width={30} />
            </div>
        </div>
    );
}

export function NoResults({approvalStatus}) {
    return (
        <div
            className="p-2 py-5 card"
            style={{
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
            }}
        >
            {approvalStatus === "Not Reviewed" ? (
                <>
                    <h5 className="madeGentle">Application pending.</h5>
                    <h6 className="text-muted">
                    Check back soon! Our team is reviewing your application and will email you once approved.
                    </h6>
                </>
               
            ) : (
                <>
                    <h5 className="madeGentle">No Results Found.</h5>
                    <h6 className="text-muted">
                        In the meantime, earn money by entering
                        some of our ongoing{" "}
                        <a
                        className="text-pink"
                        href={`/contests${Capacitor.isNativePlatform() ? `?token=${LoginToken()}` : ""}`}
                        >
                        contests
                        </a>
                        .
                    </h6>
                </>
            )}
            
            <Link to={`/contests${TokenAppenditure()}`}><div className="btn btn-pink gradient-pink text-white mt-3" style={{maxWidth:200}}><FaTrophy className="mr-2"/>View Contests</div></Link>
        </div>
    );   
}

export function Breadcrumbs(activePage, previousPage, previousLink, noShow) {
    if (!Capacitor.isNativePlatform()) {
        return (
            <ol className="breadcrumb mt-0 mb-2 hide-mobile">
                <BreadcrumbItem>
                    <a href={previousLink}>{previousPage}</a>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                    <a>{activePage}</a>
                </BreadcrumbItem>
            </ol>
        )
    }
    
}

export const stylingTabCode = () => {
    return Capacitor.isNativePlatform() ? `.PrivateTabIndicator-colorSecondary-3 {
        background-color: #fff !important;
        height: 3px !important;
      }
      .MuiTab-wrapper {color:#fff !important;}.MuiTabs-indicator {background-color: #fff !important;}` : `.MuiTabs-indicator {background-color: #f853bd !important;}`;

}

export function makeid(length) {
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const ValidateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};