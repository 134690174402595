import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import Cookies from "js-cookie";
import { CommonLoader, LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";
import { Capacitor } from "@capacitor/core";
import { Row, Col, Tooltip, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { HiPlusCircle, HiQuestionMarkCircle } from "react-icons/hi";
import { BrandRateTooltip, SongRateTooltip } from "../Utility/modals";

function range(start, thisYear) {
  const arr = [];
  for (let index = start; index < thisYear; index++) {
    arr.push(index);
  }
  return arr;
}

const Social = () => {
  const location = useLocation();
  const campaignType = location.pathname.slice(11);
  let params = queryString.parse(location.search);
  const [currentRemoval, setCurrentRemoval] = useState();
  const [modalSong, setModalSong] = useState();
  const toggleSong = () => {
    setModalSong(!modalSong);
  }
  const [modalBrand, setModalBrand] = useState();
  const toggleBrand = () => {
    setModalBrand(!modalBrand);
  }
  const [modalRemove, setModalRemove] = useState(false);
  const toggleRemove = () => {
    setModalRemove(!modalRemove);
  };
  const loginToken = LoginToken(); // => 'value'
  const history = useHistory();
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const [data, setData] = useState();
  const removeProfile = (e) => {
    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/profile-delete`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({ ContactPlatformID: e.ContactPlatformID, UID: e.UID })
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        setData();
        toggleRemove();
      });
  }
  useEffect(() => {
    if (!data) {
      fetch(`https://portalapi.genni.com/api/v1/creatorapi/profiles?x-api-key=${loginToken}`, {
        method: "GET",
      })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            history.push("/login");
          }
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          // console.log("d",data);
          setData(data);
          
        });
    }

  }, [data]);

  // console.log(data);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {Breadcrumbs("Social Media Profiles", "Account Settings", `/settings${TokenAppenditure()}`, true)}
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 madeGentle">Social Media Profiles</h4>
                <Link to={`/account/updatesocial/?ContactPlatformID=0&UID=New${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`}>
                  <div
                    className="btn btn-pink float-right fw-bold"
                    style={{ width: "100%" }}
                  >
                    <HiPlusCircle size={17} style={{ marginTop: -2, marginRight: 4 }} className="text-white" />Add
                  </div>
                </Link>
              </div>
              <div className="campaigns">
                {data ? (
                  <div className="campaigns">
                    {data.results.ContactPlatforms.map((e, i) => {
                      return (
                        <div key={i} className="campaign card p-3">
                          <div
                            className="campaign-title border-bottom pb-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <h5 className="fw-bold" style={{ display: 'flex', alignItems: 'center' }}>{e.Platform}</h5>
                            <div>
                              <Link
                                to={`/account/updatesocial/?ContactPlatformID=${e.ContactPlatformID}&UID=${e.UID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`}
                                className="btn btn-light fw-semi mr-1"
                              >
                                <span>Edit</span>{" "}
                              </Link>

                              <div
                                className="btn btn-danger fw-semi ml-1"
                                onClick={() => {
                                  setCurrentRemoval(e);
                                  toggleRemove();
                                }}
                              >
                                <span>Delete</span>{" "}
                              </div>

                              
                            </div>
                          </div>
                          <div className="campaign-details mt-3">
                            <div className="campaign-detail">
                              <p className="fw-semi mb-0">Handle</p>
                              <p className="cmpgn-value Archivo mb-1">{e.Handle && e.Handle.includes("@") ? "" : "@"}{e.Handle}</p>
                            </div>
                            <div className="campaign-detail mb-0">
                              <p className="fw-semi mb-0">
                                Song Rate <SongRateTooltip />
                              </p>
                              <p className="cmpgn-value Archivo mb-1">{e.SongRate === "$0.00" ? "Please Update" : e.SongRate}</p>
                            </div>

                            <div className="campaign-detail mb-0">
                              <p className="fw-semi mb-0">Brand Rate <BrandRateTooltip /></p>
                              <p className="cmpgn-value Archivo mb-1">{e.BrandRate === "$0.00" ? "Please Update" : e.BrandRate}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <CommonLoader />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Modal isOpen={modalBrand} toggle={toggleBrand}>
        <ModalHeader className="d-flex">
          What is a brand rate?
        </ModalHeader>
        <ModalBody
          style={{
            maxHeight: 300,
            overflowY: "scroll",
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <p>
            The typical price you'd charge for promoting a brand/product in a post. We recommend
          </p>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalRemove} toggle={toggleRemove}>
        <ModalHeader className="">
          Are you sure you want to delete this profile?
        </ModalHeader>
        <ModalFooter>
          <Button color="" className="btn-light" onClick={toggleRemove}>
            Cancel
          </Button>
          <Button
            color="pink"
            className={`btn-pink`}
            onClick={() => {
              removeProfile(currentRemoval);
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};;

export default Social;
