import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useLocation, Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Capacitor } from "@capacitor/core";
import { LoginToken, NoResults, CommonLoader } from "./common";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
  } from "@material-ui/core";
import campaignImg from "../../assets/images/campaigns3.svg";
import contestImg from "../../assets/images/contests2.svg";
import 'react-loading-skeleton/dist/skeleton.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";

const CampaignTable = (props) => {
  const { data, pagination, totalPages, currentPage, setCurrentPage,  } = props;
  const location = useLocation();
  const loginToken = LoginToken();

  const returnLink = (e) => {
    if (e) {
        if (e.type === "Campaign") {
            return `/detail?PostID=${e.postID}&UID=${e.UID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`;
        } else {
            return `/contestdetail?PostID=${e.postID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`;
        }
    }
    
  }
  const history = useHistory();
  let params = queryString.parse(location.search);
  const returnPill = (e) => {
    if (e.subtitle.includes("Accept")) {
        return (<div className="pill-table pill-pink">Invited</div>)
    } else if (e.subtitle.includes("Upload")) {
        return (<div className="pill-table pill-blue">Accepted</div>);
    } else {
        return (<div className="pill-table pill-green">Done</div>);
    }
  }
 
  const returnImg = (e) => {
    
    if (e.image) {
        return <img src={e.image}  style={{ height:30,width:30, borderRadius:3}} alt="campaignimg" />
    } else {
        return (
            <img src={campaignImg} style={{ width:'18px', height:'18px',}} alt="campaignimg" />
        );
    }
    
  }

  const columns = [
    {
      id: 1,
      label: "Title",
    },

    {
      id: 2,
      label: "Handle",
    },
    {
        id: 2,
        label: "Platform",
      },
  
    {
        id: 3,
        label: "Action",
    },
    {
        id: 4,
        label: "Rate",
    },
    // {
    //     id: 5,
    //     label: "State",
    // },
   
  ];

  const Tabl = () => {
    return (
        <Paper className=" border p-0 mb-5 pt-0" style={{ width: "100%", borderRadius:6 }}>
            <TableContainer>
                
                
                <Table aria-label="sticky table">
                <TableHead>
                    <TableRow>
                    {columns.map((column) => {
                        return (
                        <TableCell key={column.id}>
                            <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                            >
                            {column.label}
                            </div>
                        </TableCell>
                        );
                    })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, i) => {
                        return (
                        <TableRow
                            hover
                            role="checkbox"
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push(returnLink(row))}
                            tabIndex={-1}
                            key={i}
                        >
                            <TableCell key={i} className="minTableCell">
                                <div className="d-flex al-center">
                                    <div className="d-flex al-center jc-center mr-2" style={{height:30, width: 30, background: '#f8f9fa', borderRadius:3}}>
                                        {returnImg(row)}
                                    </div>
                                    {row.title}
                                </div>
                            </TableCell>
                            <TableCell key={i} >
                                {row.handle}
                            </TableCell>
                            <TableCell key={i} >
                                {row.platform}
                            </TableCell>
                            <TableCell key={i} className="minTableCell">
                                {row.subtitle.length >1 ? row.subtitle : "No Action Required"}
                            </TableCell>
                            <TableCell key={i} >
                                {row.price}
                            </TableCell>
                            {/* <TableCell key={i}>
                                {returnPill(row)}
                            </TableCell> */}
                            
                            
                        </TableRow>
                        );
                    })}
                </TableBody>
                
                </Table>
                {pagination && totalPages > 1 && (
                    <div
                    className="px-4"
                    style={{
                        textAlign: "right",
                        marginTop: 16,
                        paddingBottom: 0,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                    }}
                    >
                    <p className="px-4" style={{ fontSize: 14 }}>
                        Page {currentPage} of {totalPages}
                    </p>
                    <div
                        style={{
                        display: "flex",
                        flexDirection: "row",
                        fontSize: 27,
                        marginTop: -10,
                        width: 72,
                        justifyContent: "space-between",
                        }}
                    >
                        <div
                        className={`pagination-btn ${
                            currentPage > 1
                            ? ""
                            : "disabled-pagination-btn text-muted"
                        }`}
                        onClick={() => {
                            if (currentPage > 1) {
                            setCurrentPage(parseInt(currentPage) - 1);
                            }
                        }}
                        >
                        {" "}
                        <MdOutlineChevronLeft />
                        </div>
                        <div
                        className={`pagination-btn ${
                            currentPage < totalPages
                            ? ""
                            : "disabled-pagination-btn text-muted"
                        }`}
                        onClick={() => {
                            if (currentPage < totalPages) {
                            setCurrentPage(parseInt(currentPage) + 1);
                            
                            }
                        }}
                        >
                        {" "}
                        <MdOutlineChevronRight />
                        </div>
                    </div>
                    </div>
                )}
            </TableContainer>
            
        </Paper>
    );
  }
  return (
    <React.Fragment>
        {data ? (
            <>
                {data.length > 0 ? (
                    <Tabl />
                ) : (
                    <NoResults />
                )}
            </>
        ) : (<CommonLoader />)}
        
      
      
      
    </React.Fragment>
  );
};;


export default CampaignTable;
