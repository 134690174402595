import PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
// Import Routes all
import { AnimatedSwitch } from 'react-router-transition';
import { Capacitor } from "@capacitor/core";
import { Toast } from "@capacitor/toast";
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/";

// Import scss
import "./assets/scss/theme.scss";


Amplify.configure(awsconfig);



const App = props => {
  function getLayout() {
    let layoutCls = HorizontalLayout;

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = HorizontalLayout;
        break;
    }
    return layoutCls;
  }

  
  const Layout = getLayout();
  return (
    <React.Fragment >
      <Router>
        {Capacitor.isNativePlatform() === "not-true" ? (
          <AnimatedSwitch
          atEnter={{ translateX: 100 }}
          atLeave={{ translateX: -100 }}
          atActive={{ translateX: 0 }}
          mapStyles={styles => ({
            transform: `translateX(${styles.translateX}%)`,
          })}
          className="switch-wrapper"
        >
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
      </AnimatedSwitch>
        ) : (
          <Switch
          >
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}
          </Switch>
        )}
        
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);