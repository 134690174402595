// React Basic and Bootstrap
import React, { Component } from "react";
import { Row, Col } from "reactstrap";

function HowItWorks() {
  const steps = [
    {
      title: "Use The Song In A Post",
      desc: "Post a TikTok or Instagram Reel that uses the contest's song",
    },
    {
      title: "Submit The Link",
      desc: "Submit a link to the post on the contest's page on Genni",
    },
    {
      title: "Compete For Prizes",
      desc: "Win prizes by posting either the most liked or most creative video"
    },
  ];

  return (
    <React.Fragment>
      <Row>
        {steps.map((feat, i) => {
          return (
            <Col md="4" xs="12" key={i} name="featurebox" className="">
              <div className="work-process p-3 rounded bg-white shadow card">
                <div
                  class="mt-2 madeGentle text-pink mb-2"
                  style={{
                    borderRadius: "100%",
                    
                  }}
                >
                  <h3>{i + 1}.</h3>
                </div>
                <h5 className="title madeGentle">{feat.title}</h5>
                <p className="text-muted para Archivo">{feat.desc}</p>
              </div>
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
}

export default HowItWorks;
