import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
//Import Images
import tiktok from "../../assets/images/tiktok.png";
import capcut from "../../assets/images/capcut.png";
import ig from "../../assets/images/ig.png";
import yt from "../../assets/images/yt.png";
import { MdOutlineChevronRight } from "react-icons/md";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#162638",
    opacity: 0.8,
    color: "#fff",
    boxShadow:
      "0 4px 6px rgba(132,146,166,0.1), 0 1px 3px rgba(132,146,166,0.1)",
    fontSize: 12,
  },
  arrow: {
    color: "#162638",
    opacity: 0.8,
  },
}))(Tooltip);

function Standings(props) {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  
  function compare(a, b) {
    if (a.likes > b.likes) {
      return -1;
    }
    if (a.likes < b.likes) {
      return 1;
    }
    return 0;
  }
  const lastUpdated = props.lastUpdated;
  
  const creatorArr = Object.values(props.creatorObj).sort(compare);
  const columns = [
    {
      id: 1,
      label: "#",
    },

    {
      id: 2,
      label: "Creator",
    },
    {
      id: 3,
      label: "Likes",
    },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function numberWithCommas(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  function nFormatter(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  }

  function intToString(value) {
    var suffixes = ["", "k", "m", "b", "t"];
    if (value > 1000) {
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(2)
      );
      if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    }
    return value;
  }
  const returnPlatform = (r) =>{
    if (r.platform === "instagram") {
      return ig;
    } else if (r.platform === "youtube") {
      return yt;
    } else if (r.platform === "capcut") {
      return capcut;
    } else {
      return tiktok;
    }
  }
  return (
    <React.Fragment>
      <Container>
        <Row className="justify-content-center mt-4">
            {creatorArr.length > 0 ? (
              <Paper className="p-0" style={{ width: "100%" }}>
                <TableContainer >
                  <div className="px-3 pt-2">
                    <h4 className="mt-2 ml-3 madeGentle" style={{ marginBottom: 0 }}>
                      Leaderboard
                    </h4>
                    <p className="text-muted mb-1 ml-3 text-sm Archivo">
                      Last Updated: {moment(lastUpdated).fromNow()}
                    </p>
                  </div>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => {
                          return (
                            <TableCell key={column.id}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {column.label}
                              </div>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {creatorArr
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, i) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              style={{ cursor: "pointer" }}
                              onClick={() => window.open(row.link, "_blank")}
                              tabIndex={-1}
                              key={row.id}
                            >
                              <TableCell key={row.id} style={{ maxWidth: 10 }}>
                                {i + rowsPerPage * page + 1}
                              </TableCell>
                              <TableCell key={row.id} >
                                <div style={{display:'flex',flexDirection:'row'}}>
                                <img
                                  src={returnPlatform(row)}
                                  className="float-left mr-2 "
                                  style={{borderRadius:4}}
                                  height="30"
                                  alt=""
                                />
                                <div
                                  style={{ marginTop: 3 }}
                                  className="mobile-creator-col text-decoration"
                                >
                                  @{row.creator}{" "}
                                </div>
                                </div>
                              </TableCell>
                              <TableCell
                                key={row.id}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  {row.likes > 0 ? nFormatter(row.likes) : "-"}
                                  <span
                                    className="text-muted"
                                    style={{ fontSize: 13 }}
                                  >
                                    {row.posts > 1
                                      ? `${row.posts} Posts`
                                      : "1 Post"}
                                  </span>
                                </div>
                                <MdOutlineChevronRight className="text-muted float-right ml-1"/>
                                {/* <div className="btn btn-sm btn-primary float-right" style={{padding: '2px 12px',
    fontSize: '15px'}}>
                                    <i className="mdi mdi-play-circle text-white"></i>
                                  </div> */}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={creatorArr.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <Paper style={{ width: "100%" }}>
                <TableContainer>
                  <h4 className="mt-2 ml-3" style={{ marginBottom: 0 }}>
                    Leaderboard
                  </h4>
                  <p className="text-muted mb-1 ml-3 text-sm">Last Updated:</p>
                  <Table stickyHeader aria-label="sticky table"></Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={creatorArr.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            )}
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Standings;
