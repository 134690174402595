import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Capacitor } from '@capacitor/core';
import queryString from "query-string";
import { Link, useLocation, useHistory, withRouter} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faMoneyBill, faUser } from '@fortawesome/free-solid-svg-icons'
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
// Redux Store
import { LoginToken, TokenAppenditure } from "../../pages/Utility/common";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
//i18n
import { withTranslation } from "react-i18next";

const NativeHeader = props => {
  const [value, setValue] = React.useState(null);
  const location = useLocation();
  let params = queryString.parse(location.search);
  const history = useHistory();

  useEffect(() => {
    if (value === null){
      if (location.pathname.includes("/dashboard")) {
        setValue(0);
      } else if (location.pathname.includes("/payments")) {
        setValue(1);
      } else if (location.pathname.includes("/notifications")) {
        setValue(2);
      } else if (location.pathname.includes("/settings")) {
        setValue(3);
      } else{
        setValue(4);
      }
    }  
  }, [])
  
  return (
    <React.Fragment>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
         
        }}
        className="border-top"
      >
        <BottomNavigationAction component={Link} to={`/dashboard${TokenAppenditure()}`} className="pt-3" label="Home" icon={<FontAwesomeIcon icon={faHouse}  style={{height:20, marginBottom:4}} />} />
        <BottomNavigationAction component={Link} to={`/payments${TokenAppenditure()}`} className="pt-3" label="Payments" icon={<FontAwesomeIcon  icon={faMoneyBill} style={{height:20, marginBottom:5}} />} />
        <BottomNavigationAction component={Link} to={`/notifications${TokenAppenditure()}`} className="pt-3" label="Notifications" icon={<NotificationDropdown  isBottomNav />} />
        <BottomNavigationAction component={Link} to={`/settings${TokenAppenditure()}`} className="pt-3" label="Account"  icon={<FontAwesomeIcon  icon={faUser}  style={{height:20,marginBottom:5}} />} />
      </BottomNavigation>
    </React.Fragment>
  );
};

NativeHeader.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withRouter(NativeHeader));
