import React, {useState} from "react";
import PropTypes from 'prop-types';
import { FormGroup } from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import Cookies from "js-cookie";
import plusSign from "../../assets/images/plus-sign.svg";
import { Preferences } from '@capacitor/preferences';
import {Capacitor} from "@capacitor/core";
import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
  changeTopbarTheme,
  showRightSidebarAction,
} from "../../store/actions";
//SimpleBar
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";

import "./rightbar.scss";
import { LoginToken, TokenAppenditure } from "../../pages/Utility/common";
import SwitchModal from "./SwitchModal";
import { MdCancel } from "react-icons/md";
//Import images

const RightSidebar = (props) => {
  const { showMenu, setShowMenu } = props;
  let menu = showMenu;
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const loginToken = LoginToken();
  const history = useHistory();
  const {data} = props;
  const hasImage = (data && data.contactDetail.contactImageUrl);
  const isManager = data && (data.contactDetail.contactType === "manager" || (data.results.LinkedAccounts.length > 0));
  const switchToManager = () => {
    if (data){
      data.results.LinkedAccounts.map(e => {
        if (e.AccountType === "Manager") {
          fetch(
            `https://portalapi.genni.com/api/v1/creatorapi/switch`,
            { method: "POST",
              headers: { "Content-Type": "application/json", "x-api-key": loginToken },
              body: JSON.stringify({contactId: parseInt(e.ContactID)})
             }
        )
            .then((res) => {
            if (res.ok) {
                return res.json();
            }
            throw res;
            //const jsonRes = JSON.parse(res);
            })
            .then(async (data) => {
              if (data.results.success) {
                await Cookies.set("ContactDetail", "");
                const redirectLink = `/dashbord${TokenAppenditure()}`;
                history.push(redirectLink);
              }
              
            });
        }
      });
    }
  }
  return (
    <React.Fragment>
      
      <div className={`right-bar ${!menu ? "hide-rightbar" : "show"}`}>
        <SimpleBar style={{ height: "900px", paddingTop: `${Capacitor.isNativePlatform() ? "30px" : "0px"}` }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title d-flex align-items-center px-3 py-3 pb-0">
              
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMenu(!menu);
                }}
                className=" ms-auto"
              >
                <MdCancel color="#162638" size="28" className="noti-icon"/>
              </Link>
            </div>
            <div className="d-flex jc-center flex-column align-center">
            {hasImage || (data && data.results.LinkedAccounts.length > 0) ? (
                <div
                  className={`rounded-circle header-profile-user ${Capacitor.isNativePlatform() ? "bg-pink" : "bg-pink"}`}
                  style={{
                    padding: "0px",
                    background: `url(${
                      hasImage && data.contactDetail.contactImageUrl
                    })`,
                    backgroundSize: "contain !important",
                    display: "flex",
                    height:50,
                    width:50
                  }}
                ></div>
              ) : (
                <Link to={`/account/photo${TokenAppenditure()}`}>
                  <div
                    className="rounded-circle bg-pink"
                    style={{
                      padding: "0px",
                      display: "flex",
                      height:50,
                      width:50
                    }}
                  >
                    {data && (
                      <img
                        src={plusSign}
                        alt="plusSign"
                        style={{ width: 20, height: "auto", margin: "auto" }}
                      />
                    )}
                  </div>
                </Link>
              )}
              {data && !isManager ? (
                <>
                  <h5 class="mt-2 text-center">{data && `${data.contactDetail.contactName}`}</h5>
                  <Link to={`/settings${TokenAppenditure()}`}><div className="btn btn-light mb-3" style={{minWidth:'190px', fontSize:'16px', borderRadius:'100px', fontWeight:500}}>Account Settings</div></Link>
                </>
              ) : (
                <>
                
                  {data && data.contactDetail.contactType !== "manager" ? (
                    <>
                      <h5 class="mt-2 text-center">{data && `${data.contactDetail.contactName}`}</h5>
                      <div onClick={switchToManager} className="btn btn-light mb-3" style={{ minWidth: '190px', fontSize: '16px', borderRadius: '100px', fontWeight: 500 }}>
                        Manage Roster
                      </div>
                    </>
                  ) : (
                    <>
                      <h5 class="mt-2 text-center">{data && `${data.contactDetail.contactName}`}</h5>
                      <Link to={`/account/accounts${TokenAppenditure()}`}>
                        <div className="btn btn-light mb-3" style={{ minWidth: '190px', fontSize: '16px', borderRadius: '100px', fontWeight: 500 }}>
                          Manage Roster
                        </div>
                      </Link>
                    </>
                  )}
                  
                </>
              )}
            </div>

            <hr className="my-0" />
            <div className="menu-list">
              <Link to={`/dashboard${TokenAppenditure()}`}>
                <div className="px-3 pt-3 py-2 border-bottom">
                  <h6 className="">Home</h6>
                </div>
              </Link>
              <Link to={`/campaigns${TokenAppenditure()}`}>
                <div className="px-3 pt-3 py-2 border-bottom">
                  <h6 className="">Campaigns</h6>
                </div>
              </Link>
              <Link to={`/contests${TokenAppenditure()}`}>
                <div className="px-3 pt-3 py-2 border-bottom">
                  <h6 className="">Contests</h6>
                </div>
              </Link>
              
              <Link to={`/payments${TokenAppenditure()}`}>
                <div className="px-3 pt-3 py-2 border-bottom">
                  <h6 className="">Payments</h6>
                </div>
              </Link>
              {/* <Link to={`/analytics${TokenAppenditure()}`}>
                <div className="px-3 pt-3 py-2 border-bottom">
                  <h6 className="">Analytics</h6>
                </div>
              </Link> */}
              {!(data && !isManager) && (
                <Link to={`/settings${TokenAppenditure()}`}>
                  <div className="px-3 pt-3 py-2 border-bottom">
                    <h6 className="">Account Settings</h6>
                  </div>
                </Link>
              )}
              <Link to={`/explore${TokenAppenditure()}`}>
                <div className="px-3 pt-3 py-2 border-bottom">
                  <h6 className="">Explore</h6>
                </div>
              </Link>
              
              <Link to={`/contact${TokenAppenditure()}`}>
                <div className="px-3 pt-3 py-2 border-bottom">
                  <h6 className="">Contact Us</h6>
                </div>
              </Link>
              <Link to={`/faq${TokenAppenditure()}`}>
                <div className="px-3 pt-3 py-2 border-bottom">
                  <h6 className="">FAQ</h6>
                </div>
              </Link>
              <div
                onClick={() => {
                  fetch(`https://portalapi.genni.com/api/v1/creatorapi/auth/logout`,
                    {
                      method: "post",
                      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
                    })
                    .then((res) => {
                      if (res.ok) {
                        return res.json();
                      }
                      throw res;
                      //const jsonRes = JSON.parse(res);
                    })
                    .then(async(data) => {
                      Cookies.set("LoginToken", "");
                      await Preferences.set({
                        key: 'LoginToken',
                        value: "",
                      });
                      history.push("/login");
                    })
                    .catch((error) => {
                      Cookies.set("LoginToken", "");
                      history.push("/login");
                    });
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="px-3 pt-3 py-2 border-bottom">
                  <h6 className="">Logout</h6>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginTop: 20,
                }}
              >
                <a href="/terms" target="_blank" className="text-muted cursor-pointer">
                  Terms
                </a>
                <a href="/privacy" target="_blank" className="text-muted cursor-pointer">
                  Privacy
                </a>
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div
        className={`rightbar-overlay ${!menu ? "hide" : "show"}`}
        onClick={() => {
          setShowMenu(!menu);
        }}
      />

      <SwitchModal modal={modal} setModal={setModal} data={data} />
      
    </React.Fragment>
  );
};;

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changePreloader: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  isPreloader: PropTypes.any,
  layoutType: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  topbarTheme: PropTypes.any
};

const mapStateToProps = state => {
  return { ...state.Layout };
};

export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
  showRightSidebarAction,
})(RightSidebar);
