// React Basic and Bootstrap
import React, { Component } from "react";
import { Row, Col,Modal, ModalHeader, ModalBody } from "reactstrap";

function HowItWorks({howItWorksModal, toggleModal}) {
  const steps = [
    {
      title: "Receive An Invite",
      desc: "A brand or artist selects you to participate in a campaign",
    },
    {
      title: "Promote The Song/Brand",
      desc: "Create a post following the campaign's creative direction",
    },
    {
      title: "Get Paid!",
      desc: "After the post has been submitted and verified, you will receive payment"
    },
  ];

  return (
    <React.Fragment>
      <Modal isOpen={howItWorksModal} toggle={toggleModal}>
      <ModalHeader className="d-flex">
        
        <h4 className="madeGentle d-flex jc-space-between">How It Works<i className="mdi mdi-close" onClick={toggleModal} style={{cursor:'pointer'}}></i></h4>

      </ModalHeader>
      <ModalBody>

          {steps.map((feat, i) => {
            return (
              <Col md="12" xs="12" key={i} name="featurebox" className="">
                <div className="work-process p-3 rounded bg-white border mb-2">
                  <div
                    class="mt-2 text-pink mb-2"
                    style={{
                      borderRadius: "100%",
                      
                    }}
                  >
                    <h4 className="fw-bold">{i + 1}.</h4>
                  </div>
                  <h5 className="title fw-bold">{feat.title}</h5>
                  <p className="text-muted para Archivo">{feat.desc}</p>
                </div>
              </Col>
            );
          })}
      </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default HowItWorks;
